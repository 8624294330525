.waw-alert-wrapper {
    font-family: $ff-base;
    text-transform: uppercase;

}


.pureplanner {
    .waw-alert.waw-alert-color-blue {
        background: $c-blue !important;
        border-color: $c-blue !important;
        border-radius: 3px;
        opacity: 0.9;
    }

    .waw-alert-title,
    .waw-alert-message {
        color: $white !important;
    }

    .waw-alert__close::after,
    .waw-alert__close::before {
        background-color: $white !important;
    }

    .waw-alert-color-red {
        background: $red !important;
        border-color: $red!important;
        border-radius: 3px;
        opacity: 0.9;
    }

    .waw-alert-color-yellow {
        background: $c-green !important;
        border-color: $c-green !important;
        opacity: 0.9;
        border-radius: 3px;

        .alert-btn {
            border: none;
            color: $white;
            text-transform: uppercase;
            text-align: center;
            cursor: pointer;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            border-radius: 3px;
            width: 80px;
            height: 30px;
            margin-left: 5px;
            margin-right: 5px;
        }

        .alert-btn:first-child {
            border: 1px solid $sky;
            background-color: $white;
            transition: 0.2s ease-in-out;
            color: $sky;

        }

        .alert-btn:last-child {
            background-color: $red;
            transition: 0.2s ease-in-out;
            color: $white;

            &:hover {
                background-color: $redD;
                transition: 0.2s ease-in-out;
            }
        }

        .waw-alert__close {
            display: none;
        }

        @include bp-max(md) {
            .waw-alert-body {
                flex-direction: column;

                .alert-btn {
                    margin-top: 10px;
                }
            }
        }

    }

    &.notification {
        position: relative;
        top: $topbarHeightMobil;
        text-transform: none;
        @include bp-min(md) {
            top: $topbarHeight;
        }
        .waw-alert-body {
            align-items: flex-start !important;
        }
        .waw-alert {
            background-color: white !important;
            border-radius: 10px !important;
            border: 1px solid rgba(0, 0, 0, 0.09) !important;
            box-shadow: $shadow-popup !important;
            opacity: 1;
            
            &::after {
                box-shadow: none;
            }

            &:hover .ntf-desc {
                display: block;
            }
        }
        .waw-alert__close {
            &:before {
                background-color: $c-grey !important;
            }
            &:after {
                background-color: $c-grey !important;
            }
        }
        .ntf {
            @include bp-max(sm) {
                width: 100%;

                &-desc {
                    display: block !important;
                }
            }
            &-clock, &-unread {
                display: none;
            }
            &-desc {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
}