// ===== COLORS SETTINGS =====
:root {
    --blue-color:#191C1F;
    --sky-color: #68C7B3;
    --skyL-color: #8ef5df;
    --grey-color: #BDBDBD;
    --greyL-color: #F2F2F2;
    --red-color: #A31010;
    --redL-color: #8C1B0F;
	--green-color: #089227;
    --white-color: #fff;
    --black-color: #000;
	--ff-base: 'Gilroy', sans-serif;
	--ff-second: 'Poppins', sans-serif;
	--ff-third: 'Work Sans', sans-serif;
	--ff-field: 'Mulish', sans-serif;
	--sidebar-color: #1F2226;
	--sidebar-width: 320px;
	--sidebar-hidden-width: 76px;
	--sidebar-hidden-width-setup: 99px;
	--topbar-height: 74px;
	--topbar-height-mobile: 80px;
}
// ===== COLORS FOR SCSS =====
$blue: var(--blue-color);
$sky: var(--sky-color);
$skyL: var(--skyL-color);
$grey: var(--grey-color);
$greyL: var(--greyL-color);
$red: var(--red-color);
$redD: var(--redL-color);
$green: var(--green-color);
$white: var(--white-color);
$black: var(--black-color);

// ===== FONT SETTINGS =====
$ff-base: var(--ff-base);
$ff-second: var(--ff-second);
$ff-third: var(--ff-third);
$ff-field: var(--ff-field);

// ====  SIDEBAR SETTINGS ====
$sidebarColor: var(--sidebar-color);
$sidebarWidth: var(--sidebar-width);
$sidebarHiddenWidth: var(--sidebar-hidden-width);
$sidebarHiddenWidthSetup: var(--sidebar-hidden-width-setup);
$topbarHeight: var(--topbar-height);
$topbarHeightMobil: var(--topbar-height-mobile);


// ====  NEW COLORS ====

$c-basic: #191C1F;
$c-sky: #0FA0D8;
$c-green: #68C7B3;
$c-blue: #334D6E;

$c-error: #FF0000;


// ====  NEW COLORS ====
$c-black: #343434;
$c-background: #EBEFEE;
$c-dark_white: #EBEFEE;
$c-main: #6AC4B3;
$c-grey: #A5A5A5;
$c-placeholder: #ADB8C6;
$c-text_light-grey: #ADB8C6;
$c-text_grey: #7B808B;
$c-text_dark-grey: #666666;
$c-text_dark-grey-2: #444444;
$c-stroke: #F0F1F7;
$c-stroke-2: #F6FFFD;
$c-light-mint-green: #F1FBF9;
$c-red: #E47878;

// ====  BOX SHADOWS ====
$shadow-popup: 0px 0px 0px 0px #A4A7AF1A, 0px 14px 32px 0px #A4A7AF1A, 0px 58px 58px 0px #A4A7AF17, 0px 130px 78px 0px #A4A7AF0D, 0px 230px 92px 0px #A4A7AF03, 0px 360px 101px 0px #A4A7AF00;

// ====  INPUT  ====
$input-label_color: $c-text_dark-grey;
$input-label_height: 18px;
$input-label_font: normal 300 12px/18px $ff-second;
$input-label_margin: 6px;
$input-height: 42px;
$input-height_mobile: 32px;
$input-padding_top_bottom: 11px;
$input-padding_left_right: 15px;
$input-padding_top_bottom_mobile: 8px;
$input-padding_left_right_mobile: 10px;
$input-border: 1px solid $c-stroke;
$input-border_active: $c-main;
$input-border-radius: 8px;
$input-border-radius_mobile: 6px;
$input-background: #FFFFFF;
$input-color: $c-black;
$input-font: normal 400 14px/18px $ff-field;
$input-font_mobile: normal 400 10px/14px $ff-field;
$input-letter-spacing: 0.3px;
$input-placeholder_color: $c-text_light-grey;
$input-margin-bottom: 10px;
$input-req_color: $c-red;
$input-req_top: -4px;
$input-req_height: 15px;
$input-req_font: normal 300 12px/15px $ff-second;
$input-btn_width_height: 32px;
$input-btn_width_height_mobile: 24px;
$input-btn_right: calc($input-padding_left_right - ($input-btn_width_height - 24px) / 2);
$input-btn_right_mobile: calc(($input-height_mobile - $input-btn_width_height_mobile) / 2);
$input-btn_border-radius: 50%;
$input-btn_font-size: 11px;
$input-btn_transition: 0.15s all ease-in-out;