.calendar-container {
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - $topbarHeight);

    @include bp-max(md) {
        height: calc(100% - $topbarHeightMobil);
    }
}

.calendar-wrapper {
    background: $white;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
}

.cal-table {
    flex-grow: 1;
    width: calc(100% - 130px - 40px);
    position: absolute;
    margin-left: 130px;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;

    @media (max-width:500px) {
        width: calc(100% - 40px);
        margin-left: 0;
    }
}

.cal-table__row {
    display: flex;
    height: 100%;
    overflow-x: auto;
}

.cal-date {
    width: 100%;
}

.cal-date__item {
    position: relative;
    width: 100%;
    height: 80px;
    display: flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: $blue;
    border-top: 1px solid $grey;
    transition: 0.3s all ease-in-out;

    &--time {
        position: absolute;
        top: 2px;

        .timeSm {
            display: none;
        }

        @media (max-width:500px) {
            left: -19px;
            width: 18px;
            top: -8px;

            .timeSm {
                display: block;
            }

            .timeLg {
                display: none;
            }
        }
    }

}

.cal-date__item--line {
    display: block;
    width: 100%;
    height: 2px;
    border-bottom: 1px dashed #bbbaba;

    &._main {
        z-index: 10;
        top: -2px;
        border-bottom: 1px solid $grey;
        height: 2px;
    }
}

.col-date__item--lineHover {
    position: absolute;
    font-size: 10px;
    left: 80px;
    top: 5px;

    @media (max-width:500px) {
        left: 3px;
    }
}

.cal-table__item {
    min-width: 200px;
    // width: 230px;
    height: 100%;
    word-break: break-word;
    position: relative;
    border-right: 2px solid #f3f3f3;
    z-index: 1;
    &.active {
        z-index: 3333;
    }

    @media (max-width:500px) {
        min-width: 100px;
    }

    &:first-child {
        border-left: 2px solid #f3f3f3;
    }

    &:last-child {
        border-right: none;
    }

    &:last-child {
        margin-right: 0;
    }
}

.col-date__currentDate {
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $blue;
    font-size: 14px;
    .cal-date-small {
        max-width: 75px;
        width: 100%;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;

    }
    @media (max-width:500px) {
        opacity: 0;
    }
}

.col-date__currentDate,
.cal-table__user {

    height: 80px;
    text-align: left;
    padding: 10px;
}

.cal-table {

    // .cal-table__user
    &__user {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }

    // .cal-table__avatar
    &__avatar {
        max-width: 42px;
        max-height: 42px;
        min-width: 42px;
        min-height: 42px;
        padding: 3px;
        border-radius: 50%;
        border: 1.5px solid #DFE0EB;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }

    }

    // .cal-table__name
    &__name {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: $blue;
        text-overflow: ellipsis;
        overflow: hidden;

        @include bp-max (sm) {
            font-size: 9px;
        }
    }
}

.cal-table__item__body,
.cal-table__lunch__body {
    padding: 1px;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.cal-appoint {
    background: $white;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 10px 10px 10px 15px;
    position: relative;
    position: absolute;
    width: 100%;
    white-space: pre;
    cursor: pointer;
    min-height: 23px;

    // colors for appoint status
    .New {
        color: $green;
    }

    .Canceled {
        color: $red;
    }

    .Completed {
        color: #ababab;
    }

    .Done {
        color: #ababab;
    }

    .Confirmed {
        color: $sky;
    }

    .No-show {
        color: $red;
    }

    // .cal-appoint__color
    &__color {
        position: absolute;
        width: 5px;
        height: 100%;
        background-color: $green;
        top: 0;
        left: 0;
        border-radius: 5px 0px 0px 5px;
    }

    // .cal-appoint__warning
    &__warning {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 30px;
    }

    // .cal-appoint__title
    &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.01em;
        color: $blue;
        margin-bottom: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: initial;
    }

    // .cal-appoint__time
    &__time {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.01em;
        color: $blue;
        margin-bottom: 5px;
    }

    // .cal-appoint__staff
    &__staff {
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.01em;
        color: $blue;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: keep-all;
        &._deleted {
            color: $red;
        }
    }

    // .cal-appoint__status
    &__status {
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.01em;
        color: $green;
        word-break: keep-all;
        &-min {
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.01em;
            color: $green;
            word-break: keep-all;
            @media (max-width: 500px) {
                display: none;
            }
        }
        &-icon {
            display: none;
            font-size: 8px;
            @media (max-width: 500px) {
                display: inline;
            }
        }
    }
}

.col-lunch {
    cursor: pointer;
    background: #f6f6f6;
    border-radius: 5px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $blue;
}

.cal-nav {
    padding: 0px 20px 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__left {
        display: flex;

        .mat-select {
            margin-right: 15px;
            max-width: 200px;
            width: 100%;
            min-width: 200px;

            &:last-child {
                margin-right: 0;
            }
        }

        @media (max-width:1010px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .mat-select {
                margin-right: 0;
                margin-bottom: 10px;

            }
        }
    }

    &__right {
        display: flex;
    }
}

.lunch-min {
    font-size: 10px;
}

.appoint-medium {
    .cal-appoint__time {
        display: none;
    }
}

.appoint-min {
    padding: 5px 10px 5px 15px;
    overflow: hidden;
    .cal-appoint__warning {
        top: -3px;
    }

    .cal-appoint__time,
    .cal-appoint__staff {
        display: none;
    }
    .cal-appoint__title {
        margin-right: 5px;
    }
    .cal-appoint__status {
        display: none;
    }
}

.cl-service-btn {
    background: $sky;
    width: 35px;
    height: 35px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    cursor: pointer;
    transition: .1s all ease-in-out;

    &:hover {
        background: $skyL;
        transition: .1s all ease-in-out;
    }
}

.btn-scroll {
    display: none;
    position: fixed;
    right: 220px;
    bottom: 20px;
    z-index: 111;
	&._active {
		background: $c-error;
	}

    @include bp-max(sm) {
        @include flex(center, center);
    }
}

.filter-sm {
    display: none;
    position: fixed;
    right: 170px;
    bottom: 20px;
    z-index: 111;

    @include bp-max(sm) {   
        @include flex(center, center);
    }
}

.add-appoint-lg {
    display: inline-block;

    @include bp-max(sm) {
        display: none;
    }
}

.add-appoint-sm {
    display: none;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 111;

    @include bp-max(sm) {
        @include flex(center, center);
    }
}

.add-lunch-sm {
    display: none;
    position: fixed;
    right: 70px;
    bottom: 20px;
    z-index: 111;

    @include bp-max(sm) {
        display: inline-block;
    }
}

.all-hours-sm {
    display: none;
    position: fixed;
    right: 120px;
    bottom: 20px;
    z-index: 111;

    @include bp-max(sm) {   
        @include flex(center, center);
    }
}