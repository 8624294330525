// === Navigation left sidebar ===
.nav {
    max-width: $sidebarWidth;
    width: $sidebarWidth;
    min-width: $sidebarWidth;
    background: $sidebarColor;
    display: flex;
    flex-direction: column;
    padding: 40px 50px;
    font-size: 14px;
    line-height: 21px;
    color: $white;
    transition: .3s all ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 99;
    user-select: none;

    &-profile {
        margin-bottom: 10px;
        margin-right: -15px;
        flex-shrink: 0;

        @include bp-min(lg) {
            display: none !important;
        }
    }

    &-logo {
        text-align: center;
        padding-bottom: 50px;
        cursor: pointer;

        img {
            width: 84px;
        }
    }

    &-link {
        flex-grow: 1;

        &__item {
            display: flex;
            align-items: center;
            width: $sidebarWidth;
            margin-left: -50px;
            padding: 16px 50px;
            cursor: pointer;
            position: relative;
            transition: .1s all ease-in-out;

            @include bp-max(sm) {
                width: calc(100% + 50px);
            }

            &::before {
                content: "";
                border-left: 6px solid $c-main;
                transform: scale(0);
                position: absolute;
                width: $sidebarWidth;
                height: 100%;
                left: 0;
                top: 0;
                transition: .3s all ease-in-out;
                background: #5B92FE1A;

                @include bp-max(sm) {
                    width: 100%;
                }
            }

            &:hover,
            &._active {
                transition: .1s all ease-in-out;

                &::before {
                    transition: .3s all ease-in-out;
                    transform: scale(1);
                }
            }

            &.mobile {
                @include bp-min(lg) {
                    display: none;
                }
            }

            &._new-ntf {
                padding-right: 35px;
                
                &::after {
                    content: "";
                    position: absolute;
                    right: 25px;
                    width: 10px;
                    height: 10px;
                    background-color: $c-main;
                    border-radius: 50%;
                }
            }
        }
    }

    &-icon {
        max-width: 24px;
        min-width: 24px;
        width: 24px;
        text-align: center;
        font-size: 22px;
        z-index: 1;
    }

    &-text {
        opacity: 1;
        transform: scale(1);
        word-break: break-word;
        margin-left: 15px;
        color: $white;
        font-family: $ff-second;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        transition: .3s opacity ease-in-out;
        transition: .5s transform ease-in-out;
    }

    &-registration {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        padding: 16px;
        margin: 30px calc(-50px + 25px) calc(-40px + 30px);
        background: #5B92FE1A;
        border-radius: 10px;
        transition: .3s all ease-in-out;

        &__title {
            color: $white;
            font-family: $ff-second;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &__progress {
            width: 100%;

            &-text {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-family: $ff-second;
                font-size: 13px;
                font-weight: 400;
                line-height: normal;
            }
            
            &-steps {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                grid-column-gap: 4px;
                margin-top: 8px;

                &__item {
                    height: 6px;
                    border-radius: 100px;
                    background-color: $c-main;

                    &:not(._completed) {
                        opacity: 0.3;
                    }
                }
            }
        }

        &__btn {
            width: 100%;
            height: 42px !important;
            background-color: transparent !important;
            border-width: 2px !important;
            font-size: 16px !important;
            font-weight: 500 !important;
        }
    }

    @include bp-max(md) {
        top: $topbarHeightMobil;
        height: calc(100% - $topbarHeightMobil);
        padding: 25px;
        transform: translateX(calc(0px - $sidebarWidth));
        position: fixed;

        &-profile {
            order: -2;
        }

        &-logo {
            display: none !important;
        }

        &-link__item {
            margin-left: -25px;
            padding: 16px 25px;
        }

        &-registration {
            margin: 0 -10px 15px;
            order: -1;
        }
    }
}

// === Navigation left SMALL sidebar ===
.navHidden {
    @include bp-min (lg) {
        max-width: $sidebarHiddenWidth;
        width: $sidebarHiddenWidth;
        min-width: $sidebarHiddenWidth;
        padding: 26px;

        .nav-logo {
            margin: -12px -12px 0;
            padding-bottom: 26px;
        }

        .nav-link__item {
            width: $sidebarHiddenWidth;
            margin-left: -26px;
            padding: 16px 26px;
        }

        .nav-text {
            // left: -16px;
            // text-align: center;
            // font-size: 9px;
            // left: 0;
            word-break: keep-all;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre;
            width: calc(100% + 36px);
            margin: 0;
            // bottom: -2px;
            position: absolute;
            transform: scale(0);
            opacity: 0;
            transition: .3s opacity ease-in-out;
            transition: .5s transform ease-in-out;
        }

        .nav-link__item::before {
            max-width: $sidebarHiddenWidth;
            width: $sidebarHiddenWidth;
            min-width: $sidebarHiddenWidth;
        }

        .nav-registration {
            margin-top: 0;
            margin-bottom: 100px;
            padding: 8px;
            border-radius: 0px;

            &__progress {
                margin-top: -8px;

                &-text > *:first-of-type, &-steps {
                    display: none;
                }
            }

            &__btn {
                min-width: 0 !important;
                height: 22px !important;
                padding: 3px 10px !important;
                border-width: 1px !important;
                border-radius: 4px !important;
                font-size: 12px !important;
                line-height: 14px !important;
            }
        }

        &._setup {
            max-width: $sidebarHiddenWidthSetup;
            width: $sidebarHiddenWidthSetup;
            min-width: $sidebarHiddenWidthSetup;
            padding: 24px;

            .nav-logo {
                margin: 0;
                padding-bottom: 24px;
            }
    
            .nav-link__item {
                width: $sidebarHiddenWidthSetup;
                margin-left: -24px;
                padding: 16px 24px;
            }

            .nav-link__item::before {
                max-width: $sidebarHiddenWidthSetup;
                width: $sidebarHiddenWidthSetup;
                min-width: $sidebarHiddenWidthSetup;
            }

            .tooltip__nav {
                left: calc($sidebarHiddenWidthSetup + 10px);
            }
        }
    }
}

// === Navigation make position fixed and swipe from left to right ===
.sidebarOn {
    @include bp-max (md) {
        z-index: 120;
        max-width: $sidebarWidth;
        width: $sidebarWidth;
        min-width: $sidebarWidth;
        transform: translateX(0);
        transition: .3s transform ease-in-out;
        
        .nav-text {
            opacity: 1;
            transform: scale(1);
        }
    }

    @include bp-max (sm) {
        width: calc(100vw - 60px);
        min-width: 220px;
    }
}

// === Navigation block fade after click nav will be closed ===
.nav__fade {
    width: calc(100vw);
    height: calc(100% - $topbarHeightMobil);
    display: none;
    position: absolute;
    right: 0;
    top: $topbarHeightMobil;
    background: rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(2px);
    transition: .3s all ease-in-out;
    z-index: 119;

    @include bp-min(lg) {
        display: none !important;
    }

    &._active {
        display: block;
    }
}

.nav__toggle {
    position: absolute !important;
    left: calc($sidebarWidth - 30px);
    bottom: 40px;
    width: 60px !important;
    min-width: 60px !important;
    height: 60px !important;
    padding: 0 !important;
    border-radius: 60px !important;
    background: rgba(54, 54, 54, 0.63) !important;
    backdrop-filter: blur(3px) !important;
    color: $white;
    line-height: 25px !important;
    cursor: pointer;
    transition: .3s all ease-in-out;
    // transform: rotate(180deg);
    z-index: 100;
    user-select: none;

    @include bp-max(md) {
        display: none !important;
    }

    .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover .mat-button-focus-overlay {
        background: $white;
        opacity: 0.1;
    }

    &._hidden {
        left: calc($sidebarHiddenWidth - 30px);
        transform: rotate(-180deg);
        
        &._setup {
            left: calc($sidebarHiddenWidthSetup - 30px);
        }
    }

    &__icon {
        margin-right: 4px;
    }
}
