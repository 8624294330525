.NgxEditor__Wrapper {
    margin-bottom: $input-margin-bottom;
    border-radius: $input-border-radius !important;
    border: $input-border !important;
    background-color: $input-background !important;
    color: $input-color;
    transition: 0.2s all ease-in-out;

    @include bp-max(sm) {
        border-radius: $input-border-radius_mobile !important;
    }

    &._hover, &._focus {
        border-color: $input-border_active !important;
    }

    .NgxEditor__MenuBar {
        text-transform: lowercase;
        padding: 4px 4px 0px 2px;
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
        background-color: transparent;
        border-bottom: none !important;

        @include bp-max(sm) {
            border-top-left-radius: 6px !important;
            border-top-right-radius: 6px !important;
        }

        .NgxEditor__Seperator {
            border-color: transparent;
        }

        .NgxEditor__MenuItem {
            margin-left: 2px;
            margin-bottom: 2px;
            border-radius: 4px;
            cursor: pointer;

            @media (max-width: 1024px) {
                position: static;
            }

            &:hover {
                background: transparent !important;
            }

            .NgxEditor__MenuItem--IconContainer {
                border-radius: 4px;
                color: color-mix(in srgb, $c-text_light-grey 80%, white);
                transition: 0.1s color ease-in-out;

                &:hover:not(.NgxEditor__MenuItem--Active) {
                    background: color-mix(in srgb, $c-text_light-grey 20%, transparent);
                }

                .material-icons {
                    font-size: 20px;
                }

                &.NgxEditor__MenuItem--Active {
                    color: $c-main;
                    background: color-mix(in srgb, $c-main 20%, transparent);
                }
            }

            .NgxEditor__Popup {
                min-width: 258px;
                padding: 12px;
                border-radius: 10px;
                border: 1px solid rgba(0, 0, 0, 0.09);
                box-shadow: $shadow-popup;
                background-color: white;
                z-index: 110;
                cursor: default;

                @media (max-width: 1024px) {
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .NgxEditor__ColorContainer {
                    margin-top: 2px;

                    &:first-child {
                        margin-top: 0;
                    }

                    .NgxEditor__Color {
                        padding: 0;
                        margin-left: 6px;
                        border-radius: 5px;
                        cursor: pointer;
                        
                        &:first-child {
                            margin-left: 0;
                        }

                        &[title="#ffffff"] {
                            border: 1px solid #d9d9d9;
                        }

                        &--Active:after {
                            content: "\e876";
                            font-family: "Material Icons";
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                }

                .NgxEditor__MenuItem--Button {
                    height: 42px;
                    padding: 10px 20px;
                    margin-top: 10px;
                    position: relative;
                    left: 50%;
                    transform: translate(-50%, 0);
                    border-radius: 10px;
                    border: 1px solid $c-main;
                    background-color: $white;
                    font-family: $ff-second;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    text-align: center;
                    text-transform: capitalize;
                    transition: all 0.2s ease-in-out;
                    color: $c-main;
                    cursor: pointer;

                    @include bp-max(sm) {
                        padding: 8px 16px;
                        height: 36px;
                        font-size: 14px;
                    }

                    &:hover {
                        background-color: hsla(169, 43%, 59%, 0.1);
                    }

                    &:disabled {
                        cursor: not-allowed;
                        &:hover {
                            background-color: $white;
                        }
                    }
                }
            }
        }
    }

    .NgxEditor {
        background-color: transparent;

        .NgxEditor__Content {
            padding: $input-padding_left_right;
            color: $input-color;
            font: $input-font;
            letter-spacing: $input-letter-spacing;
            transition: 0.2s all ease-in-out;

            @include bp-max(sm) {
                padding: $input-padding_left_right_mobile;
                font: $input-font_mobile;
            }

            p {
                color: $input-color;
                margin: 0 0 2px;
            }
        }

        .NgxEditor__Placeholder {
            color: $input-placeholder_color !important;
            font: $input-font;
            letter-spacing: $input-letter-spacing;
            transition: 0.2s all ease-in-out;

            &:before {
                color: $input-placeholder_color !important;
            }

            @include bp-max(sm) {
                font: $input-font_mobile;
            }
        }
    }
}

.emoji-mart {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    box-shadow: $shadow-popup;
    background-color: white;
    .emoji-mart-title-label {
        font-size: 16px;
    }
    .emoji-mart-anchors {
        padding: 2.7px 6px;
    }
    .emoji-mart-anchor {
        padding: 7.3px 4px;
        cursor: pointer;
        &:hover {
            svg {
                fill: $c-main;
            }
        }
        &.emoji-mart-anchor-selected {
            svg {
                fill: $c-main;
            }
            .emoji-mart-anchor-bar {
                background-color: $c-main !important;
            }
        }
        svg {
            fill: $c-text_light-grey;
            transition: 0.3s ease-in-out;
            @include bp-max(xs) {
                width: 18px;
                height: 18px;
            }
        }
        .emoji-mart-anchor-bar {
            background-color: transparent;
        }
    }
    .emoji-mart-category .emoji-mart-emoji span {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
    .emoji-mart-scroll {
        height: 200px;
        @media (max-width: 1024px) and (orientation: portrait) {
            height: 270px;
        }
    }
}

.picker-overlay-backdrop {
    background: transparent;
    @media (max-width: 1024px) {
        background: #0000007A;
    }
    & ~ .cdk-overlay-connected-position-bounding-box { 
        @media (max-width: 1024px) {
            justify-content: center !important;
            align-items: center !important;
            top: auto !important;
            left: auto !important;
            right: auto !important;
            bottom: auto !important;
            width: 100% !important;
            height: 100% !important;
            .cdk-overlay-pane {
                min-width: 0px !important;
                position: static !important;
                min-height: 0 !important;
            }
        }
    }
}