* {
    outline: none;
    box-sizing: border-box;
}

html {
    overflow: hidden;
    height: 100vh;
}

body {
    font-family: $ff-base;
    padding: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
    max-height: 100vh;
	height: 100%;
    background-color: $c-background;
}

// === Main wrapper pages ===
.wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
}

.main {
    transition: .3s all ease-in-out;
    flex-grow: 1;
    width: calc(100% - $sidebarHiddenWidth);

    &._setup {
        width: calc(100% - $sidebarHiddenWidthSetup);
    }

    &._active {
        transition: .3s all ease-in-out;
        width: calc(100% - $sidebarWidth);
    }
}