// /* devanagari */
// @font-face {
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: local('Poppins Regular'), local('Poppins-Regular'), url('~fonts/1.woff2') format('woff2');
//   unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: local('Poppins Regular'), local('Poppins-Regular'), url('~fonts/2.woff2') format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: local('Poppins Regular'), local('Poppins-Regular'), url('~fonts/3.woff2') format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* devanagari */
// @font-face {
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: local('Poppins Medium'), local('Poppins-Medium'), url('~fonts/4.woff2') format('woff2');
//   unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: local('Poppins Medium'), local('Poppins-Medium'), url('~fonts/5.woff2') format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: local('Poppins Medium'), local('Poppins-Medium'), url('~fonts/6.woff2') format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* devanagari */
// @font-face {
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
//   src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url('~fonts/7.woff2') format('woff2');
//   unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
//   src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url('~fonts/8.woff2') format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
//   src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url('~fonts/9.woff2') format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
/* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: local('Roboto Bold'), local('Roboto-Bold'), url('~fonts/10.woff2') format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: local('Roboto Bold'), local('Roboto-Bold'), url('~fonts/11.woff2') format('woff2');
//   unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: local('Roboto Bold'), local('Roboto-Bold'), url('~fonts/12.woff2') format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: local('Roboto Bold'), local('Roboto-Bold'), url('~fonts/13.woff2') format('woff2');
//   unicode-range: U+0370-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: local('Roboto Bold'), local('Roboto-Bold'), url('~fonts/14.woff2') format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: local('Roboto Bold'), local('Roboto-Bold'), url('~fonts/15.woff2') format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: local('Roboto Bold'), local('Roboto-Bold'), url('~fonts/16.woff2') format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }


@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Poppins:wght@400;500;600;700&family=Mulish&family=Work+Sans:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: 'Gilroy';
    src: url('~fonts/gilroyregular.woff?nfkxxh') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('~fonts/gilroymedium.woff?nfkxxh') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('~fonts/gilroysemibold.woff?nfkxxh') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('~fonts/gilroybold.woff?nfkxxh') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}