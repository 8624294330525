.auth {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    &-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        max-width: 1074px;
        height: auto;
        min-height: 735px;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.14);
        -webkit-transition: 0.3s all ease-in-out;
        transition: 0.3s all ease-in-out;
    }

    &-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 70px 80px;
        background-color: #FFF;
        -webkit-transition: 0.3s all ease-in-out;
        overflow: hidden;
        transition: 0.3s all ease-in-out;
    }

    &-right {
        position: relative;
        height: 100%;
        min-height: 700px;
        background-color: $sidebarColor;
        -webkit-transition: 0.3s all ease-in-out;
        transition: 0.3s all ease-in-out;
        pointer-events: none;
    }

    &__title {
        margin: 0 0 40px;
        color: $c-black;
        font: normal 600 34px/54px $ff-second;
    }

    &__text {
        margin: 0;
        color: $c-black;
        font: normal 500 16px/normal $ff-second;

        strong {
            font-weight: 600;
        }
    }

    &__btn, &__btn.waw-btn._second {
        margin-bottom: 20px;
        border-radius: $input-border-radius;
        border: $input-border;
        color: $input-color;
        font: normal 500 16px/19px $ff-base;

        &:hover, &:focus {
            border-color: $input-border_active;
        }

        img {
            margin-right: 10px;
        }
    }

    &__separator {
        position: relative;
        width: 100%;
        margin: 10px 0 30px;

        hr {
            margin: 7px 0;
            border: 0;
            border-top: 1px solid $c-stroke;
            -webkit-transition: 0.3s all ease-in-out;
            transition: 0.3s all ease-in-out;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: #FFF;
            padding: 0 10px;
            color: $c-text_light-grey;
            text-align: center;
            font-size: 12px;
            translate: -50% -50%;
            -webkit-transition: 0.3s all ease-in-out;
            transition: 0.3s all ease-in-out;
        }
    }

    .waw-input {
        --btn-width: 40px;
        --btn-size: 20px;

        position: relative;
        margin-bottom: 10px;

        &__title {
            color: $c-black;
            font-weight: 500;
        }

        .req-text {
            margin-top: 0px;
            margin-bottom: 6px;
            font-weight: 500;
            text-transform: none;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: keep-all;
            white-space: nowrap;
        }

        .visibility {
            position: absolute;
            right: calc($input-padding_left_right - (var(--btn-width) - var(--btn-size)) / 2 + 1px);
            bottom: calc($input-margin-bottom + ($input-height - var(--btn-width)) / 2);
            width: var(--btn-width);
            height: var(--btn-width);
            background-color: $input-background;
            line-height: var(--btn-width);
            color: $input-color;
            font-size: var(--btn-size);
            transition: all 0.15s ease-in-out;
            z-index: 10;
            
            &:not(._show) {
                visibility: hidden;
                opacity: 0;
            }

            [class^="icon-"], [class*=" icon-"] {
                display: block;
            }

        }
        
        &:hover .visibility {
            visibility: visible;
            opacity: 1;
        }

        .check input, .req input {
            padding-right: calc($input-padding_left_right + 20px);
        }

        .check::after, .req::after {
            position: absolute;
			right: $input-padding_left_right;
			bottom: calc($input-margin-bottom + $input-height / 2);
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 20px;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
			translate: 0 50%;
            user-select: none;
            pointer-events: none;
            z-index: 5;
        }

        .check::after {
            content: "\e86c";
            color: $c-main;
            font-size: 14px;
        }

        .req::after {
            content: "\e000";
            color: $input-req_color;
            font-size: 20px;
        }

        input[name=password] {
            padding-right: calc($input-padding_left_right - (var(--btn-width) - var(--btn-size)) / 2 + var(--btn-width));
        }
    }

    &__note {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font: normal 500 14px/20px $ff-second;

        &-text {
            color: $c-black;
        }

        &-link {
            color: $c-main;
            cursor: pointer;
            transition: 0.07s all ease-in-out;

            &:visited {
                color: $c-main;
            }

            &:active, &:hover, &:focus {
                filter: brightness(0.9);
            }
        }

        &-text + &-link {
            flex-grow: 1;
            margin-left: 20px;
        }
    }

    .waw-input + &__note {
        margin-top: -4px;
    }

    button[type=submit] {
        margin-top: 40px;
        width: 100%;
    }

    &__logo {
        position: relative;
        top: 85px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-text {
            margin-left: 35px;
            color: #FFF;
            font: normal 500 24px Roboto, sans-serif;
        }
    }

    &__preview-1, &__preview-2, &__preview-3 {
        border-radius: 6px;
        border: 2px solid #FFFFFF;
        background-color: #FFFFFF;
        box-shadow: -6px 9px 17px 0px #1D24473B;
        overflow: hidden;
    }
    &__preview-1 {
        position: absolute;
        right: 44px;
        top: 240px;
        z-index: 2;
    }
    &__preview-2 {
        position: absolute;
        left: 44px;
        top: 345px;
        z-index: 1;
    }
    &__preview-3 {
        position: absolute;
        top: 458px;
        right: 68px;
        z-index: 3;
    }

    .password-verification {
        display: none;
        margin-bottom: 20px;

        &__item {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            color: $input-req_color;
            transition: 0.2s all ease-in-out;

            &.check {
                color: $c-main;
            }

            .material-icons {
                margin-right: 5px;
                font-size: 20px;
            }

            span {
                text-transform: lowercase;
                margin-bottom: 0;
            }
        }
    }

    @include bp-max(md) {
        &-container {
            grid-template-columns: 1fr;
            max-width: 537px;
            min-height: 0;
        }

        &-left {
            min-height: 500px;
        }

        &-right {
            display: none;
        }
    }

    @include bp-max(sm) {
        padding-top: 90px;
        padding-bottom: 40px;
        
        &-container {
            max-width: none;
            height: 100%;
            border-radius: 0;
            box-shadow: none;
        }

        &-left {
            justify-content: space-between;
            min-height: 0;
            padding: 0;
            background-color: transparent;
        }

        &__separator span {
            background-color: $c-background;
        }

        &__note-text + &__note-link {
            flex-grow: 0;
            margin-left: 0;
        }
    }
}