// CHECKBOX
.waw-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    // cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // hidden toggle input
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:disabled {
            cursor: default;
        }
    }

    &:hover input~.checkmark:before {
        opacity: 0.3;
    }
    
    &:hover input:checked~.checkmark:before {
        background-color: $c-main;
        opacity: 0.15;
    }

    &:active input~.checkmark:before {
        opacity: 0.6;
    }
    
    &:active input:checked~.checkmark:before {
        background-color: $c-main;
        opacity: 0.3;
    }

    input:checked~.checkmark {
        background-color: $c-main;
        border: none;
        transition: 0.2s ease-in-out;
    }

    input:checked~.checkmark:after {
        display: block;
    }

    .checkmark {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        width: 22px;
        height: 22px;
        border-radius: 3px;
        background-color: transparent;
        border: 2px solid #E1E7F3;
        flex-shrink: 0;
        transition: 0.2s ease-in-out;
        transform-style: preserve-3d;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            width: 40px;
            height: 40px;
            left: 50%;
            top: 50%;
			transform: translate(-50%, -50%) translateZ(-1px);
            border-radius: 50%;
            background-color: #E1E7F3;
            opacity: 0;
            transition: 0.2s ease-in-out;
        }

        &:after {
            content: "\e92f";
            position: absolute;
            display: none;
            left: 50%;
            top: 50%;
			transform: translate(-50%, -50%);
            color: $white;
            font-family: 'icomoon' !important;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transition: 0.2s ease-in-out;
        }
    }
	input:disabled~.checkmark {
        background-color: #C7CDD9;
        cursor: default;
	}

    input:disabled~&__text {
        cursor: default;
    }

    &:hover input:disabled~.checkmark:before {
        opacity: 0 !important;
    }

    &:active input:disabled~.checkmark:before {
        opacity: 0 !important;
    }

    &__text {
        margin-left: 10px;
        color: $c-text_dark-grey;
        font-family: $ff-second;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        cursor: pointer;
    }
}
