// INPUT
.waw-textarea {
    position: relative;
    &__title {
        margin-bottom: $input-label_margin;
        display: block;
        color: $input-label_color;
        font: $input-label_font;
        text-transform: capitalize;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: keep-all;
        white-space: nowrap;
    }
    textarea {
        display: block;
        width: 100%;
        min-height: $input-height;
        max-height: 150px;
        resize: vertical;
        padding: $input-padding_left_right;
        margin-bottom: $input-margin-bottom;
        border-radius: $input-border-radius;
        border: $input-border;
        background-color: $input-background;
        color: $input-color;
        font: $input-font;
        letter-spacing: $input-letter-spacing;
        transition: 0.2s all ease-in-out;
        @include bp-max(sm) {
            min-height: $input-height_mobile;
            padding: $input-padding_left_right_mobile;
            border-radius: $input-border-radius_mobile;
            font: $input-font_mobile;
        }
        &::placeholder {
            color: $input-placeholder_color;
            font: $input-font;
            letter-spacing: $input-letter-spacing;
            transition: 0.2s all ease-in-out;
            @include bp-max(sm) {
                font: $input-font_mobile;
            }
        }
        &:hover:not(:disabled), &:focus:not(:disabled) {
            border-color: $input-border_active;
        }
        &:disabled {
            cursor: not-allowed;
        }
    }
	.req {
		textarea {
			border-color: $input-req_color;
		}
        .NgxEditor__Wrapper {
			border-color: $input-req_color !important;
        }
        &-text {
			margin-top: $input-req_top;
			display: block;
            color: $input-req_color;
            font: $input-req_font;
        }
    }
}
