@font-face {
  font-family: 'icomoon';
  src: url('~fonts/icon/icomoon.woff?nfkxxh') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
//   speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-profile:before {
  content: "\e93b";
  color: $white;
}
.icon-analytics:before {
  content: "\e904";
  color: $white;
}
.icon-schedule:before {
  content: "\e93a";
  color: $white;
}
.icon-calendar:before {
  content: "\e905";
  color: $white;
}
.icon-clients:before {
  content: "\e906";
  color: $white;
}
.icon-directory:before {
  content: "\e907";
  color: $greyL;
}
.icon-home:before {
  content: "\e908";
  color: $greyL;
}
.icon-logo .path1:before {
  content: "\e909";
  color: rgb(255, 255, 255);
}
.icon-logo .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(230, 92, 35);
}
.icon-logo .path3:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(69, 194, 230);
}
.icon-logo .path4:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(104, 199, 179);
}
.icon-logo .path5:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-messages:before {
  content: "\e90e";
  color: $white;
}
.icon-sales:before {
  content: "\e90f";
  color: $greyL;
}
.icon-services:before {
  content: "\e910";
  color: $white;
}
.icon-setup:before {
  content: "\e911";
  color: $white;
}
.icon-staff:before {
  content: "\e912";
  color: $white;
}
.icon-toggle:before {
  content: "\e913";
  color: $greyL;
}
.icon-bell:before {
  content: "\1f323";
}
.icon-search:before {
  content: "\e914";
}
.icon-plus:before {
  content: "\e915";
}
.icon-menu:before {
  content: "\e916";
  color: #101747;
}
.icon-clock:before {
  content: "\e943";
}
.icon-exit:before {
  content: "\ea14";
}
	
.icon-clipboard:before {
  content: "\e9b8";
}
.icon-users:before {
  content: "\e972";
}
.icon-file:before {
  content: "\e92a";
}
.icon-pencil:before {
  content: "\e918";
}
.icon-edit:before {
  content: "\e949";
}
.icon-lock:before {
  content: "\e919";
  color: $blue;
}
.icon-resources:before {
  content: "\e91a";
  color: $white;
}
.icon-facebook:before {
  content: "\e91b";
  color: $blue;
}
.icon-instagram:before {
  content: "\e91c";
  color: $blue;
}
.icon-website:before {
  content: "\e91d";
  color: $blue;
}
.icon-warning .path1:before {
  content: "\e91e";
  color: #e74c3c;
}
.icon-warning .path2:before {
  content: "\e91f";
  color: $white;
  margin-left: -1em;
}
.icon-clock1:before {
  content: "\e920";
  color: $sky;
}
.icon-delete:before {
  content: "\e921";
}
.icon-user-checked:before {
  content: "\e922";
  color: $green;
}
.icon-user-clock:before {
  content: "\e923";
  color: #c9b60f;
}
.icon-user-block:before {
  content: "\e924";
  color: $red;
}
.icon-filter:before {
  content: "\e93d";
}

.icon-click-info:before {
  content: "\e926";
}
.icon-clock-in:before {
  content: "\e928";
}
.icon-clock-out:before {
  content: "\e927";
}
.icon-clock-pause:before {
  content: "\e929";
}

.icon-clock-play:before {
  content: "\e92b";
}
.icon-smile:before {
  content: "\e92d";
  color: $sky;
}

.icon-send:before {
  content: "\e92c";
  color: $sky;
}

.icon-close:before {
  content: "\e92e";
}
.icon-check:before {
  content: "\e92f";
}

.icon-add-client:before {
  content: "\e901";
}
.icon-add-staff:before {
  content: "\e900";
}

.icon-upload:before {
  content: "\e902";
}
.icon-download:before {
  content: "\e903";
}

.icon-chevron:before {
  content: "\e917";
  color: $grey;
}
.icon-arrow_right:before {
  content: "\e93c";
}
.icon-arrow_left:before {
  content: "\e93d";
}
.icon-arrow_down:before {
  content: "\e93f";
}
.icon-arrow_up:before {
  content: "\e93e";
}
.icon-arrow_down, .icon-arrow_up {
  line-height: normal;
}

.icon-permission-schedule:before {
  content: "\e939";
}
.icon-permission-calendar:before {
  content: "\e938";
}
.icon-permission-clients:before {
  content: "\e937";
}
.icon-permission-staff:before {
  content: "\e936";
}
.icon-permission-services:before {
  content: "\e935";
}
.icon-permission-resources:before {
  content: "\e934";
}
.icon-permission-setup:before {
  content: "\e933";
}
.icon-permission-messages:before {
  content: "\e932";
}

.icon-photo:before {
  content: "\e941";
}
.icon-print:before {
  content: "\e942";
}

.icon-info:before {
  content: "\e945";
}
.icon-user:before {
  content: "\e946";
}

.icon-visibility:before {
  content: "\e948";
}
.icon-visibility_off:before {
  content: "\e947";
}