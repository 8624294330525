.discount-container {
    padding: 0px 20px 20px 20px;
    overflow: auto;
    height: calc(100% - $topbarHeight);

    @include bp-max(md) {
        height: calc(100% - $topbarHeightMobil);
    }
}

.discountAdd {
    padding: 20px;
}

.discount-list {

    &__body {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(575px, 1fr));
        grid-gap: 40px;
        justify-items: center;

        @media screen and (max-width: 600px) {
            width: 100%;
            grid-template-columns: 1fr;
        }
    }

    &__item {
        width: 100%;
        background: $white;
        box-shadow: 0px 4px 4px rgba(51, 77, 110, 0.25);
        border-radius: 35px;
        padding: 40px 50px 40px;
        position: relative;
        @include bp-max (sm) {
            border-radius: 15px;
            padding: 30px 30px 15px;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: $blue;
        margin-bottom: 10px;
    }

    &__property {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: $blue;
        margin-top: 8px;
        display: grid;
        grid-template-columns: auto 1fr;

        div {
            font-weight: 600;
            margin-right: 5px;
        }
    }
    &__icon {
        color: $grey;
        position: absolute;
        top: 20px;
        right: 50px;
        & i:hover {
            color: $black;
            cursor: pointer;
        }
        & i:last-child {
            margin-left: 40px;
        }
        i {
            font-size: 22px;
        }
        @include bp-max (sm) {
            top: 10px;
            right: 30px;
            & i:last-child {
                margin-left: 30px;
            }
            i {
                font-size: 20px;
            }
        }
    }
    &__description {
        margin-bottom: 18px;
        margin-top: 2px;
        color: $grey;
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
    }
}
