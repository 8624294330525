.timepicker-wrapp {
    input {
        border: none;
        padding: 0;
        background: transparent;
    }
    button {
        padding: 0;
        border: none;
        background: none;
    }
    &.req {
        .timepicker__container {
            border-color: $input-req_color;
        }
    }
    .req-text {
        margin-top: $input-req_top;
        display: block;
        color: $input-req_color;
        font: $input-req_font;
    }
    &.disabled {
        .timepicker__container {
            cursor: not-allowed;
            &:hover {
                border-color: $c-stroke;
            }
            .btn, input {
                cursor: not-allowed;
            }
        }
    }
}

.timepicker__label {
    margin-bottom: $input-label_margin;
    display: block;
    color: $input-label_color;
    font: $input-label_font;
    text-transform: capitalize;
    text-overflow: ellipsis;
	overflow: hidden;
	word-break: keep-all;
	white-space: nowrap;
}

.timepicker__container {
    position: relative;
    width: 100%;
    min-width: 130px;
    height: $input-height;
    padding: $input-padding_top_bottom calc($input-padding_left_right + $input-btn_width_height) $input-padding_top_bottom $input-padding_left_right;
    margin-bottom: $input-margin-bottom;
    border-radius: $input-border-radius;
    border: $input-border;
    background-color: $input-background;
    transition: 0.15s all ease-in-out;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        border-color: $input-border_active;
    }
    .btn {
        position: absolute;
        top: 50%;
        right: $input-btn_right;
        width: $input-btn_width_height;
        height: $input-btn_width_height;
        font-size: $input-btn_font-size;
        line-height: $input-btn_width_height;
        border-radius: $input-btn_border-radius;
        translate: 0 -50%;
        transition: $input-btn_transition;
    }
    input {
        position: absolute;
        top: 50%;
        translate: 0 -50%;
        width: calc(100% - ($input-padding_left_right + $input-btn_width_height) - $input-padding_left_right);
        color: $input-color;
        font: $input-font;
        letter-spacing: $input-letter-spacing;
        cursor: pointer;

        &::placeholder {
            color: $input-placeholder_color;
            font: $input-font;
            letter-spacing: $input-letter-spacing;
            transition: 0.15s all ease-in-out;
        }
    }
    &.focus {
        border-color: $input-border_active;
    }

    @include bp-max(sm) {
        min-width: 90px;
        height: $input-height_mobile;
        padding: $input-padding_top_bottom_mobile calc(($input-height_mobile - $input-btn_width_height_mobile) / 2 + $input-btn_width_height_mobile) $input-padding_top_bottom_mobile $input-padding_left_right_mobile;
        border-radius: $input-border-radius_mobile;

        .btn {
            width: $input-btn_width_height_mobile;
            height: $input-btn_width_height_mobile;
            right: $input-btn_right_mobile;
            flex-shrink: 0;
            font-size: calc($input-btn_font-size - 1px);
            line-height: $input-btn_width_height_mobile;
        }

        input { 
            width: calc(100% - (($input-height_mobile - $input-btn_width_height_mobile) / 2 + $input-btn_width_height_mobile) - $input-padding_left_right_mobile);
            font: $input-font_mobile;

            &::placeholder { 
                font: $input-font_mobile;
            }
        }
    }
}

._icon .timepicker__container {
    padding-left: 44px;

    &::before {
        content: "\e943";
        position: absolute;
        left: 10px;
        top: 50%;
        color: #D6D6DC;
        font-family: 'icomoon' !important;
        font-size: 24px;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate(0, -50%);
    }

    input {
        width: calc(100% - 42px - 44px);
    }

    @include bp-max(sm) {
        padding-left: 36px;

        &::before {
            font-size: 20px;
        }

        input {
            width: calc(100% - 32px - 36px);
        }
    }
}

@include bp-min(md) {
    ._left {
        padding-right: 10px;
    }
    
    ._right {
        padding-left: 10px;
    }
}

.timepicker__dropdown {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    box-shadow: $shadow-popup;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    &-time {
        input {
            height: $input-height;
            min-height: $input-height;
            max-height: $input-height;
            padding: $input-padding_top_bottom $input-padding_left_right;
            margin: 0;
            border-radius: $input-border-radius;
            border: $input-border;
            background-color: $input-background;
            color: $input-color;
            font: $input-font;
            text-align: center;
            letter-spacing: $input-letter-spacing;
            transition: 0.15s all ease-in-out;

            &:hover, &:focus {
                border-color: $input-border_active;
            }
            &::placeholder {
                color: $input-placeholder_color;
                font: $input-font;
                letter-spacing: $input-letter-spacing;
                transition: 0.15s all ease-in-out;
            }
            &::selection {
                background-color: $c-main;
                color: $white;
            }
        }
        &-separator {
            margin: 0 10px;
            color: $input-color;
            font: $input-font;
            text-align: center;
            letter-spacing: $input-letter-spacing;
            user-select: none;
        }
    }
    &-actions {
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        button {
            width: 30px;
            height: 30px;
            line-height: 30px;
            .material-icons {
                font-size: 22px;
                line-height: 22px;
            }
        }
    }
    &-period {
        min-width: 0 !important;
    }

    @include bp-max(sm) {
        &-time {
            input {
                height: $input-height_mobile;
                min-height: $input-height_mobile;
                max-height: $input-height_mobile;
                padding: $input-padding_top_bottom_mobile $input-padding_left_right_mobile;
                border-radius: $input-border-radius_mobile;
                font: $input-font_mobile;

                &::placeholder {
                    font: $input-font_mobile;
                }
            }

            &-separator {
                margin: 0 8px;
                font: $input-font_mobile;
            }
        }

        &-actions {
            margin: 0 8px;
        }

        &-period {
            padding: 8px 10px !important;
            height: 32px !important;
            font-size: 12px !important;
            line-height: 16px !important;
        }
    }
}
