// RADIO
.waw-radio {
  display: flex;
  align-items: center;
  font-size: 16px;
  height: auto;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  &:hover input~.circle:before {
    opacity: 0.3;
  }

  &:hover input:checked~.circle:before {
      background-color: $c-main;
      opacity: 0.15;
  }

  &:active input~.circle:before {
      opacity: 0.6;
  }

  &:active input:checked~.circle:before {
      background-color: $c-main;
      opacity: 0.3;
  }

  input:checked ~ .circle {
    // background-color: $white;
    background-color: $c-main;
    border: 2px solid $c-main;
    outline: 3.5px solid $white;
    outline-offset: -5px;
  }

  input:checked ~ .circle:after {
    display: block;
  }

  .circle {
    position: relative;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid #E1E7F3;
    flex-shrink: 0;
    transition: 0.2s ease-in-out;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      width: 40px;
      height: 40px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: #E1E7F3;
      opacity: 0;
      transition: 0.2s ease-in-out;
    }

    // &:after {
    //   content: "";
    //   position: absolute;
    //   display: none;
    //   // left: 2px;
    //   // top: 2px;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   background: $c-green;
    //   border-radius: 50%;
    //   width: 11px;
    //   height: 11px;
    // }
  }

  
  &__text {
    margin-left: 10px;
    color: $c-text_dark-grey;
    font-family: $ff-second;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
}
