// text
.ta-r{
	text-align: right;
}

.ta-l{
	text-align: left;
}

.ta-c{
	text-align: center;
}

// margin

.m0{
	margin: 0;
}
.m0-a {
	margin: 0 auto;
}
.ml-a {
	margin-left: auto;
}
.mr-a {
	margin-right: auto;
}

.m40 {
    margin: 40px;
}
// margins top
.mt0 {
	margin-top: 0 !important;
}
.mt15 {
	margin-top: 15px;
}
.mt10{
	margin-top: 10px !important;
}
.mt20{
	margin-top: 20px !important;
}
.mt25 {
	margin-top: 25px;
}
.mt30 {
	margin-top: 30px !important;
}
// margins right
.mr0 {
	margin-right: 0 !important;
}
.mr5{
	margin-right: 5px;
}
.mr15{
	margin-right: 15px;
}
.mr10 {
	margin-right: 10px;
}
.mr20 {
	margin-right: 20px;
}
.mr30 {
    margin-right: 30px !important;
}
.mr35 {
    margin-right: 35px !important;
}
.mr50 {
	margin-right: 50px;
}
// margins bottom
.mb0 {
	margin-bottom: 0 !important;
}
.mb5{
	margin-bottom: 5px;
}
.mb10{
	margin-bottom: 10px;
}
.mb15{
	margin-bottom: 15px;
}
.mb20{
	margin-bottom: 20px !important;
}
.mb30{
	margin-bottom: 30px !important;
}
.mb40{
	margin-bottom: 40px;
}

// margins left
.ml15{
	margin-left: 15px;
}
.ml10{
	margin-left: 10px;
}
.ml20{
	margin-left: 20px;
}

// margins y
.my5 {
	margin-top: 5px;
	margin-bottom: 5px;
}
.my10 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.my40 {
    margin-bottom: 40px;
    margin-top: 40px;
}

.p0 {
	padding: 0 !important;
}
.pb0 {
	padding-bottom: 0 !important;
}
.pb10 {
	padding-bottom: 10px;
}
.pb20 {
	padding-bottom: 20px !important;
}
.p20 {
	padding: 20px;
}


// flex style
.fl-column-ai-c {
	flex-direction: column;
	align-items: center;
	display: flex;
}

.d-flex{
	display: flex;
}
.flex-column {
	display: flex;
	flex-direction: column;
}
.fl-c-c {
	display: flex;
	justify-content: center;
	align-items: center;
}
.fl-sb-c {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.jcse, .fl-se-c {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
.ai-c{
	display: flex;
	align-items: center;
}

.jc-c{
	display: flex;
	justify-content: center;
}
.jc-e{
	display: flex;
	justify-content: flex-end;
}
.jc-s{
	display: flex;
	justify-content: flex-start;
}

.fl-grow {
	flex-grow: 1;
}


// .cl-green{
// 	color: $green;
// }
.cl-white{
	color: $white;
}

// .cl-grey{
// 	color: $grey;
// }


.btn-icon {
	background: transparent;
	border: none;
}


.l0 {
	left: 0;
}
.t0 {
	top: 0;
}


.w50 {
    width: 50%;
}
.w100 {
	width: 100%;
}

.fw500 {
    font-weight: 500;

}

.break-word {
	word-break: break-word;
}
.pos-rel {
	position: relative;
}
.cur-p {
	cursor: pointer;
}
.ma {
	margin-left: auto;
	margin-right: auto;
}
.font-bold {
	font-weight: bold !important;
}

.m-20 {
	margin: -20px;
}

.hidden-md {
	@include bp-max(md) {
		display: none !important;
	}
}

.hidden-sm {
	@include bp-max(sm) {
		display: none !important;
	}
}

.hidden-xs {
	@include bp-max(xs) {
		display: none !important;
	}
}

.display-sm {
	@include bp-min(md) {
		display: none !important;
	}
}

.display-xs {
	@include bp-min(sm) {
		display: none !important;
	}
}