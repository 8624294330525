// new code
.notes {
    .timeline {
        position: relative;
        max-width: 100%;
        margin: 20px auto 0;
        padding: 0;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 3px;
            height: 100%;
			box-sizing: border-box;
            margin: auto;
            background: $grey;
        }
    }

    .event {
        position: relative;
        overflow: hidden;
        padding: 20px;


        .event-date {
            position: absolute;
            top: 57%;
            right: 0;
            width: 46%;
            margin-top: -20px;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.01em;
            color: $grey;

            div {
                margin-bottom: 5px;
            }
        }

        .event-desc {
            position: relative;
            float: left;
            width: 46%;
            height: auto;
            padding: 20px;
            line-height: 22px;
            background: $white;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
            border-radius: 3px;

        }

        &:nth-child(even) {
            .event-date {
                left: 0;
                text-align: right;
            }

            .event-desc {
                float: right;
            }
        }

        .event-desc__counter {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            width: 30px;
            height: 30px;
            margin: -8px auto 0;
            background: #005797;
            border-radius: 50%;

            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 21px;
                height: 21px;
                border-radius: 50%;
                left: 3.28px;
                background: transparent;
                border: 1.5px solid$white;
                top: 3px;
            }
        }


    }

    @media all and (max-width: 900px) {

        .timeline {
            padding-right: 32px;

            &:before {
                left: 28px;
                right: auto;
                margin: 0;
                padding: 0 0 50px;
            }
        }

        .event {
            padding-left: 60px;

            .event-date {
                position: relative;
                top: auto;
                right: auto;
                left: 35px;
                width: 100%;
                margin: 0 0 16px;
            }

            .event-desc {
                float: none;
                width: 100%;
                overflow: hidden;




            }

            .event-desc__counter {
                top: 20px;
                left: 14px;
                right: auto;
                margin: 0;


            }

            &:nth-child(even) {
                .event-date {
                    left: 35px;
                    text-align: left;
                }

                .event-desc {
                    float: none;
                }
            }

            &:before {
                top: 20px;
                left: 0;
                right: auto;
                margin: 0;
            }
        }
    }



    @media all and (max-width: 576px) {
        .timeline {
            padding-right: 0;

            &::before {
                display: none;
            }
        }

        .event-desc__price {
            right: 10px;
            top: 2px;
            font-size: 12px;
        }

        .event-desc__counter {
            display: none;
        }

        .event {
            margin: -20px;
            padding-left: 20px;


        }

        .event-date {
            left: 0 !important;
        }

        .event-desc__info {
            flex-direction: column;
            align-items: start !important;
        }

        .event-desc__status {
            position: absolute;
            right: 20px;
        }

        .event-desc__clock {
            text-align: left;

            div {
                text-align: left;
            }

            display: flex;
            margin-bottom: 5px;
        }

        .row {
            margin-left: 0 !important;
        }

        .cl-service-nav {
            flex-direction: column;
        }

        .cl-service-nav__right {
            margin-top: 20px;
            width: 100%;

            .cl-service-nav__datepicker {
                width: 100%;
            }
        }
    }



    .event-desc {
        display: flex;
        justify-content: space-between;

        &__left {
            max-width: 100px;
            width: 100%;
            margin-right: 10px;

        }

        &__right {
            flex-grow: 1;
            width: 100%;
        }

        &__img {
            margin: 0 auto 10px auto;
            min-width: 50px;
            min-height: 50px;
            width: 50px;
            height: 50px;
            border: 1.5px solid #DFE0EB;
            box-sizing: border-box;
            border-radius: 50%;
            padding: 3px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        &__name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: $blue;
            word-break: break-word;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }



        &__info {
            display: flex;
            align-items: center;

            .row {
                margin-left: 20px;
                padding-right: 35px;
                display: flex;
                align-items: center;

                &:first-child {
                    margin-bottom: 5px;
                }
            }
        }

        &__title {
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.01em;
            color: $blue;
        }

        &__time {
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.01em;
            color: $blue;
        }

        &__desc {
			word-break: break-word;
            padding-top: 6px;
            margin-top: 15px;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: $blue;
        }

        &__day,
        &__month {
            text-align: center;
            width: 35px;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: $blue;

        }

        &__status {
            text-align: center;
            font-size: 12px;
            margin-left: 15px;
            line-height: 12px;
            letter-spacing: 0.01em;
            color: $sky;
        }

        &__price {
            position: absolute;
            right: 15px;
            top: 16px;
        }
    }


    .event {
        margin: 0;
    }
}