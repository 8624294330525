@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Poppins:wght@400;500;600;700&family=Mulish&family=Work+Sans:wght@300;400;500;600;700&display=swap");
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #6ac4b3;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: white;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e47878;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #6ac4b3;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: white;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e47878;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #6ac4b3;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: white;
  color: black;
}

.mat-badge-warn .mat-badge-content {
  color: black;
  background: #e47878;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #6ac4b3;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: white;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #e47878;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #6ac4b3;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: white;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e47878;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: black;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: black;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #6ac4b3;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #e47878;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #6ac4b3;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: white;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e47878;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #6ac4b3;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: white;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e47878;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #6ac4b3;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e47878;
  color: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: white;
  color: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(106, 196, 179, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(106, 196, 179, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(106, 196, 179, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #6ac4b3;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(106, 196, 179, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(106, 196, 179, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(106, 196, 179, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 255, 255, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 255, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: white;
  color: black;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 255, 255, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 255, 255, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 255, 255, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(228, 120, 120, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(228, 120, 120, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(228, 120, 120, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e47878;
  color: black;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(228, 120, 120, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(228, 120, 120, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(228, 120, 120, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #6ac4b3;
}
.mat-datepicker-toggle-active.mat-accent {
  color: white;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #e47878;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #6ac4b3;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: white;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e47878;
}

.mat-focused .mat-form-field-required-marker {
  color: white;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #6ac4b3;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: white;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e47878;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #6ac4b3;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: white;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e47878;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e47878;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e47878;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e47878;
}

.mat-error {
  color: #e47878;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #6ac4b3;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: white;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e47878;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e47878;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #6ac4b3;
}
.mat-icon.mat-accent {
  color: white;
}
.mat-icon.mat-warn {
  color: #e47878;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #6ac4b3;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: white;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #e47878;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e47878;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #d6ede8;
}

.mat-progress-bar-buffer {
  background-color: #d6ede8;
}

.mat-progress-bar-fill::after {
  background-color: #6ac4b3;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbfbfb;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbfbfb;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: white;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5dada;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5dada;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e47878;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #6ac4b3;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: white;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #e47878;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #6ac4b3;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #6ac4b3;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: white;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: white;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e47878;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e47878;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #6ac4b3;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: white;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e47878;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e47878;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: white;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: white;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #6ac4b3;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(106, 196, 179, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #6ac4b3;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e47878;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(228, 120, 120, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e47878;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #6ac4b3;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(106, 196, 179, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: white;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: black;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e47878;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: black;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(228, 120, 120, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #6ac4b3;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: black;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: white;
  color: black;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: black;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e47878;
  color: black;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e47878;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #e47878;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(210, 237, 232, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #6ac4b3;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 255, 255, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: black;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(247, 215, 215, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e47878;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: black;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(210, 237, 232, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #6ac4b3;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 255, 255, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: black;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: black;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(247, 215, 215, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e47878;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: black;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: black;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #6ac4b3;
  color: white;
}
.mat-toolbar.mat-accent {
  background: white;
  color: black;
}
.mat-toolbar.mat-warn {
  background: #e47878;
  color: black;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: white;
}

:root {
  --blue-color:#191C1F;
  --sky-color: #68C7B3;
  --skyL-color: #8ef5df;
  --grey-color: #BDBDBD;
  --greyL-color: #F2F2F2;
  --red-color: #A31010;
  --redL-color: #8C1B0F;
  --green-color: #089227;
  --white-color: #fff;
  --black-color: #000;
  --ff-base: "Gilroy", sans-serif;
  --ff-second: "Poppins", sans-serif;
  --ff-third: "Work Sans", sans-serif;
  --ff-field: "Mulish", sans-serif;
  --sidebar-color: #1F2226;
  --sidebar-width: 320px;
  --sidebar-hidden-width: 76px;
  --sidebar-hidden-width-setup: 99px;
  --topbar-height: 74px;
  --topbar-height-mobile: 80px;
}

/* cyrillic-ext */
@font-face {
  font-family: "Gilroy";
  src: url("~fonts/gilroyregular.woff?nfkxxh") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("~fonts/gilroymedium.woff?nfkxxh") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("~fonts/gilroysemibold.woff?nfkxxh") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("~fonts/gilroybold.woff?nfkxxh") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "icomoon";
  src: url("~fonts/icon/icomoon.woff?nfkxxh") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-profile:before {
  content: "\e93b";
  color: var(--white-color);
}

.icon-analytics:before {
  content: "\e904";
  color: var(--white-color);
}

.icon-schedule:before {
  content: "\e93a";
  color: var(--white-color);
}

.icon-calendar:before {
  content: "\e905";
  color: var(--white-color);
}

.icon-clients:before {
  content: "\e906";
  color: var(--white-color);
}

.icon-directory:before {
  content: "\e907";
  color: var(--greyL-color);
}

.icon-home:before {
  content: "\e908";
  color: var(--greyL-color);
}

.icon-logo .path1:before {
  content: "\e909";
  color: white;
}

.icon-logo .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: #e65c23;
}

.icon-logo .path3:before {
  content: "\e90b";
  margin-left: -1em;
  color: #45c2e6;
}

.icon-logo .path4:before {
  content: "\e90c";
  margin-left: -1em;
  color: #68c7b3;
}

.icon-logo .path5:before {
  content: "\e90d";
  margin-left: -1em;
  color: white;
}

.icon-messages:before {
  content: "\e90e";
  color: var(--white-color);
}

.icon-sales:before {
  content: "\e90f";
  color: var(--greyL-color);
}

.icon-services:before {
  content: "\e910";
  color: var(--white-color);
}

.icon-setup:before {
  content: "\e911";
  color: var(--white-color);
}

.icon-staff:before {
  content: "\e912";
  color: var(--white-color);
}

.icon-toggle:before {
  content: "\e913";
  color: var(--greyL-color);
}

.icon-bell:before {
  content: "🌣";
}

.icon-search:before {
  content: "\e914";
}

.icon-plus:before {
  content: "\e915";
}

.icon-menu:before {
  content: "\e916";
  color: #101747;
}

.icon-clock:before {
  content: "\e943";
}

.icon-exit:before {
  content: "\ea14";
}

.icon-clipboard:before {
  content: "\e9b8";
}

.icon-users:before {
  content: "\e972";
}

.icon-file:before {
  content: "\e92a";
}

.icon-pencil:before {
  content: "\e918";
}

.icon-edit:before {
  content: "\e949";
}

.icon-lock:before {
  content: "\e919";
  color: var(--blue-color);
}

.icon-resources:before {
  content: "\e91a";
  color: var(--white-color);
}

.icon-facebook:before {
  content: "\e91b";
  color: var(--blue-color);
}

.icon-instagram:before {
  content: "\e91c";
  color: var(--blue-color);
}

.icon-website:before {
  content: "\e91d";
  color: var(--blue-color);
}

.icon-warning .path1:before {
  content: "\e91e";
  color: #e74c3c;
}

.icon-warning .path2:before {
  content: "\e91f";
  color: var(--white-color);
  margin-left: -1em;
}

.icon-clock1:before {
  content: "\e920";
  color: var(--sky-color);
}

.icon-delete:before {
  content: "\e921";
}

.icon-user-checked:before {
  content: "\e922";
  color: var(--green-color);
}

.icon-user-clock:before {
  content: "\e923";
  color: #c9b60f;
}

.icon-user-block:before {
  content: "\e924";
  color: var(--red-color);
}

.icon-filter:before {
  content: "\e93d";
}

.icon-click-info:before {
  content: "\e926";
}

.icon-clock-in:before {
  content: "\e928";
}

.icon-clock-out:before {
  content: "\e927";
}

.icon-clock-pause:before {
  content: "\e929";
}

.icon-clock-play:before {
  content: "\e92b";
}

.icon-smile:before {
  content: "\e92d";
  color: var(--sky-color);
}

.icon-send:before {
  content: "\e92c";
  color: var(--sky-color);
}

.icon-close:before {
  content: "\e92e";
}

.icon-check:before {
  content: "\e92f";
}

.icon-add-client:before {
  content: "\e901";
}

.icon-add-staff:before {
  content: "\e900";
}

.icon-upload:before {
  content: "\e902";
}

.icon-download:before {
  content: "\e903";
}

.icon-chevron:before {
  content: "\e917";
  color: var(--grey-color);
}

.icon-arrow_right:before {
  content: "\e93c";
}

.icon-arrow_left:before {
  content: "\e93d";
}

.icon-arrow_down:before {
  content: "\e93f";
}

.icon-arrow_up:before {
  content: "\e93e";
}

.icon-arrow_down, .icon-arrow_up {
  line-height: normal;
}

.icon-permission-schedule:before {
  content: "\e939";
}

.icon-permission-calendar:before {
  content: "\e938";
}

.icon-permission-clients:before {
  content: "\e937";
}

.icon-permission-staff:before {
  content: "\e936";
}

.icon-permission-services:before {
  content: "\e935";
}

.icon-permission-resources:before {
  content: "\e934";
}

.icon-permission-setup:before {
  content: "\e933";
}

.icon-permission-messages:before {
  content: "\e932";
}

.icon-photo:before {
  content: "\e941";
}

.icon-print:before {
  content: "\e942";
}

.icon-info:before {
  content: "\e945";
}

.icon-user:before {
  content: "\e946";
}

.icon-visibility:before {
  content: "\e948";
}

.icon-visibility_off:before {
  content: "\e947";
}

/* Breakpoints map with keys */
/* Media queries mixins */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

body {
  margin: 0;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure,
main {
  /* 1 */
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: var(--black-color);
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  /* 2 */
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

* {
  outline: 0;
  -webkit-tap-highlight-color: transparent;
}

input:-webkit-autofill, input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.ta-r {
  text-align: right;
}

.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.m0 {
  margin: 0;
}

.m0-a {
  margin: 0 auto;
}

.ml-a {
  margin-left: auto;
}

.mr-a {
  margin-right: auto;
}

.m40 {
  margin: 40px;
}

.mt0 {
  margin-top: 0 !important;
}

.mt15 {
  margin-top: 15px;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr5 {
  margin-right: 5px;
}

.mr15 {
  margin-right: 15px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mr50 {
  margin-right: 50px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px;
}

.ml15 {
  margin-left: 15px;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.my5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my40 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.p0 {
  padding: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb10 {
  padding-bottom: 10px;
}

.pb20 {
  padding-bottom: 20px !important;
}

.p20 {
  padding: 20px;
}

.fl-column-ai-c {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.d-flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.fl-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fl-sb-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jcse, .fl-se-c {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.ai-c {
  display: flex;
  align-items: center;
}

.jc-c {
  display: flex;
  justify-content: center;
}

.jc-e {
  display: flex;
  justify-content: flex-end;
}

.jc-s {
  display: flex;
  justify-content: flex-start;
}

.fl-grow {
  flex-grow: 1;
}

.cl-white {
  color: var(--white-color);
}

.btn-icon {
  background: transparent;
  border: none;
}

.l0 {
  left: 0;
}

.t0 {
  top: 0;
}

.w50 {
  width: 50%;
}

.w100 {
  width: 100%;
}

.fw500 {
  font-weight: 500;
}

.break-word {
  word-break: break-word;
}

.pos-rel {
  position: relative;
}

.cur-p {
  cursor: pointer;
}

.ma {
  margin-left: auto;
  margin-right: auto;
}

.font-bold {
  font-weight: bold !important;
}

.m-20 {
  margin: -20px;
}

@media (max-width: 1024.9px) {
  .hidden-md {
    display: none !important;
  }
}

@media (max-width: 767.9px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (max-width: 575.9px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .display-sm {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .display-xs {
    display: none !important;
  }
}

img {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}
a:visited {
  color: inherit;
}

.h-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--blue-color);
}

* {
  outline: none;
  box-sizing: border-box;
}

html {
  overflow: hidden;
  height: 100vh;
}

body {
  font-family: var(--ff-base);
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  max-height: 100vh;
  height: 100%;
  background-color: #EBEFEE;
}

.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
}

.main {
  transition: 0.3s all ease-in-out;
  flex-grow: 1;
  width: calc(100% - var(--sidebar-hidden-width));
}
.main._setup {
  width: calc(100% - var(--sidebar-hidden-width-setup));
}
.main._active {
  transition: 0.3s all ease-in-out;
  width: calc(100% - var(--sidebar-width));
}

.nav {
  max-width: var(--sidebar-width);
  width: var(--sidebar-width);
  min-width: var(--sidebar-width);
  background: var(--sidebar-color);
  display: flex;
  flex-direction: column;
  padding: 40px 50px;
  font-size: 14px;
  line-height: 21px;
  color: var(--white-color);
  transition: 0.3s all ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 99;
  user-select: none;
}
.nav-profile {
  margin-bottom: 10px;
  margin-right: -15px;
  flex-shrink: 0;
}
@media (min-width: 1025px) {
  .nav-profile {
    display: none !important;
  }
}
.nav-logo {
  text-align: center;
  padding-bottom: 50px;
  cursor: pointer;
}
.nav-logo img {
  width: 84px;
}
.nav-link {
  flex-grow: 1;
}
.nav-link__item {
  display: flex;
  align-items: center;
  width: var(--sidebar-width);
  margin-left: -50px;
  padding: 16px 50px;
  cursor: pointer;
  position: relative;
  transition: 0.1s all ease-in-out;
}
@media (max-width: 767.9px) {
  .nav-link__item {
    width: calc(100% + 50px);
  }
}
.nav-link__item::before {
  content: "";
  border-left: 6px solid #6AC4B3;
  transform: scale(0);
  position: absolute;
  width: var(--sidebar-width);
  height: 100%;
  left: 0;
  top: 0;
  transition: 0.3s all ease-in-out;
  background: #5B92FE1A;
}
@media (max-width: 767.9px) {
  .nav-link__item::before {
    width: 100%;
  }
}
.nav-link__item:hover, .nav-link__item._active {
  transition: 0.1s all ease-in-out;
}
.nav-link__item:hover::before, .nav-link__item._active::before {
  transition: 0.3s all ease-in-out;
  transform: scale(1);
}
@media (min-width: 1025px) {
  .nav-link__item.mobile {
    display: none;
  }
}
.nav-link__item._new-ntf {
  padding-right: 35px;
}
.nav-link__item._new-ntf::after {
  content: "";
  position: absolute;
  right: 25px;
  width: 10px;
  height: 10px;
  background-color: #6AC4B3;
  border-radius: 50%;
}
.nav-icon {
  max-width: 24px;
  min-width: 24px;
  width: 24px;
  text-align: center;
  font-size: 22px;
  z-index: 1;
}
.nav-text {
  opacity: 1;
  transform: scale(1);
  word-break: break-word;
  margin-left: 15px;
  color: var(--white-color);
  font-family: var(--ff-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: 0.3s opacity ease-in-out;
  transition: 0.5s transform ease-in-out;
}
.nav-registration {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  margin: 30px -25px -10px;
  background: #5B92FE1A;
  border-radius: 10px;
  transition: 0.3s all ease-in-out;
}
.nav-registration__title {
  color: var(--white-color);
  font-family: var(--ff-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.nav-registration__progress {
  width: 100%;
}
.nav-registration__progress-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--ff-second);
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
}
.nav-registration__progress-steps {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 4px;
  margin-top: 8px;
}
.nav-registration__progress-steps__item {
  height: 6px;
  border-radius: 100px;
  background-color: #6AC4B3;
}
.nav-registration__progress-steps__item:not(._completed) {
  opacity: 0.3;
}
.nav-registration__btn {
  width: 100%;
  height: 42px !important;
  background-color: transparent !important;
  border-width: 2px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
@media (max-width: 1024.9px) {
  .nav {
    top: var(--topbar-height-mobile);
    height: calc(100% - var(--topbar-height-mobile));
    padding: 25px;
    transform: translateX(calc(0px - var(--sidebar-width)));
    position: fixed;
  }
  .nav-profile {
    order: -2;
  }
  .nav-logo {
    display: none !important;
  }
  .nav-link__item {
    margin-left: -25px;
    padding: 16px 25px;
  }
  .nav-registration {
    margin: 0 -10px 15px;
    order: -1;
  }
}

@media (min-width: 1025px) {
  .navHidden {
    max-width: var(--sidebar-hidden-width);
    width: var(--sidebar-hidden-width);
    min-width: var(--sidebar-hidden-width);
    padding: 26px;
  }
  .navHidden .nav-logo {
    margin: -12px -12px 0;
    padding-bottom: 26px;
  }
  .navHidden .nav-link__item {
    width: var(--sidebar-hidden-width);
    margin-left: -26px;
    padding: 16px 26px;
  }
  .navHidden .nav-text {
    word-break: keep-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    width: calc(100% + 36px);
    margin: 0;
    position: absolute;
    transform: scale(0);
    opacity: 0;
    transition: 0.3s opacity ease-in-out;
    transition: 0.5s transform ease-in-out;
  }
  .navHidden .nav-link__item::before {
    max-width: var(--sidebar-hidden-width);
    width: var(--sidebar-hidden-width);
    min-width: var(--sidebar-hidden-width);
  }
  .navHidden .nav-registration {
    margin-top: 0;
    margin-bottom: 100px;
    padding: 8px;
    border-radius: 0px;
  }
  .navHidden .nav-registration__progress {
    margin-top: -8px;
  }
  .navHidden .nav-registration__progress-text > *:first-of-type, .navHidden .nav-registration__progress-steps {
    display: none;
  }
  .navHidden .nav-registration__btn {
    min-width: 0 !important;
    height: 22px !important;
    padding: 3px 10px !important;
    border-width: 1px !important;
    border-radius: 4px !important;
    font-size: 12px !important;
    line-height: 14px !important;
  }
  .navHidden._setup {
    max-width: var(--sidebar-hidden-width-setup);
    width: var(--sidebar-hidden-width-setup);
    min-width: var(--sidebar-hidden-width-setup);
    padding: 24px;
  }
  .navHidden._setup .nav-logo {
    margin: 0;
    padding-bottom: 24px;
  }
  .navHidden._setup .nav-link__item {
    width: var(--sidebar-hidden-width-setup);
    margin-left: -24px;
    padding: 16px 24px;
  }
  .navHidden._setup .nav-link__item::before {
    max-width: var(--sidebar-hidden-width-setup);
    width: var(--sidebar-hidden-width-setup);
    min-width: var(--sidebar-hidden-width-setup);
  }
  .navHidden._setup .tooltip__nav {
    left: calc(var(--sidebar-hidden-width-setup) + 10px);
  }
}

@media (max-width: 1024.9px) {
  .sidebarOn {
    z-index: 120;
    max-width: var(--sidebar-width);
    width: var(--sidebar-width);
    min-width: var(--sidebar-width);
    transform: translateX(0);
    transition: 0.3s transform ease-in-out;
  }
  .sidebarOn .nav-text {
    opacity: 1;
    transform: scale(1);
  }
}
@media (max-width: 767.9px) {
  .sidebarOn {
    width: calc(100vw - 60px);
    min-width: 220px;
  }
}

.nav__fade {
  width: 100vw;
  height: calc(100% - var(--topbar-height-mobile));
  display: none;
  position: absolute;
  right: 0;
  top: var(--topbar-height-mobile);
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  transition: 0.3s all ease-in-out;
  z-index: 119;
}
@media (min-width: 1025px) {
  .nav__fade {
    display: none !important;
  }
}
.nav__fade._active {
  display: block;
}

.nav__toggle {
  position: absolute !important;
  left: calc(var(--sidebar-width) - 30px);
  bottom: 40px;
  width: 60px !important;
  min-width: 60px !important;
  height: 60px !important;
  padding: 0 !important;
  border-radius: 60px !important;
  background: rgba(54, 54, 54, 0.63) !important;
  backdrop-filter: blur(3px) !important;
  color: var(--white-color);
  line-height: 25px !important;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  z-index: 100;
  user-select: none;
}
@media (max-width: 1024.9px) {
  .nav__toggle {
    display: none !important;
  }
}
.nav__toggle .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav__toggle:hover .mat-button-focus-overlay {
  background: var(--white-color);
  opacity: 0.1;
}
.nav__toggle._hidden {
  left: calc(var(--sidebar-hidden-width) - 30px);
  transform: rotate(-180deg);
}
.nav__toggle._hidden._setup {
  left: calc(var(--sidebar-hidden-width-setup) - 30px);
}
.nav__toggle__icon {
  margin-right: 4px;
}

.pureplanner .waw-alert__close {
  height: 10px !important;
}
.pureplanner .waw-alert-texts {
  margin: 0 !important;
  font-family: var(--ff-base) !important;
}
.pureplanner .waw-alert-body {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.waw-btn, .waw-btn.mat-button, .waw-btn.mat-stroked-button, .waw-btn.mat-flat-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  height: 42px;
  padding: 10px 20px;
  background-color: #6AC4B3;
  border: none;
  border-radius: 10px;
  color: var(--white-color);
  font-family: var(--ff-base);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  flex-shrink: 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
@media (max-width: 1024.9px) {
  .waw-btn:not(._not_change_on_mobile), .waw-btn.mat-button:not(._not_change_on_mobile), .waw-btn.mat-stroked-button:not(._not_change_on_mobile), .waw-btn.mat-flat-button:not(._not_change_on_mobile) {
    font-size: 14px;
    line-height: normal;
  }
}
@media (max-width: 767.9px) {
  .waw-btn:not(._not_change_on_mobile), .waw-btn.mat-button:not(._not_change_on_mobile), .waw-btn.mat-stroked-button:not(._not_change_on_mobile), .waw-btn.mat-flat-button:not(._not_change_on_mobile) {
    height: 32px;
    min-width: 80px;
    padding: 8px 15px;
    border-radius: 6px;
    font-size: 12px;
    line-height: normal;
  }
}
.waw-btn._primary, .waw-btn.mat-button._primary, .waw-btn.mat-stroked-button._primary, .waw-btn.mat-flat-button._primary {
  background-color: #6AC4B3;
  box-shadow: 0px 4px 19px 0px rgba(37, 110, 255, 0.18);
}
.waw-btn._primary:hover, .waw-btn._primary:focus, .waw-btn.mat-button._primary:hover, .waw-btn.mat-button._primary:focus, .waw-btn.mat-stroked-button._primary:hover, .waw-btn.mat-stroked-button._primary:focus, .waw-btn.mat-flat-button._primary:hover, .waw-btn.mat-flat-button._primary:focus {
  background-color: #80cdbe;
}
.waw-btn._second, .waw-btn._second:disabled, .waw-btn.mat-button._second, .waw-btn.mat-button._second:disabled, .waw-btn.mat-stroked-button._second, .waw-btn.mat-stroked-button._second:disabled, .waw-btn.mat-flat-button._second, .waw-btn.mat-flat-button._second:disabled {
  border: 1px solid #6AC4B3;
  background-color: var(--white-color);
  color: #6AC4B3;
}
.waw-btn._second:disabled:hover, .waw-btn.mat-button._second:disabled:hover, .waw-btn.mat-stroked-button._second:disabled:hover, .waw-btn.mat-flat-button._second:disabled:hover {
  background-color: var(--white-color);
}
.waw-btn._danger, .waw-btn.mat-button._danger, .waw-btn.mat-stroked-button._danger, .waw-btn.mat-flat-button._danger {
  background-color: var(--white-color);
  color: #E47878;
}
.waw-btn._delete .mat-button-wrapper, .waw-btn.mat-button._delete .mat-button-wrapper, .waw-btn.mat-stroked-button._delete .mat-button-wrapper, .waw-btn.mat-flat-button._delete .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.waw-btn._delete .mat-button-wrapper::after, .waw-btn.mat-button._delete .mat-button-wrapper::after, .waw-btn.mat-stroked-button._delete .mat-button-wrapper::after, .waw-btn.mat-flat-button._delete .mat-button-wrapper::after {
  content: "\e931";
  margin-left: 3px;
  font-family: "icomoon" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 19px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 767.9px) {
  .waw-btn._delete .mat-button-wrapper::after, .waw-btn.mat-button._delete .mat-button-wrapper::after, .waw-btn.mat-stroked-button._delete .mat-button-wrapper::after, .waw-btn.mat-flat-button._delete .mat-button-wrapper::after {
    margin-left: 10px;
    font-size: 16px;
  }
}
.waw-btn._edit, .waw-btn.mat-button._edit, .waw-btn.mat-stroked-button._edit, .waw-btn.mat-flat-button._edit {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 32px;
}
.waw-btn._edit .mat-button-wrapper, .waw-btn.mat-button._edit .mat-button-wrapper, .waw-btn.mat-stroked-button._edit .mat-button-wrapper, .waw-btn.mat-flat-button._edit .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.waw-btn._edit .mat-button-wrapper::before, .waw-btn.mat-button._edit .mat-button-wrapper::before, .waw-btn.mat-stroked-button._edit .mat-button-wrapper::before, .waw-btn.mat-flat-button._edit .mat-button-wrapper::before {
  content: "\e918";
  font-family: "icomoon" !important;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: normal;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 767.9px) {
  .waw-btn._edit, .waw-btn.mat-button._edit, .waw-btn.mat-stroked-button._edit, .waw-btn.mat-flat-button._edit {
    width: 28px;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
  }
}
.waw-btn:disabled, .waw-btn.mat-button:disabled, .waw-btn.mat-stroked-button:disabled, .waw-btn.mat-flat-button:disabled {
  color: var(--white-color);
  background-color: #6AC4B3;
  cursor: not-allowed;
}
.waw-btn:disabled:hover, .waw-btn.mat-button:disabled:hover, .waw-btn.mat-stroked-button:disabled:hover, .waw-btn.mat-flat-button:disabled:hover {
  background-color: #6AC4B3;
  cursor: not-allowed;
}
.waw-btn .mat-button-wrapper, .waw-btn.mat-button .mat-button-wrapper, .waw-btn.mat-stroked-button .mat-button-wrapper, .waw-btn.mat-flat-button .mat-button-wrapper {
  display: flex;
  align-items: center;
}
.waw-btn .icon-plus, .waw-btn.mat-button .icon-plus, .waw-btn.mat-stroked-button .icon-plus, .waw-btn.mat-flat-button .icon-plus {
  margin-right: 14px;
}
@media (max-width: 767.9px) {
  .waw-btn .icon-plus, .waw-btn.mat-button .icon-plus, .waw-btn.mat-stroked-button .icon-plus, .waw-btn.mat-flat-button .icon-plus {
    margin-right: 12px;
  }
}

.mat-button.mat-primary:hover:not(.mat-button-disabled) .mat-button-focus-overlay, .mat-stroked-button.mat-primary:hover:not(.mat-button-disabled) .mat-button-focus-overlay, .mat-button.mat-warn:hover:not(.mat-button-disabled) .mat-button-focus-overlay, .mat-stroked-button.mat-warn:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
  opacity: 0.1 !important;
}

.mat-icon-button:hover .mat-button-focus-overlay {
  opacity: 0.04 !important;
}

.mat-primary.mat-icon-button:hover .mat-button-focus-overlay, .mat-warn.mat-icon-button:hover .mat-button-focus-overlay {
  opacity: 0.1 !important;
}

.waw-btn__icon {
  width: 42px !important;
  min-width: 42px !important;
  height: 42px !important;
  padding: 0 !important;
  border-radius: 10px !important;
  line-height: 42px !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
}
@media (max-width: 1024.9px) {
  .waw-btn__icon._tablet {
    width: 32px !important;
    min-width: 32px !important;
    height: 32px !important;
    line-height: 32px !important;
    border-radius: 50% !important;
    border: none !important;
  }
  .waw-btn__icon._tablet .icon-pencil::before {
    content: "\e949";
  }
}
@media (max-width: 767.9px) {
  .waw-btn__icon {
    width: 32px !important;
    min-width: 32px !important;
    height: 32px !important;
    line-height: 32px !important;
  }
  .waw-btn__icon:not(._mobile):not(._tablet) {
    border-radius: 8px !important;
  }
  .waw-btn__icon._mobile {
    border-radius: 50% !important;
    border: none !important;
  }
  .waw-btn__icon._mobile .icon-pencil::before {
    content: "\e949";
  }
  .waw-btn__icon .material-icons {
    font-size: 20px;
    line-height: 32px;
  }
}
.waw-btn__icon .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.waw-btn__icon.primary {
  border: 1px solid #6AC4B3;
}
.waw-btn__icon.primary [class^=icon-]:before, .waw-btn__icon.primary [class*=" icon-"]:before {
  color: #6AC4B3;
  font-size: 16px;
}
@media (max-width: 767.9px) {
  .waw-btn__icon.primary [class^=icon-]:before, .waw-btn__icon.primary [class*=" icon-"]:before {
    font-size: 15px;
  }
}
.waw-btn__icon.warn {
  border: 1px solid #E47878;
}
.waw-btn__icon.warn:disabled {
  border-color: rgba(0, 0, 0, 0.26);
}
.waw-btn__icon.warn [class^=icon-]:before, .waw-btn__icon.warn [class*=" icon-"]:before {
  color: #E47878;
  font-size: 16px;
}
@media (max-width: 767.9px) {
  .waw-btn__icon.warn [class^=icon-]:before, .waw-btn__icon.warn [class*=" icon-"]:before {
    font-size: 15px;
  }
}

.waw-btn__add {
  width: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
  min-height: 20px !important;
  line-height: 18px !important;
  border: 1px solid #6AC4B3 !important;
  border-radius: 6px !important;
  background: transparent !important;
  font-family: "icomoon" !important;
  font-size: 11px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.waw-btn__add::before {
  content: "\e930";
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  font-size: 10px;
  transform: translate(-50%, -50%);
}

.waw-btn__add-label {
  margin-left: 12px;
  color: #6AC4B3;
  font-family: var(--ff-second);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

@media (max-width: 767.9px) {
  .action-edit, .action-delete {
    width: 35px !important;
    height: 35px !important;
    line-height: 35px !important;
  }
}
.action-edit [class^=icon-]:before, .action [class*=" icon-"]:before, .action-delete [class^=icon-]:before, .action [class*=" icon-"]:before {
  font-size: 18px;
}
@media (max-width: 767.9px) {
  .action-edit [class^=icon-]:before, .action [class*=" icon-"]:before, .action-delete [class^=icon-]:before, .action [class*=" icon-"]:before {
    font-size: 15px;
  }
}
.action-edit [class^=icon-]:before, .action [class*=" icon-"]:before {
  color: #6AC4B3;
}
.action-delete [class^=icon-]:before, .action [class*=" icon-"]:before {
  color: #E47878;
}

.waw-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.waw-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.waw-checkbox input:disabled {
  cursor: default;
}
.waw-checkbox:hover input ~ .checkmark:before {
  opacity: 0.3;
}
.waw-checkbox:hover input:checked ~ .checkmark:before {
  background-color: #6AC4B3;
  opacity: 0.15;
}
.waw-checkbox:active input ~ .checkmark:before {
  opacity: 0.6;
}
.waw-checkbox:active input:checked ~ .checkmark:before {
  background-color: #6AC4B3;
  opacity: 0.3;
}
.waw-checkbox input:checked ~ .checkmark {
  background-color: #6AC4B3;
  border: none;
  transition: 0.2s ease-in-out;
}
.waw-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.waw-checkbox .checkmark {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  border-radius: 3px;
  background-color: transparent;
  border: 2px solid #E1E7F3;
  flex-shrink: 0;
  transition: 0.2s ease-in-out;
  transform-style: preserve-3d;
  cursor: pointer;
}
.waw-checkbox .checkmark:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translateZ(-1px);
  border-radius: 50%;
  background-color: #E1E7F3;
  opacity: 0;
  transition: 0.2s ease-in-out;
}
.waw-checkbox .checkmark:after {
  content: "\e92f";
  position: absolute;
  display: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--white-color);
  font-family: "icomoon" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.2s ease-in-out;
}
.waw-checkbox input:disabled ~ .checkmark {
  background-color: #C7CDD9;
  cursor: default;
}
input:disabled ~ .waw-checkbox__text {
  cursor: default;
}
.waw-checkbox:hover input:disabled ~ .checkmark:before {
  opacity: 0 !important;
}
.waw-checkbox:active input:disabled ~ .checkmark:before {
  opacity: 0 !important;
}
.waw-checkbox__text {
  margin-left: 10px;
  color: #666666;
  font-family: var(--ff-second);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}

.waw-input {
  position: relative;
}
.waw-input .req input {
  border-color: #E47878;
}
.waw-input .req input:hover, .waw-input .req input:focus {
  border-color: #E47878 !important;
}
.waw-input .req-text {
  margin-top: -4px;
  display: block;
  color: #E47878;
  font: normal 300 12px/15px var(--ff-second);
}
.waw-input._icon input {
  padding-left: 40px;
}
.waw-input._icon .material-icons {
  position: absolute;
  top: 50%;
  color: #ADB8C6;
  transform: translateY(-50%);
  left: 20px;
}
.waw-input__title {
  margin-bottom: 6px;
  display: block;
  color: #666666;
  font: normal 300 12px/18px var(--ff-second);
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
}
.waw-input input {
  width: 100%;
  height: 42px;
  min-height: 42px;
  max-height: 42px;
  padding: 11px 15px;
  margin-bottom: 10px;
  display: block;
  border-radius: 8px;
  border: 1px solid #F0F1F7;
  background-color: #FFFFFF;
  color: #343434;
  font: normal 400 14px/18px var(--ff-field);
  letter-spacing: 0.3px;
  transition: 0.2s all ease-in-out;
}
.waw-input input::placeholder {
  color: #ADB8C6;
  font: normal 400 14px/18px var(--ff-field);
  letter-spacing: 0.3px;
  transition: 0.2s all ease-in-out;
}
.waw-input input:hover:not(:disabled), .waw-input input:focus:not(:disabled) {
  border-color: #6AC4B3;
}
.waw-input input:disabled {
  cursor: not-allowed;
}
@media (max-width: 767.9px) {
  .waw-input:not(._not_change_on_mobile) input {
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    padding: 8px 10px;
    border-radius: 6px;
    font: normal 400 10px/14px var(--ff-field);
  }
  .waw-input:not(._not_change_on_mobile) input::placeholder {
    font: normal 400 10px/14px var(--ff-field);
  }
}

input[type=time] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

.waw-datepicker {
  position: relative;
}
.waw-datepicker__title {
  margin-bottom: 6px;
  display: block;
  color: #666666;
  font: normal 300 12px/18px var(--ff-second);
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
}
.waw-datepicker.req .req-text {
  margin-top: -4px;
  display: block;
  color: #E47878;
  font: normal 300 12px/15px var(--ff-second);
  border-color: #E47878;
}

.waw-radio {
  display: flex;
  align-items: center;
  font-size: 16px;
  height: auto;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.waw-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.waw-radio:hover input ~ .circle:before {
  opacity: 0.3;
}
.waw-radio:hover input:checked ~ .circle:before {
  background-color: #6AC4B3;
  opacity: 0.15;
}
.waw-radio:active input ~ .circle:before {
  opacity: 0.6;
}
.waw-radio:active input:checked ~ .circle:before {
  background-color: #6AC4B3;
  opacity: 0.3;
}
.waw-radio input:checked ~ .circle {
  background-color: #6AC4B3;
  border: 2px solid #6AC4B3;
  outline: 3.5px solid var(--white-color);
  outline-offset: -5px;
}
.waw-radio input:checked ~ .circle:after {
  display: block;
}
.waw-radio .circle {
  position: relative;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: transparent;
  border: 2px solid #E1E7F3;
  flex-shrink: 0;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.waw-radio .circle:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #E1E7F3;
  opacity: 0;
  transition: 0.2s ease-in-out;
}
.waw-radio__text {
  margin-left: 10px;
  color: #666666;
  font-family: var(--ff-second);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

body:not(._mobile-scrollbar) *::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #EBEFEE;
  display: none;
}
body:not(._mobile-scrollbar) *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}
body:not(._mobile-scrollbar) *::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #6AC4B3;
}
body:not(._mobile-scrollbar) *::-webkit-scrollbar-thumb:hover {
  background-color: #22AAA1;
}
body:not(._mobile-scrollbar) *::-webkit-scrollbar-corner {
  background: transparent;
}
body:not(._mobile-scrollbar) *.dark-scrollbar::-webkit-scrollbar-thumb {
  background-color: #444444;
}
body:not(._mobile-scrollbar) *.dark-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #666666;
}

@-moz-document url-prefix() {
  * {
    scrollbar-color: #22AAA1 transparent;
  }

  *.dark-scrollbar {
    scrollbar-color: #666666 transparent;
  }
}
.waw-select__title {
  display: block;
  margin-bottom: 6px;
  color: #666666;
  font: normal 300 12px/18px var(--ff-second);
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
}

.waw-select .req-text {
  margin-top: -4px;
  display: block;
  color: #E47878;
  font: normal 300 12px/15px var(--ff-second);
}

.waw-switch {
  display: flex;
  align-items: center;
}
.waw-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.waw-switch input:not([disabled]) + .track, .waw-switch input:not([disabled]) ~ .waw-switch-text {
  cursor: pointer;
}
.waw-switch input:checked + .track {
  border: 1px solid #6AC4B3;
}
.waw-switch input:checked + .track .handle {
  transform: translate(18px, -50%);
  background-color: #6AC4B3;
}
@media (max-width: 767.9px) {
  .waw-switch input:checked + .track .handle {
    transform: translate(16px, -50%);
  }
}
.waw-switch:hover input:not([disabled]) ~ .track .handle:before {
  opacity: 0.3;
}
.waw-switch:hover input:checked:not([disabled]) ~ .track .handle:before {
  background-color: #6AC4B3;
  opacity: 0.15;
}
.waw-switch:active input:not([disabled]) ~ .track .handle:before {
  opacity: 0.6;
}
.waw-switch:active input:checked:not([disabled]) ~ .track .handle:before {
  background-color: #6AC4B3;
  opacity: 0.3;
}
.waw-switch input:disabled + .track {
  border-color: #F0F1F7 !important;
  transition: 0s;
}
.waw-switch input:not([checked]):disabled + .track .handle {
  background-color: #F0F1F7 !important;
  transition: 0s;
}
.waw-switch input:checked:disabled + .track .handle {
  background-color: #D6D6DC !important;
  transition: 0s;
}
.waw-switch input:disabled ~ .waw-switch-text {
  color: #ADB8C6 !important;
}
.waw-switch .track {
  position: relative;
  width: 42px;
  height: 24px;
  border: 1px solid #F0F1F7;
  border-radius: 100px;
  background: #FCFDFE;
  outline: none;
  transition: 0.4s;
}
.waw-switch .track .handle {
  position: absolute;
  display: block;
  width: 18px;
  height: 18px;
  left: 2px;
  top: 50%;
  border: 1px solid #F0F1F7;
  border-radius: 50%;
  background-color: #ADB8C6;
  transform: translateY(-50%);
  box-sizing: border-box;
  transition: 0.4s;
  transform-style: preserve-3d;
}
.waw-switch .track .handle::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translateZ(-1px);
  border-radius: 50%;
  background-color: #E1E7F3;
  opacity: 0;
  transition: 0.2s ease-in-out;
}
@media (max-width: 767.9px) {
  .waw-switch .track {
    width: 34px;
    height: 18px;
  }
  .waw-switch .track .handle {
    width: 14px;
    height: 14px;
    left: 1px;
  }
  .waw-switch .track::before {
    width: 24px;
    height: 24px;
  }
}
.waw-switch-text {
  margin-left: 15px;
  color: #666666;
  font-family: var(--ff-second);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.waw-textarea {
  position: relative;
}
.waw-textarea__title {
  margin-bottom: 6px;
  display: block;
  color: #666666;
  font: normal 300 12px/18px var(--ff-second);
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
}
.waw-textarea textarea {
  display: block;
  width: 100%;
  min-height: 42px;
  max-height: 150px;
  resize: vertical;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #F0F1F7;
  background-color: #FFFFFF;
  color: #343434;
  font: normal 400 14px/18px var(--ff-field);
  letter-spacing: 0.3px;
  transition: 0.2s all ease-in-out;
}
@media (max-width: 767.9px) {
  .waw-textarea textarea {
    min-height: 32px;
    padding: 10px;
    border-radius: 6px;
    font: normal 400 10px/14px var(--ff-field);
  }
}
.waw-textarea textarea::placeholder {
  color: #ADB8C6;
  font: normal 400 14px/18px var(--ff-field);
  letter-spacing: 0.3px;
  transition: 0.2s all ease-in-out;
}
@media (max-width: 767.9px) {
  .waw-textarea textarea::placeholder {
    font: normal 400 10px/14px var(--ff-field);
  }
}
.waw-textarea textarea:hover:not(:disabled), .waw-textarea textarea:focus:not(:disabled) {
  border-color: #6AC4B3;
}
.waw-textarea textarea:disabled {
  cursor: not-allowed;
}
.waw-textarea .req textarea {
  border-color: #E47878;
}
.waw-textarea .req .NgxEditor__Wrapper {
  border-color: #E47878 !important;
}
.waw-textarea .req-text {
  margin-top: -4px;
  display: block;
  color: #E47878;
  font: normal 300 12px/15px var(--ff-second);
}

body.dialog-open {
  overflow: hidden;
}

.nsm-dialog-btn-close {
  border: 0;
  background: none;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.2em;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color 500ms;
  background-color: transparent;
  z-index: 999;
}
.overlay.nsm-overlay-open {
  background-color: rgba(0, 0, 0, 0.5);
}
.overlay.transparent {
  background-color: transparent;
}

.nsm-dialog {
  position: relative;
  opacity: 1;
  visibility: visible;
  min-height: 200px;
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  pointer-events: none;
  outline: none;
}
.nsm-dialog.nsm-dialog-close {
  opacity: 0;
}
.nsm-dialog.nsm-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 3.5rem);
}

.nsm-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 2px;
  padding: 1rem;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  margin-left: 1.75rem;
  margin-right: 1.75rem;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  outline: 0;
  transform: translate3d(0, 0, 0);
}

.nsm-body {
  position: relative;
  flex: 1 1 auto;
}

/* *************************
* Animations
* *************************/
.nsm-dialog[class*=nsm-dialog-animation-] {
  transition: transform 500ms ease-in-out, opacity 500ms;
}

.nsm-dialog-animation-ltr {
  transform: translate3d(-50%, 0, 0);
}
.nsm-dialog-animation-ltr.nsm-dialog-open {
  transform: translate3d(0, 0, 0);
}
.nsm-dialog-animation-ltr.nsm-dialog-close {
  transform: translate3d(-50%, 0, 0);
}

.nsm-dialog-animation-rtl {
  transform: translate3d(50%, 0, 0);
}
.nsm-dialog-animation-rtl.nsm-dialog-open {
  transform: translate3d(0, 0, 0);
}
.nsm-dialog-animation-rtl.nsm-dialog-close {
  transform: translate3d(50%, 0, 0);
}

.nsm-dialog-animation-ttb {
  transform: translate3d(0, -50%, 0);
}
.nsm-dialog-animation-ttb.nsm-dialog-open {
  transform: translate3d(0, 0, 0);
}
.nsm-dialog-animation-ttb.nsm-dialog-close {
  transform: translate3d(0, -50%, 0);
}

.nsm-dialog-animation-btt {
  transform: translate3d(0, 50%, 0);
}
.nsm-dialog-animation-btt.nsm-dialog-open {
  transform: translate3d(0, 0, 0);
}
.nsm-dialog-animation-btt.nsm-dialog-close {
  transform: translate3d(0, 50%, 0);
}

.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  color: #858585;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color 0.1s ease-out;
  margin: 0;
  box-shadow: none;
  background: none;
  border: none;
}

.emoji-mart-anchor:focus {
  outline: 0;
}

.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform;
  /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
  position: relative;
}

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 5px 25px 6px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  -webkit-appearance: none;
}

.emoji-mart-search-icon {
  position: absolute;
  top: 3px;
  right: 11px;
  z-index: 2;
  padding: 2px 5px 1px;
  border: none;
  background: none;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.95);
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.emoji-mart-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}

.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: 0.2em;
}

.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px;
  right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-skins.custom {
  right: 10px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}

.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: 0.5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999A9C;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0;
  border: none;
  background-color: #fff;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s;
}

.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: 0.03s;
}

.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: 0.06s;
}

.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: 0.09s;
}

.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: 0.12s;
}

.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: 0.15s;
}

.emoji-mart-skin-swatch.selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch.selected:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block;
  width: 0;
  height: 38px;
  overflow: hidden;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
  cursor: default;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative;
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: "";
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-text.opened {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #888;
  font-size: 11px;
  padding: 5px 2px;
  width: 95px;
  height: 40px;
  border-radius: 10%;
  background-color: #fff;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a;
}

.emoji-mart-skin-tone-2 {
  background-color: #fadcbc;
}

.emoji-mart-skin-tone-3 {
  background-color: #e0bb95;
}

.emoji-mart-skin-tone-4 {
  background-color: #bf8f68;
}

.emoji-mart-skin-tone-5 {
  background-color: #9b643d;
}

.emoji-mart-skin-tone-6 {
  background-color: #594539;
}

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */
.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Dark mode styles
 */
.emoji-mart-dark {
  color: #fff;
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-bar {
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff;
  border-color: #555453;
  background-color: #2f2f2f;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: #fff;
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #444;
}

.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #222;
  color: #fff;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  color: #bfbfbf;
}

.NgxEditor__Wrapper {
  margin-bottom: 10px;
  border-radius: 8px !important;
  border: 1px solid #F0F1F7 !important;
  background-color: #FFFFFF !important;
  color: #343434;
  transition: 0.2s all ease-in-out;
}
@media (max-width: 767.9px) {
  .NgxEditor__Wrapper {
    border-radius: 6px !important;
  }
}
.NgxEditor__Wrapper._hover, .NgxEditor__Wrapper._focus {
  border-color: #6AC4B3 !important;
}
.NgxEditor__Wrapper .NgxEditor__MenuBar {
  text-transform: lowercase;
  padding: 4px 4px 0px 2px;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  background-color: transparent;
  border-bottom: none !important;
}
@media (max-width: 767.9px) {
  .NgxEditor__Wrapper .NgxEditor__MenuBar {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__Seperator {
  border-color: transparent;
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem {
  margin-left: 2px;
  margin-bottom: 2px;
  border-radius: 4px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem {
    position: static;
  }
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem:hover {
  background: transparent !important;
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__MenuItem--IconContainer {
  border-radius: 4px;
  color: color-mix(in srgb, #ADB8C6 80%, white);
  transition: 0.1s color ease-in-out;
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__MenuItem--IconContainer:hover:not(.NgxEditor__MenuItem--Active) {
  background: color-mix(in srgb, #ADB8C6 20%, transparent);
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__MenuItem--IconContainer .material-icons {
  font-size: 20px;
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__MenuItem--IconContainer.NgxEditor__MenuItem--Active {
  color: #6AC4B3;
  background: color-mix(in srgb, #6AC4B3 20%, transparent);
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__Popup {
  min-width: 258px;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 0px 0px #A4A7AF1A, 0px 14px 32px 0px #A4A7AF1A, 0px 58px 58px 0px #A4A7AF17, 0px 130px 78px 0px #A4A7AF0D, 0px 230px 92px 0px #A4A7AF03, 0px 360px 101px 0px #A4A7AF00;
  background-color: white;
  z-index: 110;
  cursor: default;
}
@media (max-width: 1024px) {
  .NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__Popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__Popup .NgxEditor__ColorContainer {
  margin-top: 2px;
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__Popup .NgxEditor__ColorContainer:first-child {
  margin-top: 0;
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__Popup .NgxEditor__ColorContainer .NgxEditor__Color {
  padding: 0;
  margin-left: 6px;
  border-radius: 5px;
  cursor: pointer;
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__Popup .NgxEditor__ColorContainer .NgxEditor__Color:first-child {
  margin-left: 0;
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__Popup .NgxEditor__ColorContainer .NgxEditor__Color[title="#ffffff"] {
  border: 1px solid #d9d9d9;
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__Popup .NgxEditor__ColorContainer .NgxEditor__Color--Active:after {
  content: "\e876";
  font-family: "Material Icons";
  font-size: 18px;
  line-height: 24px;
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__Popup .NgxEditor__MenuItem--Button {
  height: 42px;
  padding: 10px 20px;
  margin-top: 10px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 10px;
  border: 1px solid #6AC4B3;
  background-color: var(--white-color);
  font-family: var(--ff-second);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  color: #6AC4B3;
  cursor: pointer;
}
@media (max-width: 767.9px) {
  .NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__Popup .NgxEditor__MenuItem--Button {
    padding: 8px 16px;
    height: 36px;
    font-size: 14px;
  }
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__Popup .NgxEditor__MenuItem--Button:hover {
  background-color: rgba(105, 195, 179, 0.1);
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__Popup .NgxEditor__MenuItem--Button:disabled {
  cursor: not-allowed;
}
.NgxEditor__Wrapper .NgxEditor__MenuBar .NgxEditor__MenuItem .NgxEditor__Popup .NgxEditor__MenuItem--Button:disabled:hover {
  background-color: var(--white-color);
}
.NgxEditor__Wrapper .NgxEditor {
  background-color: transparent;
}
.NgxEditor__Wrapper .NgxEditor .NgxEditor__Content {
  padding: 15px;
  color: #343434;
  font: normal 400 14px/18px var(--ff-field);
  letter-spacing: 0.3px;
  transition: 0.2s all ease-in-out;
}
@media (max-width: 767.9px) {
  .NgxEditor__Wrapper .NgxEditor .NgxEditor__Content {
    padding: 10px;
    font: normal 400 10px/14px var(--ff-field);
  }
}
.NgxEditor__Wrapper .NgxEditor .NgxEditor__Content p {
  color: #343434;
  margin: 0 0 2px;
}
.NgxEditor__Wrapper .NgxEditor .NgxEditor__Placeholder {
  color: #ADB8C6 !important;
  font: normal 400 14px/18px var(--ff-field);
  letter-spacing: 0.3px;
  transition: 0.2s all ease-in-out;
}
.NgxEditor__Wrapper .NgxEditor .NgxEditor__Placeholder:before {
  color: #ADB8C6 !important;
}
@media (max-width: 767.9px) {
  .NgxEditor__Wrapper .NgxEditor .NgxEditor__Placeholder {
    font: normal 400 10px/14px var(--ff-field);
  }
}

.emoji-mart {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 0px 0px #A4A7AF1A, 0px 14px 32px 0px #A4A7AF1A, 0px 58px 58px 0px #A4A7AF17, 0px 130px 78px 0px #A4A7AF0D, 0px 230px 92px 0px #A4A7AF03, 0px 360px 101px 0px #A4A7AF00;
  background-color: white;
}
.emoji-mart .emoji-mart-title-label {
  font-size: 16px;
}
.emoji-mart .emoji-mart-anchors {
  padding: 2.7px 6px;
}
.emoji-mart .emoji-mart-anchor {
  padding: 7.3px 4px;
  cursor: pointer;
}
.emoji-mart .emoji-mart-anchor:hover svg {
  fill: #6AC4B3;
}
.emoji-mart .emoji-mart-anchor.emoji-mart-anchor-selected svg {
  fill: #6AC4B3;
}
.emoji-mart .emoji-mart-anchor.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  background-color: #6AC4B3 !important;
}
.emoji-mart .emoji-mart-anchor svg {
  fill: #ADB8C6;
  transition: 0.3s ease-in-out;
}
@media (max-width: 575.9px) {
  .emoji-mart .emoji-mart-anchor svg {
    width: 18px;
    height: 18px;
  }
}
.emoji-mart .emoji-mart-anchor .emoji-mart-anchor-bar {
  background-color: transparent;
}
.emoji-mart .emoji-mart-category .emoji-mart-emoji span {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.emoji-mart .emoji-mart-scroll {
  height: 200px;
}
@media (max-width: 1024px) and (orientation: portrait) {
  .emoji-mart .emoji-mart-scroll {
    height: 270px;
  }
}

.picker-overlay-backdrop {
  background: transparent;
}
@media (max-width: 1024px) {
  .picker-overlay-backdrop {
    background: #0000007A;
  }
}
@media (max-width: 1024px) {
  .picker-overlay-backdrop ~ .cdk-overlay-connected-position-bounding-box {
    justify-content: center !important;
    align-items: center !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    width: 100% !important;
    height: 100% !important;
  }
  .picker-overlay-backdrop ~ .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    min-width: 0px !important;
    position: static !important;
    min-height: 0 !important;
  }
}

.auth {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.auth-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 1074px;
  height: auto;
  min-height: 735px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.14);
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.auth-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 70px 80px;
  background-color: #FFF;
  -webkit-transition: 0.3s all ease-in-out;
  overflow: hidden;
  transition: 0.3s all ease-in-out;
}
.auth-right {
  position: relative;
  height: 100%;
  min-height: 700px;
  background-color: var(--sidebar-color);
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  pointer-events: none;
}
.auth__title {
  margin: 0 0 40px;
  color: #343434;
  font: normal 600 34px/54px var(--ff-second);
}
.auth__text {
  margin: 0;
  color: #343434;
  font: normal 500 16px/normal var(--ff-second);
}
.auth__text strong {
  font-weight: 600;
}
.auth__btn, .auth__btn.waw-btn._second {
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #F0F1F7;
  color: #343434;
  font: normal 500 16px/19px var(--ff-base);
}
.auth__btn:hover, .auth__btn:focus, .auth__btn.waw-btn._second:hover, .auth__btn.waw-btn._second:focus {
  border-color: #6AC4B3;
}
.auth__btn img, .auth__btn.waw-btn._second img {
  margin-right: 10px;
}
.auth__separator {
  position: relative;
  width: 100%;
  margin: 10px 0 30px;
}
.auth__separator hr {
  margin: 7px 0;
  border: 0;
  border-top: 1px solid #F0F1F7;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.auth__separator span {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #FFF;
  padding: 0 10px;
  color: #ADB8C6;
  text-align: center;
  font-size: 12px;
  translate: -50% -50%;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.auth .waw-input {
  --btn-width: 40px;
  --btn-size: 20px;
  position: relative;
  margin-bottom: 10px;
}
.auth .waw-input__title {
  color: #343434;
  font-weight: 500;
}
.auth .waw-input .req-text {
  margin-top: 0px;
  margin-bottom: 6px;
  font-weight: 500;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
}
.auth .waw-input .visibility {
  position: absolute;
  right: calc(15px - (var(--btn-width) - var(--btn-size)) / 2 + 1px);
  bottom: calc(10px + (42px - var(--btn-width)) / 2);
  width: var(--btn-width);
  height: var(--btn-width);
  background-color: #FFFFFF;
  line-height: var(--btn-width);
  color: #343434;
  font-size: var(--btn-size);
  transition: all 0.15s ease-in-out;
  z-index: 10;
}
.auth .waw-input .visibility:not(._show) {
  visibility: hidden;
  opacity: 0;
}
.auth .waw-input .visibility [class^=icon-], .auth .waw-input .visibility [class*=" icon-"] {
  display: block;
}
.auth .waw-input:hover .visibility {
  visibility: visible;
  opacity: 1;
}
.auth .waw-input .check input, .auth .waw-input .req input {
  padding-right: 35px;
}
.auth .waw-input .check::after, .auth .waw-input .req::after {
  position: absolute;
  right: 15px;
  bottom: 31px;
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  translate: 0 50%;
  user-select: none;
  pointer-events: none;
  z-index: 5;
}
.auth .waw-input .check::after {
  content: "\e86c";
  color: #6AC4B3;
  font-size: 14px;
}
.auth .waw-input .req::after {
  content: "\e000";
  color: #E47878;
  font-size: 20px;
}
.auth .waw-input input[name=password] {
  padding-right: calc(15px - (var(--btn-width) - var(--btn-size)) / 2 + var(--btn-width));
}
.auth__note {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font: normal 500 14px/20px var(--ff-second);
}
.auth__note-text {
  color: #343434;
}
.auth__note-link {
  color: #6AC4B3;
  cursor: pointer;
  transition: 0.07s all ease-in-out;
}
.auth__note-link:visited {
  color: #6AC4B3;
}
.auth__note-link:active, .auth__note-link:hover, .auth__note-link:focus {
  filter: brightness(0.9);
}
.auth__note-text + .auth__note-link {
  flex-grow: 1;
  margin-left: 20px;
}
.waw-input + .auth__note {
  margin-top: -4px;
}
.auth button[type=submit] {
  margin-top: 40px;
  width: 100%;
}
.auth__logo {
  position: relative;
  top: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth__logo-text {
  margin-left: 35px;
  color: #FFF;
  font: normal 500 24px Roboto, sans-serif;
}
.auth__preview-1, .auth__preview-2, .auth__preview-3 {
  border-radius: 6px;
  border: 2px solid #FFFFFF;
  background-color: #FFFFFF;
  box-shadow: -6px 9px 17px 0px #1D24473B;
  overflow: hidden;
}
.auth__preview-1 {
  position: absolute;
  right: 44px;
  top: 240px;
  z-index: 2;
}
.auth__preview-2 {
  position: absolute;
  left: 44px;
  top: 345px;
  z-index: 1;
}
.auth__preview-3 {
  position: absolute;
  top: 458px;
  right: 68px;
  z-index: 3;
}
.auth .password-verification {
  display: none;
  margin-bottom: 20px;
}
.auth .password-verification__item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #E47878;
  transition: 0.2s all ease-in-out;
}
.auth .password-verification__item.check {
  color: #6AC4B3;
}
.auth .password-verification__item .material-icons {
  margin-right: 5px;
  font-size: 20px;
}
.auth .password-verification__item span {
  text-transform: lowercase;
  margin-bottom: 0;
}
@media (max-width: 1024.9px) {
  .auth-container {
    grid-template-columns: 1fr;
    max-width: 537px;
    min-height: 0;
  }
  .auth-left {
    min-height: 500px;
  }
  .auth-right {
    display: none;
  }
}
@media (max-width: 767.9px) {
  .auth {
    padding-top: 90px;
    padding-bottom: 40px;
  }
  .auth-container {
    max-width: none;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }
  .auth-left {
    justify-content: space-between;
    min-height: 0;
    padding: 0;
    background-color: transparent;
  }
  .auth__separator span {
    background-color: #EBEFEE;
  }
  .auth__note-text + .auth__note-link {
    flex-grow: 0;
    margin-left: 0;
  }
}

picture {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
}
picture._fz-46 .letter {
  font-size: 46px;
}

.h-inp-def {
  height: 40.4px;
}

.containerBox {
  padding: 20px 40px;
  overflow: auto;
  height: calc(100% - var(--topbar-height));
  scrollbar-gutter: stable;
}
@media (max-width: 1024.9px) {
  .containerBox {
    height: calc(100% - var(--topbar-height-mobile));
    padding: 30px 20px;
  }
}
@media (max-width: 767.9px) {
  .containerBox {
    padding: 20px;
  }
}

.page-nav {
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-nav__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #68C7B3;
  color: #fff;
  font-family: var(--ff-base);
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.1s all ease-in-out;
  padding: 0;
  text-align: center;
}
.page-nav__btn:hover {
  background: #44b89f;
  transition: 0.1s all ease-in-out;
}
.page-nav__search {
  padding: 10px;
  background: var(--white-color);
  border-radius: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  min-width: 36px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
  position: relative;
}
.page-nav__search .icon-search {
  position: absolute;
  left: 12px;
}
.page-nav__search input {
  width: 80%;
  right: 12px;
  position: absolute;
  font-size: 14px;
  padding: 5px 10px;
  display: block;
  border: 1px solid var(--grey-color);
  border-radius: 15px;
  color: var(--blue-color);
  letter-spacing: 0.2px;
  font-size: 16px;
  background-color: var(--white-color);
  transition: 0.3s all ease-in-out;
  transform: scale(0);
}
.page-nav__search._active {
  width: 235px;
}
.page-nav__search._active input {
  transition: 0.3s all ease-in-out;
  transform: scale(1);
}

.icon {
  cursor: pointer;
  color: var(--grey-color);
}
.icon::before {
  color: var(--grey-color);
}

.tab {
  width: 100%;
  border-bottom: 0.7px solid var(--grey-color);
  display: flex;
}
.tab__item--icon {
  display: none;
  padding: 12px 20px;
  font-weight: normal;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #A5A5A5;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  line-height: 21px;
}
.tab__item--icon::before {
  content: "";
  position: absolute;
  bottom: -3px;
  width: 100%;
  height: 3px;
  left: 0;
  transform: scale(0);
  background-color: #68C7B3;
  transition: 0.3s all ease-in-out;
}
.tab__item--icon._active {
  color: #68C7B3;
}
.tab__item--icon._active::before {
  transition: 0.3s all ease-in-out;
  transform: scale(1);
}
@media (max-width: 1024.9px) {
  .tab__item {
    display: none;
  }
  .tab__item--icon {
    display: block;
  }
}
.tab__item {
  padding: 5px 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #A5A5A5;
  position: relative;
  cursor: pointer;
  text-align: center;
}
.tab__item::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  left: 0;
  transform: scale(0);
  background-color: #68C7B3;
  transition: 0.3s all ease-in-out;
}
.tab__item._active {
  color: #68C7B3;
}
.tab__item._active::before {
  transition: 0.3s all ease-in-out;
  transform: scale(1);
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: var(--blue-color);
  color: var(--white-color);
  text-align: center;
  border-radius: 3px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--blue-color) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.t-hide {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  word-break: keep-all;
}

.text-elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
}

.ovf-hidden {
  overflow: hidden;
}

.text-muted {
  color: var(--grey-color);
}

.nav-filter {
  display: none;
}
.nav-filter .page-nav__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
}
.nav-filter .page-nav__btn .material-icons {
  font-size: 20px;
}
.nav-filter .icon-filter {
  font-size: 24px;
}

.flex-wrap {
  flex-wrap: wrap;
}

@media (max-width: 1250px) {
  .hidden-mob {
    display: none;
  }
}

@media (max-width: 1250px) {
  .dis-mob {
    display: flex;
  }
}

.filter-mobile {
  position: relative;
}
.filter-mobile-overlay {
  width: 250px;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 70;
  padding: 30px 26px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.filter-mobile-overlay.right {
  left: auto;
  right: 0;
}
.filter-mobile-overlay .col-1 {
  padding: 0 0 10px;
}
.filter-mobile-overlay .col-1:last-child {
  padding-bottom: 0;
}
@media (max-width: 400px) {
  .filter-mobile-overlay {
    width: 200px;
  }
}

.image-cropper {
  border-radius: 15px;
  margin-bottom: 10px;
}

.events-none {
  pointer-events: none;
}

.search-input {
  position: relative;
  max-width: 300px;
  width: 100%;
  height: 42px;
}
.search-input::after {
  content: "\e914";
  position: absolute;
  right: 23px;
  top: 50%;
  color: #999999;
  transform: translateY(-50%);
  font-family: "icomoon" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.search-input input {
  display: block;
  width: 100%;
  height: 42px;
  min-height: 42px;
  max-height: 42px;
  padding: 13px 50px 13px 30px;
  margin: 0;
  border-radius: 10px;
  background-color: var(--white-color);
  border: 1px solid transparent;
  color: #444444;
  font-family: var(--ff-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  transition: 0.2s all ease-in-out;
}
.search-input input:hover, .search-input input:focus {
  border-color: #6AC4B3;
}
.search-input input::placeholder {
  color: #999;
  font-family: var(--ff-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
}
@media (max-width: 1024.9px) {
  .search-input::after {
    right: 20px;
  }
  .search-input input {
    padding: 13px 45px 13px 20px;
  }
}
@media (max-width: 767.9px) {
  .search-input::after {
    right: 16px;
  }
  .search-input input {
    padding: 13px 41px 13px 16px;
    border-radius: 8px;
  }
}

.setup-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  height: auto;
  overflow: auto;
  transition: 0.3s all ease-in-out;
}
@media (max-width: 1439.9px) {
  .setup-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 1024.9px) {
  .setup-container {
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }
}
@media (max-width: 767.9px) {
  .setup-container {
    grid-template-columns: 1fr;
  }
}

.setup-box {
  background: var(--white-color);
  padding: 40px 50px;
  box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.14);
  border-radius: 10px;
  height: 100%;
  font-family: var(--ff-third);
  overflow: hidden;
  transition: 0.3s all ease-in-out;
}
@media (max-width: 1024.9px) {
  .setup-box {
    padding: 30px 20px;
  }
}
@media (max-width: 767.9px) {
  .setup-box {
    padding: 16px 10px;
  }
}

.setup-item {
  position: relative;
  font-family: var(--ff-third);
}
.setup-item__title {
  margin-right: 70px;
  color: #444444;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: keep-all;
}
@media (max-width: 1024.9px) {
  .setup-item__title {
    font-size: 16px;
  }
}
@media (max-width: 767.9px) {
  .setup-item__title {
    font-size: 14px;
  }
}
.setup-item__actions {
  position: absolute;
  top: 30px;
  right: 40px;
}
@media (max-width: 1024.9px) {
  .setup-item__actions {
    top: 20px;
    right: 10px;
  }
}
@media (max-width: 767.9px) {
  .setup-item__actions {
    top: 6px;
    right: 5px;
  }
}
.setup-item__description {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #7B808B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 1024.9px) {
  .setup-item__description {
    font-size: 12px;
  }
}
.setup-item__description ::ng-deep p {
  margin: 0 !important;
}
.setup-item__property {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}
.setup-item__property__name {
  margin-right: 10px;
  color: #444444;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.setup-item__property__value {
  color: #7B808B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: end;
}
.setup-item__property ::ng-deep p {
  margin: 0 !important;
}
@media (max-width: 1024.9px) {
  .setup-item__property__name, .setup-item__property__value {
    font-size: 12px;
    line-height: 14px;
  }
}
.setup-item hr {
  border: 0;
  border-top: 1px solid #E4E8F1;
  margin: 20px 0;
}

.setup-add {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  border: 1px dashed #BDC7DC;
  border-spacing: 20px;
  box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.14);
}
@media (max-width: 767.9px) {
  .setup-add {
    min-height: 130px !important;
  }
}

.setup-empty {
  width: 100%;
  height: auto;
  min-height: 100%;
  text-align: center;
}
.setup-empty__img {
  width: 100%;
  max-width: 525px;
  max-height: 350px;
  margin-left: auto;
  margin-right: auto;
}
.setup-empty__img img {
  max-height: inherit;
}
.setup-empty__title {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 40px;
  color: #444444;
  font-family: var(--ff-third);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.setup-empty__title a {
  color: #6AC4B3;
  cursor: pointer;
  transition: 0.07s all ease-in-out;
}
.setup-empty__title a:visited {
  color: #6AC4B3;
}
.setup-empty__title a:active, .setup-empty__title a:hover, .setup-empty__title a:focus {
  filter: brightness(0.9);
}
.setup-empty__btn {
  display: flex;
  justify-content: center;
}
@media (max-width: 1439.9px) {
  .setup-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 1439.9px) and (orientation: landscape) {
  .setup-empty__img {
    max-width: 425px;
    max-height: 280px;
  }
}
@media (max-width: 1439.9px) {
  .setup-empty__title {
    max-width: 650px;
  }
}
@media (max-width: 1439.9px) and (orientation: portrait) {
  .setup-empty__title {
    margin-top: 80px;
  }
}
@media (max-width: 767.9px) and (orientation: landscape) {
  .setup-empty__img {
    max-width: 325px;
    min-height: 216px;
  }
  .setup-empty__title {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767.9px) {
  .setup-empty__title {
    font-size: 16px;
  }
}
@media (max-width: 767.9px) and (orientation: portrait) {
  .setup-empty__title {
    margin-bottom: 30px;
  }
}

.cl-service-nav {
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hours {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.hours__day {
  display: flex;
  flex-direction: row;
}
.hours__day:not(._empty) {
  margin-top: 24px;
}
.hours__day__name {
  width: 150px;
  font-family: var(--ff-second);
  font-size: 16px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0px;
  color: #666666;
}
.hours__day__body {
  display: flex;
  flex-direction: column;
  gap: 44px;
  flex: 1;
}
.hours__day__body__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
}
.hours__day__body__item-time {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: -20px;
  margin-bottom: -10px;
}
.hours__day__body__item-time timepicker {
  flex: 1;
}
.hours__day__body__item-time timepicker .timepicker__label {
  margin-bottom: 2px;
}
.hours__day__body__item-time timepicker .timepicker__container {
  min-width: 115px;
  padding-right: 27px;
}
.hours__day__body__item-time timepicker .timepicker__container:hover, .hours__day__body__item-time timepicker .timepicker__container.focus {
  z-index: 2;
}
.hours__day__body__item-time timepicker .timepicker__container input {
  width: calc(100% - 42px + 15px - 15px);
}
.hours__day__body__item-time timepicker .timepicker-wrapp.req .timepicker__container {
  z-index: 4;
}
.hours__day__body__item-time timepicker:first-of-type .timepicker__container {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.hours__day__body__item-time timepicker:first-of-type .timepicker__container .btn {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.hours__day__body__item-time timepicker:last-of-type {
  margin-left: -1px;
}
.hours__day__body__item-time timepicker:last-of-type .timepicker__container {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.hours__day__body__item-actions {
  display: flex;
  flex-direction: row;
  gap: 15px;
  min-width: 99px;
}
@media (max-width: 767.9px) {
  .hours {
    gap: 20px;
  }
  .hours__day {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
  .hours__day:not(._empty) {
    gap: 8px;
    margin-top: 0px;
  }
  .hours__day__name {
    width: auto;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
  }
  .hours__day__body {
    gap: 15px;
  }
  .hours__day__body__item {
    gap: 10px;
  }
  .hours__day__body__item-time {
    margin-top: 0px;
  }
  .hours__day__body__item-time timepicker .timepicker__container {
    min-width: 0;
    padding-right: 20px;
  }
  .hours__day__body__item-time timepicker .timepicker__container input {
    width: calc(100% - 20px - 10px);
  }
  .hours__day__body__item-actions {
    align-items: flex-start;
    gap: 10px;
    min-width: 76px;
    margin-top: 20px;
  }
}

.topbar {
  position: sticky;
  display: flex;
  top: 0;
  right: 0;
  width: calc(100vw - var(--sidebar-hidden-width));
  height: calc(var(--topbar-height));
  background: var(--white-color);
  box-shadow: 0px 1px 0px 0px #E3E4E8;
  user-select: none;
  z-index: 130;
}
.topbar._setup {
  width: calc(100vw - var(--sidebar-hidden-width-setup));
}
.topbar.navShown {
  width: calc(100vw - var(--sidebar-width));
}
@media (max-width: 1024.9px) {
  .topbar {
    width: 100vw !important;
    height: var(--topbar-height-mobile);
    background: var(--sidebar-color);
    box-shadow: none;
  }
  .topbar-profile {
    display: none !important;
  }
}
.topbar-left {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.topbar-left__back {
  display: flex;
  justify-content: center;
  align-items: center;
}
.topbar-left__back-btn {
  width: 46px !important;
  height: 46px !important;
  line-height: 46px !important;
}
.topbar-left__back-btn .mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1025px) {
  .topbar-left__back {
    flex-basis: 60px;
    width: 60px;
    height: 100%;
    border-right: 1px solid #E3E4E8 !important;
  }
  .topbar-left__back-btn {
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
  }
}
@media (max-width: 1024.9px) {
  .topbar-left__back {
    flex-basis: 46px;
    margin-left: 15px;
  }
  .topbar-left__back-btn {
    font-size: 20px;
  }
}
.topbar-left__menu {
  position: relative;
  flex-basis: 46px;
  width: 46px !important;
  height: 46px !important;
  margin-left: 15px !important;
  line-height: 46px !important;
}
.topbar-left__menu .mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.topbar-left__menu._ntf::after, .topbar-left__menu._timer::after {
  content: "";
  position: absolute;
  right: 5px;
  top: 11px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 1;
  transition: 0.2s all ease-in-out;
}
.topbar-left__menu._ntf._close::after, .topbar-left__menu._timer._close::after {
  opacity: 0;
}
.topbar-left__menu._ntf:not(._timer)::after {
  background-color: #6AC4B3;
}
.topbar-left__menu._timer::after {
  background-color: #E47878;
}
@media (min-width: 1025px) {
  .topbar-left__menu {
    display: none !important;
  }
}
.topbar-left__page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 30px;
  font-family: var(--ff-third);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  overflow: hidden;
}
@media (max-width: 1024.9px) {
  .topbar-left__page {
    margin: 10px;
    margin-right: 20px;
  }
}
@media (max-width: 767.9px) {
  .topbar-left__page {
    margin-right: 0;
  }
}
.topbar-left__page__title {
  color: #444444;
  font-size: 20px;
  line-height: 23px;
  max-height: 46px;
}
@media (max-width: 1024.9px) {
  .topbar-left__page__title {
    color: var(--white-color);
  }
}
.topbar-left__page__subtitle {
  color: #C8CDD9;
  font-size: 12px;
}
.topbar-right {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  padding-right: 25px;
}
.topbar-logo {
  width: 32px;
  height: 32px;
  margin-left: 15px;
  flex-shrink: 0;
}
@media (min-width: 1025px) {
  .topbar-logo {
    display: none !important;
  }
}
.topbar-profile {
  height: 100%;
  cursor: pointer;
}
.topbar-profile__info {
  max-width: 200px;
}
@media (max-width: 1439.9px) {
  .topbar-profile__info {
    max-width: 90px;
  }
}

.topbar-user-btn {
  height: 42px !important;
  padding: 10px 20px !important;
  border-width: 2px !important;
  background-color: transparent !important;
  border-radius: 10px !important;
  font-size: 16px !important;
}
.topbar-user-btn .icon-plus {
  margin-right: 10px;
}

.topbar-btn {
  width: 42px !important;
  height: 42px !important;
  margin-left: 3px !important;
  line-height: 42px !important;
}
.topbar-btn__icon {
  color: #D4D4D4;
}
@media (max-width: 1024.9px) {
  .topbar-btn__icon {
    color: var(--white-color);
  }
}
.topbar-btn._primary .topbar-btn__icon {
  color: #6AC4B3;
}
.topbar-btn._warn .topbar-btn__icon {
  color: #E47878;
}

.popup {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 0px 0px #A4A7AF1A, 0px 14px 32px 0px #A4A7AF1A, 0px 58px 58px 0px #A4A7AF17, 0px 130px 78px 0px #A4A7AF0D, 0px 230px 92px 0px #A4A7AF03, 0px 360px 101px 0px #A4A7AF00;
  background-color: white;
}
@media (min-width: 1025px) {
  .popup > * {
    animation: 0.15s opacityIn;
  }
  @keyframes opacityIn {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.user-popup {
  width: 270px;
  padding-top: 15px;
  margin-top: 26px;
}

.profile-popup {
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-top: 10px;
  overflow: hidden;
}
.profile-popup__item {
  height: 42px !important;
  border-radius: 0 !important;
  color: #343434 !important;
  font-family: var(--ff-base);
  font-size: 16px;
  font-weight: 400;
  line-height: 42px !important;
}
.profile-popup__item._warn {
  color: #E47878 !important;
}

.ntf-popup {
  margin-top: calc((var(--topbar-height) - 42px) / 2 + 10px);
  min-width: 200px;
}
.ntf-popup__head {
  padding: 10px 10px 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767.9px) {
  .ntf-popup__head {
    padding: 5px 5px 5px 15px;
  }
}
.ntf-popup__head__title {
  color: #444444;
  font-family: var(--ff-third);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 767.9px) {
  .ntf-popup__head__title {
    font-size: 16px;
  }
}
.ntf-popup__head__stgs-icon, .ntf-popup__head__stgs-icon::before {
  width: 20px;
  height: 20px;
  color: #D4D4D4 !important;
  font-size: 20px;
}
.ntf-popup__body {
  padding: 0px 25px 10px;
  overflow-y: auto;
  max-height: calc(100vh - var(--topbar-height) * 2 - 62px);
}
@media (max-width: 767.9px) {
  .ntf-popup__body {
    padding: 0px 15px 5px;
  }
}
.ntf-popup__item {
  border-bottom: 1px solid #EBEFEE;
  transition: opacity 0.2s, transform 0.2s;
}
@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.ntf-popup__item:last-child {
  border-bottom: none;
}
.ntf-popup._active {
  transform: translateY(0);
  transition: 0.5s all ease-in-out;
}
@media (max-width: 575.9px) {
  .ntf-popup .ntf {
    width: 220px !important;
  }
}
.ntf-popup .empty-list {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 15px 25px 25px;
  text-align: center;
  user-select: none;
}
@media (max-width: 767.9px) {
  .ntf-popup .empty-list {
    padding: 15px;
  }
}
@media (max-width: 575.9px) {
  .ntf-popup .empty-list {
    width: 220px;
  }
}
.ntf-popup .empty-list__icon {
  margin-bottom: 10px;
  color: #EBEFEE;
  font-size: 40px;
}
.ntf-popup .empty-list__text {
  color: #A4A6AA;
  font-family: var(--ff-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 767.9px) {
  .ntf-popup .empty-list__text {
    font-size: 12px;
  }
}

.topbar-profile, .nav-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.topbar-profile__img, .nav-profile__img {
  flex-shrink: 0;
  position: relative;
  width: 52px;
  height: 52px;
  border: 1px solid #F4F4F4;
  box-sizing: border-box;
  border-radius: 50%;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topbar-profile__img::after, .nav-profile__img::after {
  content: "";
  position: absolute;
  top: 3px;
  right: 2px;
  width: 8px;
  height: 8px;
  background-color: #6AC4B3;
  border-radius: 50%;
}
.topbar-profile__img picture, .nav-profile__img picture {
  display: block;
  width: 40px !important;
  height: 40px !important;
  min-height: 40px !important;
  min-width: 40px !important;
  max-height: 40px !important;
  max-width: 40px !important;
}
.topbar-profile__img img, .nav-profile__img img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #000;
}
.topbar-profile__info, .nav-profile__info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 26px;
  overflow: hidden;
}
@media (max-width: 575.9px) {
  .topbar-profile__info, .nav-profile__info {
    margin-left: 15px;
  }
}
.topbar-profile__info-name, .nav-profile__info-name {
  color: #343434;
  font-family: var(--ff-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 1024.9px) {
  .topbar-profile__info-name, .nav-profile__info-name {
    color: var(--white-color);
  }
}
.topbar-profile__info-role, .nav-profile__info-role {
  color: #A4A6AA;
  font-family: var(--ff-base);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.topbar-profile__btn, .nav-profile__btn {
  width: 42px !important;
  height: 42px !important;
  line-height: 42px !important;
  font-size: 12px !important;
  margin-left: 3px !important;
}

.topbar-profile {
  margin-left: 10px;
}

.topbar-date_and_time {
  transition: all 0.15s ease-in-out 0s;
}
.topbar-date_and_time::after, .topbar-date_and_time .topbar-date {
  margin-right: 16px;
  font-family: var(--ff-base);
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  white-space: nowrap;
  color: #BDBDBD;
}
.topbar-date_and_time .topbar-time {
  margin-right: 10px;
  font-family: var(--ff-base);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  color: #999999;
}
@media (max-width: 1439.9px) {
  .topbar-date_and_time._hide {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .topbar-date_and_time._hide::after {
    margin-right: 10px;
    content: attr(data-date);
  }
  .topbar-date_and_time._hide .topbar-date {
    display: none;
  }
}
@media screen and (max-width: 1170px) {
  .topbar-date_and_time {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  .topbar-date_and_time .topbar-date {
    margin-right: 10px;
  }
  .topbar-date_and_time._hide {
    display: none;
  }
}

.modal.pureplanner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 70px 150px;
  background-color: #0000007A !important;
}
@media (max-width: 1024.9px) {
  .modal.pureplanner {
    padding: 20px 30px;
  }
}
@media (max-width: 767.9px) {
  .modal.pureplanner {
    padding: 10px 20px;
  }
}

.modalOpened .pureplanner > .modal-content {
  width: 100%;
  max-width: 570px !important;
  padding: 0px;
  margin: 0 auto !important;
  border-radius: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0);
}
.modalOpened .pureplanner > .modal-content > div {
  width: 100%;
}
.modalOpened .pureplanner > .modal-content .close {
  display: none;
  top: 10px;
  right: 45px;
  content: "\e5cd";
  font-family: "Material Icons";
  color: #fff;
}
@media (max-width: 1024.9px) {
  .modalOpened .pureplanner > .modal-content .close {
    top: 25px;
    right: 25px;
  }
}

.modalOpened .pureplanner.big > .modal-content {
  max-width: 725px !important;
}

.modalOpened .pureplanner.small > .modal-content {
  max-width: 378px !important;
}

.modalOpened .pureplanner.importexport > .modal-content {
  max-width: 760px !important;
}
@media (max-width: 1024.9px) {
  .modalOpened .pureplanner.importexport > .modal-content {
    max-width: 435px !important;
  }
}
.pureplanner:not(.foreground) {
  z-index: 999 !important;
}

.newAppoint-modal .nsm-dialog {
  max-width: 100%;
}

.nsm-body {
  max-width: 810px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.nsm-content {
  border-radius: 0;
  padding: 60px 150px;
  margin: 0;
  padding-top: 87px;
}
@media (max-width: 1024.9px) {
  .nsm-content {
    padding: 40px;
  }
}

.nsm-dialog {
  max-width: 100%;
}

.nsm-dialog-btn-close {
  width: 14px;
  height: 14px;
  padding: 0;
  top: 45px;
  right: 45px;
}
.nsm-dialog-btn-close svg {
  width: 100%;
}
@media (max-width: 1024.9px) {
  .nsm-dialog-btn-close {
    top: 25px;
    right: 25px;
  }
}

.modal-title {
  margin-bottom: 30px;
  margin-right: 40px;
  color: #343434;
  font-family: var(--ff-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.3px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  word-break: keep-all;
}
@media (max-width: 767.9px) {
  .modal-title {
    margin-bottom: 20px;
    margin-right: 15px;
    color: #444444;
    font-size: 14px;
  }
}
.modal-img {
  flex-shrink: 0;
  display: block;
  width: 138px;
  height: 138px;
  position: relative;
  border-radius: 50%;
}
@media (max-width: 1024.9px) {
  .modal-img {
    width: 130px;
    height: 130px;
  }
}
@media (max-width: 767.9px) {
  .modal-img {
    width: 92px;
    height: 92px;
  }
}
.modal-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.modal-img .img-upload, .modal-img .img-delete {
  width: 26px !important;
  min-width: 26px !important;
  height: 26px !important;
  border-radius: 8px !important;
  background-color: var(--white-color);
  font-size: 15px;
  text-align: center;
}
@media (max-width: 767.9px) {
  .modal-img .img-upload, .modal-img .img-delete {
    width: 24px !important;
    min-width: 24px !important;
    height: 24px !important;
    font-size: 14px;
  }
}
.modal-img .img-upload [class^=icon-]:before, .modal-img .img-upload [class*=" icon-"]:before, .modal-img .img-delete [class^=icon-]:before, .modal-img .img-delete [class*=" icon-"]:before {
  font-size: 15px;
  line-height: 24px;
}
@media (max-width: 767.9px) {
  .modal-img .img-upload [class^=icon-]:before, .modal-img .img-upload [class*=" icon-"]:before, .modal-img .img-delete [class^=icon-]:before, .modal-img .img-delete [class*=" icon-"]:before {
    font-size: 14px;
    line-height: 22px;
  }
}
.modal-img .img-upload {
  position: absolute;
  right: 0;
  bottom: 0;
  border: 1px solid #6AC4B3;
  color: #6AC4B3;
}
.modal-img .img-delete {
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #E47878;
  color: #E47878;
}
.modal-box {
  position: relative;
  padding: 30px;
  background: var(--white-color);
  border-radius: 20px;
  transition: 0.2s all ease-in-out;
}
@media (max-width: 767.9px) {
  .modal-box {
    border-radius: 14px;
    padding: 16px 10px 10px;
  }
}
.modal-switch {
  margin: 10px 0 30px;
}
@media (max-width: 767.9px) {
  .modal-switch {
    margin-top: 0;
  }
}
@media screen and (max-width: 583px) {
  .modal-switch {
    display: flex;
    justify-content: flex-start;
  }
}
.modal-btn {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
}
@media (max-width: 767.9px) {
  .modal-btn {
    margin-top: 20px;
  }
}
.modal-btn .waw-btn {
  width: 100%;
  max-width: 155px;
}
.modal-btn .waw-btn:nth-child(1) {
  justify-self: start;
}
.modal-btn .waw-btn:nth-child(2) {
  justify-self: end;
}
@media (max-width: 1024.9px) {
  .modal-btn .waw-btn {
    font-size: 16px;
    line-height: normal;
  }
}
@media (max-width: 767.9px) {
  .modal-btn .waw-btn {
    max-width: 100%;
    min-width: 0;
    height: 36px;
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 14px;
    line-height: normal;
  }
}
.modal-close {
  position: absolute !important;
  top: 25px;
  right: 25px;
  width: 42px !important;
  height: 42px !important;
  line-height: 22px !important;
}
.modal-close__icon {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
}
.modal-close__icon::before, .modal-close__icon::after {
  position: absolute;
  top: -4px;
  left: 10px;
  content: " ";
  height: 30px;
  width: 1px;
  background-color: #D4D4D4;
}
.modal-close__icon::before {
  transform: rotate(45deg);
}
.modal-close__icon::after {
  transform: rotate(-45deg);
}
@media (max-width: 767.9px) {
  .modal-close__icon {
    width: 10px;
    height: 10px;
  }
  .modal-close__icon::before, .modal-close__icon::after {
    height: 13px;
    top: -1px;
    left: 5px;
    background-color: #ADB8C6;
  }
}
@media (max-width: 767.9px) {
  .modal-close {
    top: 11px;
    right: 5px;
    width: 20px !important;
    height: 20px !important;
    line-height: 10px !important;
  }
}
.modal-section {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background: #F7F8FA;
}
@media (max-width: 1024.9px) {
  .modal-section {
    padding: 15px;
  }
}
@media (max-width: 767.9px) {
  .modal-section {
    padding: 10px;
    margin-top: -10px;
    border-radius: 6px;
  }
}
.modal-h2 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #343434;
  font-family: var(--ff-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.3px;
}
@media (max-width: 767.9px) {
  .modal-h2 {
    font-size: 14px;
  }
}
.modal-section .modal-h2 {
  color: #444444;
}
.modal-p {
  margin: 0;
  margin-bottom: 20px;
  color: #666666;
  font-family: var(--ff-field);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.3px;
}
@media (max-width: 767.9px) {
  .modal-p {
    font-size: 10px;
    line-height: normal;
  }
}
.modal-rowImg {
  display: flex;
  flex-wrap: nowrap;
  width: calc(100% + 30px);
  margin-left: -15px;
}
@media (max-width: 767.9px) {
  .modal-rowImg {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
}
.modal-rowImg__left {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 15px 10px;
}
.modal-rowImg__left .placeholder-icon {
  width: 100%;
  height: 100%;
  display: block;
  color: #D6D6DC;
  text-align: center;
  line-height: 138px;
  font-size: 55.2px;
}
.modal-rowImg__left .waw-input, .modal-rowImg__left .waw-select {
  max-width: 158px;
  margin-right: -20px;
}
@media (max-width: 1024.9px) {
  .modal-rowImg__left .placeholder-icon {
    line-height: 130px;
    font-size: 52px;
  }
  .modal-rowImg__left .waw-input, .modal-rowImg__left .waw-select {
    max-width: 150px;
  }
}
@media (max-width: 767.9px) {
  .modal-rowImg__left {
    margin: 0 10px 10px 5px;
  }
  .modal-rowImg__left .placeholder-icon {
    line-height: 92px;
    font-size: 36.8px;
  }
  .modal-rowImg__left .waw-input, .modal-rowImg__left .waw-select {
    max-width: 92px;
    margin-right: 0;
  }
}
.modal-rowImg__right {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  width: auto;
  width: calc(100% + 30px);
}
@media (max-width: 767.9px) {
  .modal-rowImg__right {
    width: calc(100% + 10px);
  }
}
.modal-selectMultiple {
  --item-height: 24px;
  position: relative;
  width: 100%;
  min-height: 42px;
  padding: calc((40px - var(--item-height)) / 2) 15px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #F0F1F7;
  background-color: #FFFFFF;
  color: #343434;
  font: normal 400 14px/18px var(--ff-field);
  letter-spacing: 0.3px;
  user-select: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}
.modal-selectMultiple:hover, .modal-selectMultiple._open {
  border-color: #6AC4B3;
}
.modal-selectMultiple._req {
  border-color: #E47878;
}
.modal-selectMultiple__placeholder {
  color: #ADB8C6;
  letter-spacing: 0.3px;
  line-height: var(--item-height);
  transition: 0.2s all ease-in-out;
}
.modal-selectMultiple__btn {
  position: absolute !important;
  bottom: 20.5px;
  right: 11px;
  width: 32px !important;
  height: 32px !important;
  font-size: 11px;
  line-height: 32px !important;
  border-radius: 50% !important;
  translate: 0 50%;
  transition: 0.15s all ease-in-out;
}
.modal-selectMultiple__item {
  position: relative;
  display: block;
  height: var(--item-height);
  padding: 5px 18px;
  border-radius: 57px;
  background: #6AC4B3;
  color: white;
  font-family: var(--ff-base);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.modal-selectMultiple__item:last-of-type {
  margin-right: 32px;
}
.modal-selectMultiple__item._delete {
  padding-right: calc(var(--item-height) + 1px);
}
.modal-selectMultiple__item__delete {
  position: absolute !important;
  top: 0;
  right: 0;
  width: var(--item-height) !important;
  height: var(--item-height) !important;
  line-height: var(--item-height) !important;
}
.modal-selectMultiple__item__delete .material-icons {
  font-size: 12px;
  line-height: var(--item-height);
  color: white;
}
@media (max-width: 767.9px) {
  .modal-selectMultiple {
    --item-height: 22px;
    min-height: 32px;
    padding: calc((30px - var(--item-height)) / 2) 10px;
    border-radius: 6px;
    font: normal 400 10px/14px var(--ff-field);
  }
  .modal-selectMultiple__btn {
    bottom: 15.5px;
    right: 4px;
    width: 24px !important;
    height: 24px !important;
    flex-shrink: 0;
    font-size: 10px;
    line-height: 24px !important;
  }
  .modal-selectMultiple__item {
    padding: 5px 11px;
    font-size: 10px;
    font-weight: 400;
  }
  .modal-selectMultiple__item:last-of-type {
    margin-right: 18px;
  }
}

hr {
  border: 0;
  border-top: 0.25px solid #979797;
  margin: 30px 0;
}

.modal-popup {
  width: 100%;
  max-width: 300px;
  padding: 30px 26px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.modal-popup .col-1 {
  padding: 0 0 10px;
}
.modal-popup .modal-btn {
  justify-content: center;
}
@media (max-width: 767.9px) {
  .modal-popup .modal-btn .waw-btn {
    margin-bottom: 0;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
}
@media (max-width: 767.9px) {
  .row {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
}

.col-6 {
  width: 16.66%;
  padding: 0 5px 10px;
}
@media (max-width: 575.9px) {
  .col-6 {
    width: 33.33%;
  }
}

.col-5 {
  width: 20%;
  padding: 0 15px 10px;
}
@media (max-width: 767.9px) {
  .col-5 {
    padding: 0 5px 10px;
    width: 100%;
  }
}

.col-4 {
  width: 25%;
  padding: 0 15px 10px;
}
@media (max-width: 767.9px) {
  .col-4 {
    padding: 0 5px 10px;
    width: 100%;
  }
}

.col-3 {
  width: 33.33%;
  padding: 0 15px 10px;
}
@media (max-width: 767.9px) {
  .col-3 {
    padding: 0 5px 10px;
  }
}

.col-25 {
  width: 40%;
  padding: 0 15px 10px;
}
@media (max-width: 767.9px) {
  .col-25 {
    padding: 0 5px 10px;
    width: 100%;
  }
}

.col-2 {
  width: 50%;
  padding: 0 15px 10px;
}
@media (max-width: 767.9px) {
  .col-2 {
    padding: 0 5px 10px;
  }
}

.col-2m {
  width: 50%;
  padding: 0 15px 10px;
}

.col-23 {
  width: 66.66%;
  padding: 0 15px 10px;
}
@media (max-width: 767.9px) {
  .col-23 {
    padding: 0 5px 10px;
    width: 100%;
  }
}

.col-1 {
  width: 100%;
  padding: 0 15px 10px;
}
@media (max-width: 767.9px) {
  .col-1 {
    padding: 0 5px 10px;
  }
}

.col-10 {
  width: 10%;
  padding: 0 15px 10px;
}
@media (max-width: 767.9px) {
  .col-10 {
    padding: 0 5px 10px;
    width: 100%;
  }
}

.col-30 {
  width: 30%;
  padding: 0 15px 10px;
}
@media (max-width: 767.9px) {
  .col-30 {
    padding: 0 5px 10px;
    width: 100%;
  }
}

.table-wrapp {
  background: var(--white-color);
  padding: 20px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(51, 77, 110, 0.25);
  border-radius: 35px;
}
.table-wrapp table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}
.table-wrapp .cl-table-clients {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1468px) {
  .table-wrapp .cl-table-clients._fl-column {
    flex-direction: column;
    align-items: center;
  }
  .table-wrapp .cl-table-clients._fl-column .cl-table-clients__info {
    margin-left: 0;
  }
}
@media (max-width: 767.9px) {
  .table-wrapp .cl-table-clients {
    flex-direction: row;
  }
  .table-wrapp .cl-table-clients .cl-table-clients__info {
    margin-left: 15px;
  }
}
.table-wrapp .cl-table-clients .icon-pencil::before {
  transition: 0.2s all ease-in-out;
}
.table-wrapp .cl-table-clients .icon-pencil:hover::before {
  color: var(--sky-color);
  transition: 0.2s all ease-in-out;
}
.table-wrapp .cl-table-clients__img {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  width: 50px;
  height: 50px;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 3px;
}
.table-wrapp .cl-table-clients__img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.table-wrapp .cl-table-clients__info {
  flex-grow: 1;
  text-align: left;
  margin-left: 15px;
}
.table-wrapp .cl-table-clients__status {
  color: var(--red-color);
}
@media screen and (max-width: 600px) {
  .table-wrapp._vertical .cl-table-clients__img {
    display: none;
  }
  .table-wrapp._vertical td {
    text-align: right;
  }
  .table-wrapp._vertical .cl-table-clients__info {
    text-align: right;
  }
  .table-wrapp._vertical table {
    border: 0;
  }
  .table-wrapp._vertical td .cl-table-clients {
    align-items: flex-end;
  }
  .table-wrapp._vertical table caption {
    font-size: 1.3em;
  }
  .table-wrapp._vertical table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .table-wrapp._vertical table tr {
    display: block;
    margin-bottom: 0.625em;
    border-bottom: 3px solid #ddd;
  }
  .table-wrapp._vertical table td {
    display: block;
    border-right: none;
    text-align: right;
    min-height: 56px;
  }
  .table-wrapp._vertical table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  .table-wrapp._vertical table td:last-child {
    border-bottom: 0;
  }
}
.table-wrapp th {
  padding: 15px 10px;
  text-align: center;
  border-right: 0.5px solid #979797;
  border-bottom: 0.5px solid #979797;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #191C1F;
}
.table-wrapp th:last-child {
  border-right: none;
}
@media (max-width: 1024.9px) {
  .table-wrapp th {
    font-size: 12px;
  }
}
.table-wrapp td {
  position: relative;
  cursor: pointer;
  padding: 15px 10px;
  text-align: center;
  vertical-align: middle;
  border-right: 0.5px solid #979797;
  border-bottom: 0.5px solid #979797;
  letter-spacing: 0.01em;
  word-break: break-word;
  font-size: 14px;
  line-height: 16px;
  color: #191C1F;
}
@media (max-width: 1024.9px) {
  .table-wrapp td {
    font-size: 12px;
  }
}
.table-wrapp tr:last-child td {
  border-bottom: none;
}
.table-wrapp tr td:last-child,
.table-wrapp tr th:last-child {
  border-right: none;
  border-right: none;
}
@media (max-width: 600px) {
  .table-wrapp tr:nth-child(even) {
    background: rgba(0, 0, 0, 0.04);
  }
}

.table-wrapp tbody tr {
  transition: 0.3s all ease-in-out;
}

@media (max-width: 1749.9px) {
  .xl-hide {
    display: none;
  }

  .xl-br-none {
    border-right: none !important;
  }
}
@media (max-width: 767.9px) {
  .sm-hide {
    display: none;
  }

  .sm-br-none {
    border-right: none !important;
    position: relative;
  }
}
th .material-icons {
  opacity: 0;
  transition: 0.3s all ease-in-out;
  margin-left: 10px;
}
th:hover .material-icons {
  opacity: 1;
  transition: 0.3s all ease-in-out;
}

.table-container {
  width: 100%;
  margin-top: -12px;
}
.table-container:not(._paginator) {
  margin-bottom: -12px;
}
.table-container .table {
  width: 100%;
  border-spacing: 0 12px;
  table-layout: fixed;
}
.table-container .table thead th {
  padding: 4px 8px;
  color: #444444;
  font-family: var(--ff-second);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  user-select: none;
}
.table-container .table thead th:first-child {
  padding-left: 85px;
}
.table-container .table thead th:last-child {
  width: 124px;
  padding-right: 16px;
}
.table-container .table thead th.sort {
  cursor: pointer;
}
.table-container .table thead th.sort .table-header__sort {
  display: none;
  position: relative;
}
.table-container .table thead th.sort .table-header__sort .arrow {
  position: absolute;
  top: 50%;
  opacity: 0.3;
  margin: 0;
  font-size: 22px;
  user-select: none;
  transition: 0.1s all ease-in-out;
}
.table-container .table thead th.sort .table-header__sort .arrow-up {
  transform: translate(0, calc(-50% - 4px));
}
.table-container .table thead th.sort .table-header__sort .arrow-down {
  transform: translate(0, calc(-50% + 4px));
}
@media (max-width: 1024.9px) {
  .table-container .table thead th.sort .table-header__sort .arrow {
    font-size: 20px;
  }
  .table-container .table thead th.sort .table-header__sort .arrow-up {
    transform: translate(0, calc(-50% - 3.5px));
  }
  .table-container .table thead th.sort .table-header__sort .arrow-down {
    transform: translate(0, calc(-50% + 3.5px));
  }
}
.table-container .table thead th.sort .table-header__sort._sortActiveDown .arrow-down {
  opacity: 1;
}
.table-container .table thead th.sort .table-header__sort._sortActiveDown .arrow-up {
  opacity: 0.3;
}
.table-container .table thead th.sort .table-header__sort._sortActiveUp .arrow-up {
  opacity: 1;
}
.table-container .table thead th.sort .table-header__sort._sortActiveUp .arrow-down {
  opacity: 0.3;
}
.table-container .table tbody tr {
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.14);
}
.table-container .table tbody td {
  padding: 15px 8px;
  background: white;
  color: #343434;
  font-family: var(--ff-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.table-container .table tbody td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 20px;
}
.table-container .table tbody td:last-child {
  width: 124px;
  padding-right: 16px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table-container .table tbody td .actions {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.table-container .table tbody tr.noresults {
  position: relative;
}
.table-container .table tbody tr.noresults td {
  position: absolute !important;
  width: 100% !important;
  font: normal 500 20px/normal var(--ff-third);
  align-content: center;
  text-align: center;
}
.table-container .table tbody tr.noresults td div {
  margin-top: 10px;
  font-weight: 400;
  font-size: 16px;
}
.table-container .table tbody tr.noresults {
  height: 200px;
}
.table-container .table tbody tr.noresults td {
  height: 100%;
  padding: 45px 20px;
}
.table-container .table tbody tr.noresults td .icon-search {
  display: block;
  margin-bottom: 15px;
  font-size: 32px;
}
.table-container._empty {
  height: 100%;
  margin-bottom: 0px;
}
.table-container._empty + mat-paginator {
  display: none;
}
.table-container._empty .table {
  height: 100%;
}
.table-container._empty .table thead {
  display: none;
}
.table-container._empty .table tbody {
  height: calc(100% + 12px);
}
.table-container._empty .table tbody tr.empty {
  height: 100%;
  position: relative;
}
.table-container._empty .table tbody tr.empty td {
  width: 100% !important;
  height: calc(100% + 12px);
  padding: 20px 30px;
  font: normal 500 20px/normal var(--ff-third);
  align-content: center;
  text-align: center;
}
.table-container._empty .table tbody tr.empty td div:last-child {
  margin-bottom: 0;
}
@media (max-width: 1024.9px) {
  .table-container .table thead th {
    padding: 4px;
    font-size: 12px;
  }
  .table-container .table thead th:first-child {
    padding-left: 54px;
  }
  .table-container .table thead th:last-child {
    width: 103px;
    padding-right: 13px;
  }
  .table-container .table tbody td {
    padding: 10px 5px;
    font-size: 12px;
  }
  .table-container .table tbody td:first-child {
    padding-left: 10px;
  }
  .table-container .table tbody td:last-child {
    width: 103px;
    padding-right: 13px;
  }
  .table-container.tablet-col2 thead, .table-container.tablet-col3 thead {
    display: none;
  }
  .table-container.tablet-col2 tbody, .table-container.tablet-col3 tbody {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
  }
  .table-container.tablet-col2 tbody tr, .table-container.tablet-col3 tbody tr {
    background: white;
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    grid-template-rows: min-content min-content auto;
    padding: 13px;
  }
  .table-container.tablet-col2 tbody td, .table-container.tablet-col3 tbody td {
    width: 100% !important;
    padding: 0 !important;
    border-radius: 0 !important;
    background: transparent;
  }
  .table-container.tablet-col2 tbody td::before, .table-container.tablet-col3 tbody td::before {
    content: attr(data-label);
    display: block;
    padding-bottom: 10px;
    color: #444444;
    font-family: var(--ff-second);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
  }
  .table-container.tablet-col2 tbody td .actions, .table-container.tablet-col3 tbody td .actions {
    gap: 8px;
  }
  .table-container.tablet-col2 tbody td .actions .waw-btn__icon, .table-container.tablet-col3 tbody td .actions .waw-btn__icon {
    background-color: #EBEFEE;
  }
  .table-container.tablet-col2 tbody tr.noresults, .table-container.tablet-col3 tbody tr.noresults {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    height: 140px;
    padding: 20px 10px;
  }
  .table-container.tablet-col2 tbody tr.noresults td, .table-container.tablet-col3 tbody tr.noresults td {
    position: relative !important;
  }
  .table-container.tablet-col2 tbody tr.noresults td::before, .table-container.tablet-col3 tbody tr.noresults td::before {
    display: none;
  }
  .table-container.tablet-col2 tbody {
    grid-template-columns: 1fr;
  }
  .table-container.tablet-col2 tbody tr {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
    width: 100%;
    padding: 15px;
  }
  .table-container.tablet-col2 tbody td::before {
    padding-bottom: 15px;
    font-size: 14px;
  }
  .table-container.tablet-col3 tbody {
    grid-template-columns: 1fr 1fr;
  }
  .table-container.tablet-col3 tbody tr {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .table-container.tablet-col3 tbody td {
    font-size: 12px;
  }
  .table-container.tablet-col3 tbody tr.noresults, .table-container.tablet-col3 tbody tr.empty {
    grid-column: span 2;
    grid-template-columns: none;
    grid-template-rows: none;
  }
  .table-container.tablet-col3 tbody tr.noresults {
    height: auto;
  }
  .table-container.tablet-col3 tbody tr.empty td {
    height: calc(100% - 26px) !important;
    width: calc(100% - 26px) !important;
    margin: 13px;
  }
}
@media (max-width: 767.9px) {
  .table-container .table thead {
    display: none;
  }
  .table-container .table tbody {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
  }
  .table-container .table tbody tr {
    background: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    width: 100%;
    padding: 13px;
  }
  .table-container .table tbody td {
    width: 100% !important;
    padding: 0 !important;
    background: transparent;
    font-size: 10px !important;
    border-radius: 0 !important;
  }
  .table-container .table tbody td::before {
    content: attr(data-label);
    display: block;
    padding-bottom: 10px !important;
    color: #444444;
    font-family: var(--ff-second);
    font-size: 12px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
  }
  .table-container .table tbody td .actions {
    gap: 8px;
  }
  .table-container .table tbody td .actions .waw-btn__icon {
    background-color: #EBEFEE;
  }
  .table-container .table tbody tr.empty td {
    height: calc(100% - 26px) !important;
    width: calc(100% - 26px) !important;
    margin: 13px;
  }
  .table-container .table tbody tr.noresults {
    grid-template-columns: 1fr !important;
    grid-template-rows: auto !important;
    height: 125px;
    padding: 20px 10px;
  }
  .table-container .table tbody tr.noresults td {
    position: relative !important;
    font-size: 16px !important;
  }
  .table-container .table tbody tr.noresults td::before {
    display: none;
  }
  .table-container .table tbody tr.noresults td div {
    margin-top: 5px;
    font-size: 14px;
  }
  .table-container .table tbody tr.noresults td .icon-search {
    font-size: 28px;
  }
  .table-container.mobile-col3 .table tbody tr {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: min-content min-content auto;
  }
  .table-container.mobile-col3 .table tbody tr.noresults {
    height: auto;
  }
  .table-container.mobile-col3 .table tbody tr.empty {
    grid-column: span 2;
    grid-template-columns: none;
    grid-template-rows: none;
  }
  .table-container.mobile-col3 .table tbody tr.empty td {
    height: calc(100% - 26px) !important;
    width: calc(100% - 26px) !important;
    margin: 13px;
  }
}

.table__user {
  display: flex;
  align-items: center;
}
.table__user__img {
  min-width: 44px;
  min-height: 44px;
  max-width: 44px;
  max-height: 44px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.table__user__info {
  margin-left: 21px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  text-wrap: nowrap;
}
.table__user__info-desc {
  font-size: 80%;
}
.table__user__info-deleted {
  color: #E47878;
}
@media (max-width: 1024.9px) {
  .table__user__img {
    min-width: 34px;
    min-height: 34px;
    max-width: 34px;
    max-height: 34px;
    width: 34px;
    height: 34px;
  }
  .table__user__info {
    margin-left: 10px;
  }
}
@media (max-width: 767.9px) {
  .table__user__img {
    min-width: 28px;
    min-height: 28px;
    max-width: 28px;
    max-height: 28px;
    width: 28px;
    height: 28px;
  }
  .table__user__info-desc {
    font-size: 100%;
  }
}

.note {
  overflow-x: auto;
  overflow-wrap: break-word;
}
.note._limit {
  position: relative;
  overflow-y: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.note._limit._more {
  overflow-x: hidden;
}
.note._limit._all {
  overflow-x: auto;
  -webkit-line-clamp: unset;
}
.note._limit + .note-btn {
  text-align: center;
}
.note._limit + .note-btn button {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.note._limit + .note-btn button .icon-arrow_up, .note._limit + .note-btn button .icon-arrow_down {
  line-height: 24px;
}
@media (max-width: 1024.9px) {
  .note._limit {
    -webkit-line-clamp: 3;
  }
}
.note p {
  margin: 2px 0;
}

.notes .timeline {
  position: relative;
  max-width: 100%;
  margin: 20px auto 0;
  padding: 0;
}
.notes .timeline:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 3px;
  height: 100%;
  box-sizing: border-box;
  margin: auto;
  background: var(--grey-color);
}
.notes .event {
  position: relative;
  overflow: hidden;
  padding: 20px;
}
.notes .event .event-date {
  position: absolute;
  top: 57%;
  right: 0;
  width: 46%;
  margin-top: -20px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--grey-color);
}
.notes .event .event-date div {
  margin-bottom: 5px;
}
.notes .event .event-desc {
  position: relative;
  float: left;
  width: 46%;
  height: auto;
  padding: 20px;
  line-height: 22px;
  background: var(--white-color);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}
.notes .event:nth-child(even) .event-date {
  left: 0;
  text-align: right;
}
.notes .event:nth-child(even) .event-desc {
  float: right;
}
.notes .event .event-desc__counter {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 30px;
  height: 30px;
  margin: -8px auto 0;
  background: #005797;
  border-radius: 50%;
}
.notes .event .event-desc__counter::before {
  content: "";
  display: block;
  position: absolute;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  left: 3.28px;
  background: transparent;
  border: 1.5px solid var(--white-color);
  top: 3px;
}
@media all and (max-width: 900px) {
  .notes .timeline {
    padding-right: 32px;
  }
  .notes .timeline:before {
    left: 28px;
    right: auto;
    margin: 0;
    padding: 0 0 50px;
  }
  .notes .event {
    padding-left: 60px;
  }
  .notes .event .event-date {
    position: relative;
    top: auto;
    right: auto;
    left: 35px;
    width: 100%;
    margin: 0 0 16px;
  }
  .notes .event .event-desc {
    float: none;
    width: 100%;
    overflow: hidden;
  }
  .notes .event .event-desc__counter {
    top: 20px;
    left: 14px;
    right: auto;
    margin: 0;
  }
  .notes .event:nth-child(even) .event-date {
    left: 35px;
    text-align: left;
  }
  .notes .event:nth-child(even) .event-desc {
    float: none;
  }
  .notes .event:before {
    top: 20px;
    left: 0;
    right: auto;
    margin: 0;
  }
}
@media all and (max-width: 576px) {
  .notes .timeline {
    padding-right: 0;
  }
  .notes .timeline::before {
    display: none;
  }
  .notes .event-desc__price {
    right: 10px;
    top: 2px;
    font-size: 12px;
  }
  .notes .event-desc__counter {
    display: none;
  }
  .notes .event {
    margin: -20px;
    padding-left: 20px;
  }
  .notes .event-date {
    left: 0 !important;
  }
  .notes .event-desc__info {
    flex-direction: column;
    align-items: start !important;
  }
  .notes .event-desc__status {
    position: absolute;
    right: 20px;
  }
  .notes .event-desc__clock {
    text-align: left;
    display: flex;
    margin-bottom: 5px;
  }
  .notes .event-desc__clock div {
    text-align: left;
  }
  .notes .row {
    margin-left: 0 !important;
  }
  .notes .cl-service-nav {
    flex-direction: column;
  }
  .notes .cl-service-nav__right {
    margin-top: 20px;
    width: 100%;
  }
  .notes .cl-service-nav__right .cl-service-nav__datepicker {
    width: 100%;
  }
}
.notes .event-desc {
  display: flex;
  justify-content: space-between;
}
.notes .event-desc__left {
  max-width: 100px;
  width: 100%;
  margin-right: 10px;
}
.notes .event-desc__right {
  flex-grow: 1;
  width: 100%;
}
.notes .event-desc__img {
  margin: 0 auto 10px auto;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  border: 1.5px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 3px;
}
.notes .event-desc__img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.notes .event-desc__name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
  word-break: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.notes .event-desc__info {
  display: flex;
  align-items: center;
}
.notes .event-desc__info .row {
  margin-left: 20px;
  padding-right: 35px;
  display: flex;
  align-items: center;
}
.notes .event-desc__info .row:first-child {
  margin-bottom: 5px;
}
.notes .event-desc__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
}
.notes .event-desc__time {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
}
.notes .event-desc__desc {
  word-break: break-word;
  padding-top: 6px;
  margin-top: 15px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
}
.notes .event-desc__day, .notes .event-desc__month {
  text-align: center;
  width: 35px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--blue-color);
}
.notes .event-desc__status {
  text-align: center;
  font-size: 12px;
  margin-left: 15px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: var(--sky-color);
}
.notes .event-desc__price {
  position: absolute;
  right: 15px;
  top: 16px;
}
.notes .event {
  margin: 0;
}

.discount-container {
  padding: 0px 20px 20px 20px;
  overflow: auto;
  height: calc(100% - var(--topbar-height));
}
@media (max-width: 1024.9px) {
  .discount-container {
    height: calc(100% - var(--topbar-height-mobile));
  }
}

.discountAdd {
  padding: 20px;
}

.discount-list__body {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(575px, 1fr));
  grid-gap: 40px;
  justify-items: center;
}
@media screen and (max-width: 600px) {
  .discount-list__body {
    width: 100%;
    grid-template-columns: 1fr;
  }
}
.discount-list__item {
  width: 100%;
  background: var(--white-color);
  box-shadow: 0px 4px 4px rgba(51, 77, 110, 0.25);
  border-radius: 35px;
  padding: 40px 50px 40px;
  position: relative;
}
@media (max-width: 767.9px) {
  .discount-list__item {
    border-radius: 15px;
    padding: 30px 30px 15px;
  }
}
.discount-list__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
  margin-bottom: 10px;
}
.discount-list__property {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
  margin-top: 8px;
  display: grid;
  grid-template-columns: auto 1fr;
}
.discount-list__property div {
  font-weight: 600;
  margin-right: 5px;
}
.discount-list__icon {
  color: var(--grey-color);
  position: absolute;
  top: 20px;
  right: 50px;
}
.discount-list__icon i:hover {
  color: var(--black-color);
  cursor: pointer;
}
.discount-list__icon i:last-child {
  margin-left: 40px;
}
.discount-list__icon i {
  font-size: 22px;
}
@media (max-width: 767.9px) {
  .discount-list__icon {
    top: 10px;
    right: 30px;
  }
  .discount-list__icon i:last-child {
    margin-left: 30px;
  }
  .discount-list__icon i {
    font-size: 20px;
  }
}
.discount-list__description {
  margin-bottom: 18px;
  margin-top: 2px;
  color: var(--grey-color);
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
}

.mat-form-field {
  display: block;
  width: 100%;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
  border: none;
}

.mat-calendar .mat-calendar-table-header-divider::after {
  left: 0 !important;
  right: 0 !important;
}
.mat-calendar .mat-calendar-body-cell .mat-calendar-body-cell-content.mat-focus-indicator {
  background: transparent;
}
.mat-calendar .mat-calendar-body-cell:hover .mat-calendar-body-cell-content.mat-focus-indicator {
  background: rgba(115, 119, 118, 0.15) !important;
}
.mat-calendar .mat-calendar-body-disabled.mat-calendar-body-cell:hover > .mat-calendar-body-cell-content {
  background: transparent !important;
}
.mat-calendar .mat-calendar-body-cell .mat-calendar-body-selected,
.mat-calendar .mat-calendar-body-cell:hover .mat-calendar-body-cell-content.mat-calendar-body-selected {
  background: #6AC4B3 !important;
  box-shadow: inset 0 0 0 1px white;
}
.mat-calendar .mat-calendar-body-disabled.mat-calendar-body-cell .mat-calendar-body-selected, .mat-calendar .mat-calendar-body-disabled.mat-calendar-body-cell:hover .mat-calendar-body-cell-content.mat-calendar-body-selected {
  background: rgba(106, 196, 179, 0.4) !important;
}
.mat-calendar .mat-calendar-body-in-range::before {
  background: rgba(115, 119, 118, 0.15) !important;
}

.mat-datepicker-content {
  margin: 10px 0 !important;
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 0px 0px #A4A7AF1A, 0px 14px 32px 0px #A4A7AF1A, 0px 58px 58px 0px #A4A7AF17, 0px 130px 78px 0px #A4A7AF0D, 0px 230px 92px 0px #A4A7AF03, 0px 360px 101px 0px #A4A7AF00;
  background-color: white;
}

.cdk-overlay-dark-backdrop {
  background: #0000007A !important;
}

.calendar-container {
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - var(--topbar-height));
}
@media (max-width: 1024.9px) {
  .calendar-container {
    height: calc(100% - var(--topbar-height-mobile));
  }
}

.calendar-wrapper {
  background: var(--white-color);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.cal-table {
  flex-grow: 1;
  width: calc(100% - 130px - 40px);
  position: absolute;
  margin-left: 130px;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
}
@media (max-width: 500px) {
  .cal-table {
    width: calc(100% - 40px);
    margin-left: 0;
  }
}

.cal-table__row {
  display: flex;
  height: 100%;
  overflow-x: auto;
}

.cal-date {
  width: 100%;
}

.cal-date__item {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
  border-top: 1px solid var(--grey-color);
  transition: 0.3s all ease-in-out;
}
.cal-date__item--time {
  position: absolute;
  top: 2px;
}
.cal-date__item--time .timeSm {
  display: none;
}
@media (max-width: 500px) {
  .cal-date__item--time {
    left: -19px;
    width: 18px;
    top: -8px;
  }
  .cal-date__item--time .timeSm {
    display: block;
  }
  .cal-date__item--time .timeLg {
    display: none;
  }
}

.cal-date__item--line {
  display: block;
  width: 100%;
  height: 2px;
  border-bottom: 1px dashed #bbbaba;
}
.cal-date__item--line._main {
  z-index: 10;
  top: -2px;
  border-bottom: 1px solid var(--grey-color);
  height: 2px;
}

.col-date__item--lineHover {
  position: absolute;
  font-size: 10px;
  left: 80px;
  top: 5px;
}
@media (max-width: 500px) {
  .col-date__item--lineHover {
    left: 3px;
  }
}

.cal-table__item {
  min-width: 200px;
  height: 100%;
  word-break: break-word;
  position: relative;
  border-right: 2px solid #f3f3f3;
  z-index: 1;
}
.cal-table__item.active {
  z-index: 3333;
}
@media (max-width: 500px) {
  .cal-table__item {
    min-width: 100px;
  }
}
.cal-table__item:first-child {
  border-left: 2px solid #f3f3f3;
}
.cal-table__item:last-child {
  border-right: none;
}
.cal-table__item:last-child {
  margin-right: 0;
}

.col-date__currentDate {
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--blue-color);
  font-size: 14px;
}
.col-date__currentDate .cal-date-small {
  max-width: 75px;
  width: 100%;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
@media (max-width: 500px) {
  .col-date__currentDate {
    opacity: 0;
  }
}

.col-date__currentDate,
.cal-table__user {
  height: 80px;
  text-align: left;
  padding: 10px;
}

.cal-table__user {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.cal-table__avatar {
  max-width: 42px;
  max-height: 42px;
  min-width: 42px;
  min-height: 42px;
  padding: 3px;
  border-radius: 50%;
  border: 1.5px solid #DFE0EB;
}
.cal-table__avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.cal-table__name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 767.9px) {
  .cal-table__name {
    font-size: 9px;
  }
}

.cal-table__item__body,
.cal-table__lunch__body {
  padding: 1px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.cal-appoint {
  background: var(--white-color);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 10px 10px 10px 15px;
  position: relative;
  position: absolute;
  width: 100%;
  white-space: pre;
  cursor: pointer;
  min-height: 23px;
}
.cal-appoint .New {
  color: var(--green-color);
}
.cal-appoint .Canceled {
  color: var(--red-color);
}
.cal-appoint .Completed {
  color: #ababab;
}
.cal-appoint .Done {
  color: #ababab;
}
.cal-appoint .Confirmed {
  color: var(--sky-color);
}
.cal-appoint .No-show {
  color: var(--red-color);
}
.cal-appoint__color {
  position: absolute;
  width: 5px;
  height: 100%;
  background-color: var(--green-color);
  top: 0;
  left: 0;
  border-radius: 5px 0px 0px 5px;
}
.cal-appoint__warning {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 30px;
}
.cal-appoint__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: initial;
}
.cal-appoint__time {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
  margin-bottom: 5px;
}
.cal-appoint__staff {
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: keep-all;
}
.cal-appoint__staff._deleted {
  color: var(--red-color);
}
.cal-appoint__status {
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: var(--green-color);
  word-break: keep-all;
}
.cal-appoint__status-min {
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: var(--green-color);
  word-break: keep-all;
}
@media (max-width: 500px) {
  .cal-appoint__status-min {
    display: none;
  }
}
.cal-appoint__status-icon {
  display: none;
  font-size: 8px;
}
@media (max-width: 500px) {
  .cal-appoint__status-icon {
    display: inline;
  }
}

.col-lunch {
  cursor: pointer;
  background: #f6f6f6;
  border-radius: 5px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--blue-color);
}

.cal-nav {
  padding: 0px 20px 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cal-nav__left {
  display: flex;
}
.cal-nav__left .mat-select {
  margin-right: 15px;
  max-width: 200px;
  width: 100%;
  min-width: 200px;
}
.cal-nav__left .mat-select:last-child {
  margin-right: 0;
}
@media (max-width: 1010px) {
  .cal-nav__left {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .cal-nav__left .mat-select {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.cal-nav__right {
  display: flex;
}

.lunch-min {
  font-size: 10px;
}

.appoint-medium .cal-appoint__time {
  display: none;
}

.appoint-min {
  padding: 5px 10px 5px 15px;
  overflow: hidden;
}
.appoint-min .cal-appoint__warning {
  top: -3px;
}
.appoint-min .cal-appoint__time,
.appoint-min .cal-appoint__staff {
  display: none;
}
.appoint-min .cal-appoint__title {
  margin-right: 5px;
}
.appoint-min .cal-appoint__status {
  display: none;
}

.cl-service-btn {
  background: var(--sky-color);
  width: 35px;
  height: 35px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.1s all ease-in-out;
}
.cl-service-btn:hover {
  background: var(--skyL-color);
  transition: 0.1s all ease-in-out;
}

.btn-scroll {
  display: none;
  position: fixed;
  right: 220px;
  bottom: 20px;
  z-index: 111;
}
.btn-scroll._active {
  background: #FF0000;
}
@media (max-width: 767.9px) {
  .btn-scroll {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.filter-sm {
  display: none;
  position: fixed;
  right: 170px;
  bottom: 20px;
  z-index: 111;
}
@media (max-width: 767.9px) {
  .filter-sm {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.add-appoint-lg {
  display: inline-block;
}
@media (max-width: 767.9px) {
  .add-appoint-lg {
    display: none;
  }
}

.add-appoint-sm {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 111;
}
@media (max-width: 767.9px) {
  .add-appoint-sm {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.add-lunch-sm {
  display: none;
  position: fixed;
  right: 70px;
  bottom: 20px;
  z-index: 111;
}
@media (max-width: 767.9px) {
  .add-lunch-sm {
    display: inline-block;
  }
}

.all-hours-sm {
  display: none;
  position: fixed;
  right: 120px;
  bottom: 20px;
  z-index: 111;
}
@media (max-width: 767.9px) {
  .all-hours-sm {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.waw-alert-wrapper {
  font-family: var(--ff-base);
  text-transform: uppercase;
}

.pureplanner .waw-alert.waw-alert-color-blue {
  background: #334D6E !important;
  border-color: #334D6E !important;
  border-radius: 3px;
  opacity: 0.9;
}
.pureplanner .waw-alert-title,
.pureplanner .waw-alert-message {
  color: var(--white-color) !important;
}
.pureplanner .waw-alert__close::after,
.pureplanner .waw-alert__close::before {
  background-color: var(--white-color) !important;
}
.pureplanner .waw-alert-color-red {
  background: var(--red-color) !important;
  border-color: var(--red-color) !important;
  border-radius: 3px;
  opacity: 0.9;
}
.pureplanner .waw-alert-color-yellow {
  background: #68C7B3 !important;
  border-color: #68C7B3 !important;
  opacity: 0.9;
  border-radius: 3px;
}
.pureplanner .waw-alert-color-yellow .alert-btn {
  border: none;
  color: var(--white-color);
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  width: 80px;
  height: 30px;
  margin-left: 5px;
  margin-right: 5px;
}
.pureplanner .waw-alert-color-yellow .alert-btn:first-child {
  border: 1px solid var(--sky-color);
  background-color: var(--white-color);
  transition: 0.2s ease-in-out;
  color: var(--sky-color);
}
.pureplanner .waw-alert-color-yellow .alert-btn:last-child {
  background-color: var(--red-color);
  transition: 0.2s ease-in-out;
  color: var(--white-color);
}
.pureplanner .waw-alert-color-yellow .alert-btn:last-child:hover {
  background-color: var(--redL-color);
  transition: 0.2s ease-in-out;
}
.pureplanner .waw-alert-color-yellow .waw-alert__close {
  display: none;
}
@media (max-width: 1024.9px) {
  .pureplanner .waw-alert-color-yellow .waw-alert-body {
    flex-direction: column;
  }
  .pureplanner .waw-alert-color-yellow .waw-alert-body .alert-btn {
    margin-top: 10px;
  }
}
.pureplanner.notification {
  position: relative;
  top: var(--topbar-height-mobile);
  text-transform: none;
}
@media (min-width: 768px) {
  .pureplanner.notification {
    top: var(--topbar-height);
  }
}
.pureplanner.notification .waw-alert-body {
  align-items: flex-start !important;
}
.pureplanner.notification .waw-alert {
  background-color: white !important;
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  box-shadow: 0px 0px 0px 0px #A4A7AF1A, 0px 14px 32px 0px #A4A7AF1A, 0px 58px 58px 0px #A4A7AF17, 0px 130px 78px 0px #A4A7AF0D, 0px 230px 92px 0px #A4A7AF03, 0px 360px 101px 0px #A4A7AF00 !important;
  opacity: 1;
}
.pureplanner.notification .waw-alert::after {
  box-shadow: none;
}
.pureplanner.notification .waw-alert:hover .ntf-desc {
  display: block;
}
.pureplanner.notification .waw-alert__close:before {
  background-color: #A5A5A5 !important;
}
.pureplanner.notification .waw-alert__close:after {
  background-color: #A5A5A5 !important;
}
@media (max-width: 767.9px) {
  .pureplanner.notification .ntf {
    width: 100%;
  }
  .pureplanner.notification .ntf-desc {
    display: block !important;
  }
}
.pureplanner.notification .ntf-clock, .pureplanner.notification .ntf-unread {
  display: none;
}
.pureplanner.notification .ntf-desc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.select-container {
  position: relative;
}

.select {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  min-height: 42px;
  max-height: 42px;
  padding: 11px 47px 11px 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #F0F1F7;
  background-color: #FFFFFF;
  font: normal 400 14px/18px var(--ff-field);
  letter-spacing: 0.3px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s border ease-in-out;
}
.select:hover:not(._disabled), .select:active:not(._disabled) {
  border-color: #6AC4B3;
}
.select._disabled, .select._disabled .btn {
  cursor: not-allowed;
}
.select.search::before, .select.location::before, .select.service::before {
  position: absolute;
  top: 50%;
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, -50%);
}
.select.search::before {
  content: "\e914";
  left: 15px;
  color: #999999;
  font-size: 14px;
}
.select.location::before {
  content: "\e944";
  left: 10px;
  color: #444444;
  font-size: 18px;
}
.select.service::before {
  content: "\e910";
  left: 10px;
  color: #444444;
  font-size: 18px;
}
.select.staff::before {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  content: "\e7ff";
  font-family: "Material Icons";
  color: #999999;
  font-size: 24px;
}
.select.category::before {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  content: "\e574";
  font-family: "Material Icons";
  color: #999999;
  font-size: 24px;
}
.select.money::before {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  content: "\e263";
  font-family: "Material Icons Round";
  color: #444444;
  font-size: 21.5px;
}
.select._icon {
  padding: 11px 47px 11px 36px;
}
.select._open {
  border-color: #6AC4B3;
}
.select._req {
  border-color: #E47878 !important;
}
.select._filter {
  margin-bottom: 0;
  padding-right: 37px;
  padding-left: 40px;
  border-radius: 10px;
  border: 1px solid transparent;
  background: var(--white-color);
  color: #444444;
  font-family: var(--ff-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
}
.select._filter .select__placeholder {
  color: #444444;
  font-family: var(--ff-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
}
.select._filter .select__value {
  font-family: var(--ff-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
}
.select._filter .btn {
  right: 5px;
}
.select._filter::before {
  left: 12px;
  color: #444444;
}
.select._zero_padding {
  padding: 0 40px 0 0;
}
.select._zero_padding .select__placeholder {
  margin: 11px 0px 11px 15px;
}
.select__placeholder {
  color: #ADB8C6;
  font: normal 400 14px/18px var(--ff-field);
  letter-spacing: 0.3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.select__value {
  color: #343434;
  font: normal 400 14px/18px var(--ff-field);
  letter-spacing: 0.3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.select__value._multiple {
  display: flex;
  align-items: center;
}
.select__value-first {
  height: 24px;
  padding: 0px 18px;
  border-radius: 57px;
  background: #6AC4B3;
  color: var(--white-color);
  font-family: var(--ff-second);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.select__value-more {
  flex-shrink: 0;
  height: 24px;
  padding: 0px 18px;
  margin-left: 6px;
  border-radius: 57px;
  border: 1px dashed #6AC4B3;
  background: transparent;
  color: #6AC4B3;
  font-family: var(--ff-second);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.select__value-all {
  height: 24px;
  padding: 0px 18px;
  border-radius: 57px;
  border: 1px dashed #6AC4B3;
  background: transparent;
  color: #6AC4B3;
  font-family: var(--ff-second);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.select .btn {
  position: absolute;
  top: 50%;
  right: 11px;
  width: 32px;
  height: 32px;
  font-size: 11px;
  line-height: 32px;
  border-radius: 50%;
  translate: 0 -50%;
  transition: 0.15s all ease-in-out;
}
@media (max-width: 767.9px) {
  .select {
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    padding: 8px 28px 8px 10px;
    border-radius: 6px;
    font: normal 400 10px/14px var(--ff-field);
  }
  .select.search::before {
    left: 10px;
    font-size: 12px;
  }
  .select.location::before {
    font-size: 14px;
  }
  .select.staff::before {
    font-size: 20px;
  }
  .select.category::before {
    font-size: 20px;
  }
  .select.money::before {
    font-size: 17px;
  }
  .select.service::before {
    font-size: 14px;
  }
  .select._icon {
    padding: 8px 28px 8px 32px;
  }
  .select._filter {
    padding-left: 28px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
  }
  .select._filter::before {
    left: 8px;
  }
  .select._filter .select__placeholder {
    font-size: 12px;
    font-weight: 400;
  }
  .select._filter .select__value {
    font-size: 12px;
    font-weight: 400;
  }
  .select._filter .btn {
    right: 4px;
  }
  .select__placeholder {
    font-size: 10px;
    line-height: 16px;
  }
  .select__value {
    font-size: 10px;
    line-height: 16px;
  }
  .select__value-first {
    height: 22px;
    padding: 0 11px;
    font-size: 10px;
    line-height: 22px;
  }
  .select__value-more {
    height: 22px;
    padding: 0 11px;
    font-size: 10px;
    line-height: 22px;
  }
  .select__value-all {
    height: 22px;
    padding: 0 11px;
    font-size: 10px;
    line-height: 22px;
  }
  .select .btn {
    width: 24px;
    height: 24px;
    right: 4px;
    flex-shrink: 0;
    font-size: 10px;
    line-height: 24px;
  }
}

.select-picker {
  position: absolute;
  width: 250px;
  min-width: 200px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 0px 0px #A4A7AF1A, 0px 14px 32px 0px #A4A7AF1A, 0px 58px 58px 0px #A4A7AF17, 0px 130px 78px 0px #A4A7AF0D, 0px 230px 92px 0px #A4A7AF03, 0px 360px 101px 0px #A4A7AF00;
  background-color: white;
  overflow: hidden;
  box-sizing: border-box;
  font-family: var(--ff-second);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  z-index: 100;
}
.select-picker._searchable .select-list {
  margin-top: 0px;
}
.select-picker._clearable .select-list, .select-picker._multiple .select-list {
  margin-bottom: 0px;
}
.select-picker .select-list {
  width: 100%;
  max-height: 200px;
  margin-top: 6px;
  margin-bottom: 6px;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
}
.select-picker .select-list__empty {
  padding: 12px;
  color: #A5A5A5;
  font-weight: 400;
}
.select-picker .select-list__item {
  padding: 10px 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.15s all ease-in-out;
  color: #343434;
  cursor: pointer;
}
.select-picker .select-list__item:hover {
  background-color: #00000006;
}
.select-picker .select-list__item:active {
  background-color: #00000013;
}
.select-picker .select-list__item:last-child {
  margin-bottom: 0;
}
.select-picker .select-list__item._active {
  padding: 10px 15px;
  color: #6AC4B3;
}
.select-picker .select-list__item._disabled {
  cursor: default;
  color: #A5A5A5;
}
.select-picker .select-list__item._disabled:hover {
  background-color: transparent;
}
.select-picker .select-list__item.multiple {
  display: flex;
  align-items: center;
}
.select-picker .select-list__item .checkbox {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  border-radius: 3px;
  border: 1px solid #DBDFEE;
  background-color: var(--white-color);
  transition: 0.15s all ease-in-out;
}
.select-picker .select-list__item input:checked ~ .checkbox {
  background-color: #6AC4B3;
  border-color: #6AC4B3;
}
.select-picker .select-list__item input:checked ~ .checkbox::after {
  content: "\e5ca";
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--white-color);
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  transform: translate(-50%, -50%);
  transition: 0.15s all ease-in-out;
}
.select-picker .select-list__item-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.select-picker .select-search {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.select-picker .select-search::before {
  content: "\e914";
  position: absolute;
  left: 15px;
  top: 50%;
  font-size: 14px;
  color: #999999;
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, -50%);
}
.select-picker .select-search__input {
  width: 100%;
  height: 42px;
  min-height: 42px;
  max-height: 42px;
  padding: 10px 15px 10px 40px;
  border: none;
  background: transparent;
  color: #343434;
  font-family: var(--ff-field);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.3px;
  transition: 0.2s all ease-in-out;
}
.select-picker .select-search__input::placeholder {
  color: #A5A5A5;
  font-family: var(--ff-field);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.3px;
  transition: 0.2s all ease-in-out;
}
.select-picker .select-actions {
  display: flex;
  justify-content: flex-end;
  padding: 6px 6px 6px;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}
.select-picker .select-actions button {
  margin-left: 10px;
  border-radius: 4px;
  font-family: var(--ff-second);
  font-size: 14px;
  line-height: 36px;
}
.select-picker .select-actions button:first-child {
  margin-left: 0;
}
@media (max-width: 767.9px) {
  .select-picker .select-actions button {
    font-size: 12px;
    line-height: 32px;
  }
}
@media (max-width: 1024.9px) {
  .select-picker {
    position: relative;
    margin: 0;
    width: 65vh;
    max-height: 75vh;
  }
  .select-picker._searchable .select-list {
    max-height: calc(75vh - 50px - 42px);
  }
  .select-picker .select-list {
    max-height: calc(75vh - 50px);
  }
}
@media (max-width: 767.9px) {
  .select-picker {
    width: 80vw;
    max-height: 65vh;
    font-size: 12px;
  }
  .select-picker._searchable .select-list {
    max-height: calc(65vh - 50px - 42px);
  }
  .select-picker .select-list {
    max-height: calc(65vh - 50px);
  }
  .select-picker .select-list__item {
    padding: 7px 10px;
  }
  .select-picker .select-list__item._active {
    padding: 7px 10px;
  }
  .select-picker .select-list__item .checkbox {
    width: 20px;
    height: 20px;
  }
  .select-picker .select-list__item input:checked ~ .checkbox::after {
    font-size: 15px;
  }
  .select-picker .select-search::before {
    left: 10px;
    font-size: 12px;
  }
  .select-picker .select-search__input {
    padding: 8px 10px 8px 30px;
    font-size: 12px;
    line-height: 16px;
  }
  .select-picker .select-search__input::placeholder {
    font-size: 12px;
    line-height: 16px;
  }
  .select-picker .select-actions button {
    font-size: 12px;
    line-height: 32px;
  }
}

.select-option-user {
  margin: 6px 0px 6px 15px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
@media (max-width: 767.9px) {
  .select-option-user {
    margin: 5px 0px 5px 10px;
  }
}
.select-option-user__img {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
@media (max-width: 767.9px) {
  .select-option-user__img {
    width: 22px;
    height: 22px;
  }
}
.select-option-user__img .letter {
  font-size: 14px !important;
}
@media (max-width: 767.9px) {
  .select-option-user__img .letter {
    font-size: 12px !important;
  }
}
.select-option-user__name {
  margin-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media (max-width: 767.9px) {
  .select-option-user__name {
    margin-left: 6px;
  }
}

.timepicker-wrapp input {
  border: none;
  padding: 0;
  background: transparent;
}
.timepicker-wrapp button {
  padding: 0;
  border: none;
  background: none;
}
.timepicker-wrapp.req .timepicker__container {
  border-color: #E47878;
}
.timepicker-wrapp .req-text {
  margin-top: -4px;
  display: block;
  color: #E47878;
  font: normal 300 12px/15px var(--ff-second);
}
.timepicker-wrapp.disabled .timepicker__container {
  cursor: not-allowed;
}
.timepicker-wrapp.disabled .timepicker__container:hover {
  border-color: #F0F1F7;
}
.timepicker-wrapp.disabled .timepicker__container .btn, .timepicker-wrapp.disabled .timepicker__container input {
  cursor: not-allowed;
}

.timepicker__label {
  margin-bottom: 6px;
  display: block;
  color: #666666;
  font: normal 300 12px/18px var(--ff-second);
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
}

.timepicker__container {
  position: relative;
  width: 100%;
  min-width: 130px;
  height: 42px;
  padding: 11px 47px 11px 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #F0F1F7;
  background-color: #FFFFFF;
  transition: 0.15s all ease-in-out;
  overflow: hidden;
  cursor: pointer;
}
.timepicker__container:hover {
  border-color: #6AC4B3;
}
.timepicker__container .btn {
  position: absolute;
  top: 50%;
  right: 11px;
  width: 32px;
  height: 32px;
  font-size: 11px;
  line-height: 32px;
  border-radius: 50%;
  translate: 0 -50%;
  transition: 0.15s all ease-in-out;
}
.timepicker__container input {
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  width: calc(100% - 47px - 15px);
  color: #343434;
  font: normal 400 14px/18px var(--ff-field);
  letter-spacing: 0.3px;
  cursor: pointer;
}
.timepicker__container input::placeholder {
  color: #ADB8C6;
  font: normal 400 14px/18px var(--ff-field);
  letter-spacing: 0.3px;
  transition: 0.15s all ease-in-out;
}
.timepicker__container.focus {
  border-color: #6AC4B3;
}
@media (max-width: 767.9px) {
  .timepicker__container {
    min-width: 90px;
    height: 32px;
    padding: 8px 28px 8px 10px;
    border-radius: 6px;
  }
  .timepicker__container .btn {
    width: 24px;
    height: 24px;
    right: 4px;
    flex-shrink: 0;
    font-size: 10px;
    line-height: 24px;
  }
  .timepicker__container input {
    width: calc(100% - 28px - 10px);
    font: normal 400 10px/14px var(--ff-field);
  }
  .timepicker__container input::placeholder {
    font: normal 400 10px/14px var(--ff-field);
  }
}

._icon .timepicker__container {
  padding-left: 44px;
}
._icon .timepicker__container::before {
  content: "\e943";
  position: absolute;
  left: 10px;
  top: 50%;
  color: #D6D6DC;
  font-family: "icomoon" !important;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, -50%);
}
._icon .timepicker__container input {
  width: calc(100% - 42px - 44px);
}
@media (max-width: 767.9px) {
  ._icon .timepicker__container {
    padding-left: 36px;
  }
  ._icon .timepicker__container::before {
    font-size: 20px;
  }
  ._icon .timepicker__container input {
    width: calc(100% - 32px - 36px);
  }
}

@media (min-width: 768px) {
  ._left {
    padding-right: 10px;
  }

  ._right {
    padding-left: 10px;
  }
}
.timepicker__dropdown {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 0px 0px #A4A7AF1A, 0px 14px 32px 0px #A4A7AF1A, 0px 58px 58px 0px #A4A7AF17, 0px 130px 78px 0px #A4A7AF0D, 0px 230px 92px 0px #A4A7AF03, 0px 360px 101px 0px #A4A7AF00;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.timepicker__dropdown-time input {
  height: 42px;
  min-height: 42px;
  max-height: 42px;
  padding: 11px 15px;
  margin: 0;
  border-radius: 8px;
  border: 1px solid #F0F1F7;
  background-color: #FFFFFF;
  color: #343434;
  font: normal 400 14px/18px var(--ff-field);
  text-align: center;
  letter-spacing: 0.3px;
  transition: 0.15s all ease-in-out;
}
.timepicker__dropdown-time input:hover, .timepicker__dropdown-time input:focus {
  border-color: #6AC4B3;
}
.timepicker__dropdown-time input::placeholder {
  color: #ADB8C6;
  font: normal 400 14px/18px var(--ff-field);
  letter-spacing: 0.3px;
  transition: 0.15s all ease-in-out;
}
.timepicker__dropdown-time input::selection {
  background-color: #6AC4B3;
  color: var(--white-color);
}
.timepicker__dropdown-time-separator {
  margin: 0 10px;
  color: #343434;
  font: normal 400 14px/18px var(--ff-field);
  text-align: center;
  letter-spacing: 0.3px;
  user-select: none;
}
.timepicker__dropdown-actions {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.timepicker__dropdown-actions button {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.timepicker__dropdown-actions button .material-icons {
  font-size: 22px;
  line-height: 22px;
}
.timepicker__dropdown-period {
  min-width: 0 !important;
}
@media (max-width: 767.9px) {
  .timepicker__dropdown-time input {
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    padding: 8px 10px;
    border-radius: 6px;
    font: normal 400 10px/14px var(--ff-field);
  }
  .timepicker__dropdown-time input::placeholder {
    font: normal 400 10px/14px var(--ff-field);
  }
  .timepicker__dropdown-time-separator {
    margin: 0 8px;
    font: normal 400 10px/14px var(--ff-field);
  }
  .timepicker__dropdown-actions {
    margin: 0 8px;
  }
  .timepicker__dropdown-period {
    padding: 8px 10px !important;
    height: 32px !important;
    font-size: 12px !important;
    line-height: 16px !important;
  }
}

.navigation {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-column-gap: 30px;
  grid-row-gap: 16px;
  margin-bottom: 30px;
  transition: 0.2s all ease-in-out, 0s padding ease-in-out;
}
.navigation__topbar button {
  float: right;
}
.navigation__search {
  width: 100%;
  max-width: 300px;
}
.navigation__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px 10px;
  width: 100%;
}
.navigation__filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}
.navigation__filter-date {
  width: 100%;
  max-width: 175px;
  overflow: hidden;
}
.navigation__filter-type {
  width: 100%;
  max-width: 145px;
  overflow: hidden;
}
.navigation__btn button {
  transition: all 0.2s ease-in-out, border-radius 0s, min-width 0s, height 0s !important;
}
@media (min-width: 1025px) {
  .navigation.without_searching::before {
    content: "";
    display: block;
    width: 100%;
    max-width: 300px;
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .navigation.navShown {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }
  .navigation.navShown.without_filter.without_searching::before {
    display: none;
  }
  .navigation.navShown:not(.without_filter).without_searching, .navigation.navShown:not(.without_searching).without_filter, .navigation.navShown:not(.without_searching):not(.without_filter) {
    padding-top: 58px;
    position: relative;
  }
  .navigation.navShown:not(.without_filter).without_searching .navigation__tab, .navigation.navShown:not(.without_searching).without_filter .navigation__tab, .navigation.navShown:not(.without_searching):not(.without_filter) .navigation__tab {
    width: 100%;
    position: absolute;
    top: 0;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .navigation:not(.without_filter).without_searching, .navigation:not(.without_searching).without_filter, .navigation:not(.without_searching):not(.without_filter) {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding-top: 58px;
    position: relative;
  }
  .navigation:not(.without_filter).without_searching .navigation__tab, .navigation:not(.without_searching).without_filter .navigation__tab, .navigation:not(.without_searching):not(.without_filter) .navigation__tab {
    width: 100%;
    position: absolute;
    top: 0;
  }
}
@media (max-width: 1024.9px) {
  .navigation {
    display: flex;
    justify-content: center !important;
    gap: 16px;
  }
  .navigation__search {
    flex-shrink: 1;
  }
  .navigation__right {
    overflow: visible;
    width: auto;
  }
  .navigation__right:not(._filter) {
    position: absolute;
  }
  .navigation__filter-date {
    width: 175px;
  }
  .navigation__filter-type {
    width: 145px;
  }
}
@media (max-width: 767.9px) {
  .navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
    padding-top: 0 !important;
  }
  .navigation__tab {
    width: 100%;
  }
  .navigation__search {
    max-width: none;
  }
  .navigation__filter {
    gap: 16px;
  }
  .navigation__filter-type, .navigation__filter-date {
    width: 50% !important;
    max-width: none;
  }
  .navigation__filter-daterange {
    flex-grow: 1;
  }
  .navigation__right {
    width: 100%;
    gap: 10px;
  }
  .navigation__right:not(._filter) {
    position: absolute;
  }
  .navigation__right._filter {
    flex-direction: row;
    justify-content: center;
  }
}
@media (max-width: 575.9px) {
  .navigation__right._filter {
    align-items: flex-end;
    flex-direction: column;
  }
  .navigation__filter {
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 1024.9px) {
  .navigation__btn {
    position: fixed;
    right: 24px;
    bottom: 24px;
    z-index: 100;
    transition: all 0.3s ease-in-out;
  }
  .navigation__btn button {
    transition: border-radius 0s;
    width: 56px !important;
    min-width: 0 !important;
    height: 56px !important;
    padding: 0 !important;
    border-radius: 50% !important;
    font-size: 25px !important;
  }
  .navigation__btn button .icon-plus {
    margin-right: 0 !important;
  }
  .navigation__btn button .icon-plus + * {
    display: none;
  }
  .navigation__btn._hide {
    bottom: -56px !important;
    opacity: 0 !important;
  }

  .hideOnScroll {
    transition: all 0.3s ease-in-out;
  }
  .hideOnScroll._hide {
    bottom: -56px !important;
    opacity: 0 !important;
  }
}
.mat-tooltip {
  font-family: var(--ff-second);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
  background-color: color-mix(in srgb, #343434 95%, transparent);
}
.mat-tooltip.tableTooltip {
  margin-bottom: -5px !important;
}
.mat-tooltip.tableTooltip.mat-tooltip-handset {
  margin-bottom: 5px !important;
}
.mat-tooltip.fileTooltip {
  word-break: break-all;
  margin-top: 6px !important;
  z-index: 1100;
}

.mat-paginator {
  background-color: transparent;
  color: #444444;
}
.mat-paginator .mat-paginator-container {
  min-height: 40px;
}
.mat-paginator .mat-paginator-range-actions .mat-paginator-range-label {
  font-family: var(--ff-base);
  margin-left: 0;
}
@media (max-width: 767.9px) {
  .mat-paginator .mat-paginator-range-actions {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .mat-paginator .mat-paginator-range-actions .mat-paginator-range-label {
    width: 100%;
    margin: 10px 0 0;
    text-align: center;
  }
}

.mat-menu-panel {
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 0px 0px #A4A7AF1A, 0px 14px 32px 0px #A4A7AF1A, 0px 58px 58px 0px #A4A7AF17, 0px 130px 78px 0px #A4A7AF0D, 0px 230px 92px 0px #A4A7AF03, 0px 360px 101px 0px #A4A7AF00;
  background-color: white;
}
.mat-menu-panel:not(.m0) {
  margin: 10px 0;
}
.mat-menu-panel .mat-menu-item {
  color: #444444 !important;
  font-family: var(--ff-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.1s ease-in-out;
}
.mat-menu-panel .mat-menu-item[disabled] {
  opacity: 0.38;
}
.mat-menu-panel .mat-menu-item.matMenu__item {
  display: flex;
  align-items: center;
}
.mat-menu-panel .mat-menu-item.matMenu__item .matMenu__item-icon {
  margin-right: 14px;
  font-size: 16px;
  color: #444444 !important;
}
.mat-menu-panel .mat-menu-item.matMenu__item .matMenu__item-icon:before {
  color: #444444 !important;
}
.mat-menu-panel .mat-menu-item.matMenu__item .matMenu__item-icon[class^=material-icons-], .mat-menu-panel .mat-menu-item.matMenu__item .matMenu__item-icon[class*=" material-icons-"], .mat-menu-panel .mat-menu-item.matMenu__item .matMenu__item-icon.material-icons {
  margin-left: -2px;
  margin-right: 12px;
  font-size: 20px;
}
.mat-menu-panel .mat-menu-item > span:first-child {
  position: relative;
  z-index: 1;
}
@media (max-width: 1024.9px) {
  .mat-menu-panel {
    width: 100% !important;
    max-width: 100% !important;
    margin: 10px !important;
  }
}
@media (max-width: 767.9px) {
  .mat-menu-panel .mat-menu-item {
    font-size: 14px;
  }
  .mat-menu-panel .mat-menu-item.matMenu__item .matMenu__item-icon {
    margin-right: 10px;
    font-size: 14px;
  }
  .mat-menu-panel .mat-menu-item.matMenu__item .matMenu__item-icon[class^=material-icons-], .mat-menu-panel .mat-menu-item.matMenu__item .matMenu__item-icon[class*=" material-icons-"], .mat-menu-panel .mat-menu-item.matMenu__item .matMenu__item-icon.material-icons {
    margin-right: 8px;
    font-size: 18px;
  }
}

.matMenu__header {
  padding: 15px 0;
}
.matMenu__header-img {
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  width: 80px;
  height: 80px;
  margin: 0 auto 5px;
  border-radius: 50%;
}
.matMenu__header-img ::ng-deep picture .letter {
  font-size: 40px;
}
.matMenu__header-name {
  color: #444444;
  font-family: var(--ff-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.matMenu__header-date {
  margin-top: 5px;
  color: #444444;
  font-family: var(--ff-base);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
@media (min-width: 1025px) {
  .matMenu__header {
    display: none;
  }
}

@media (max-width: 1024.9px) {
  .menuBackdrop, .menuBackdrop_small {
    background: #0000007A !important;
  }
  .menuBackdrop + .cdk-overlay-connected-position-bounding-box, .menuBackdrop_small + .cdk-overlay-connected-position-bounding-box {
    right: 0px !important;
    left: 0px !important;
    bottom: 0px !important;
    top: 0px !important;
    height: 100% !important;
    width: 100% !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }
  .menuBackdrop + .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane, .menuBackdrop_small + .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    position: static !important;
    width: 100% !important;
  }

  .menuBackdrop + .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    max-width: 500px !important;
  }

  .menuBackdrop_small + .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    max-width: 300px !important;
  }
}
.table-container.week {
  position: relative;
  overflow: auto;
}
@media (min-width: 1025px) {
  .table-container.week._empty .table {
    overflow: hidden;
  }
}
.table-container.week._empty {
  overflow: visible;
}
.table-container.week._empty .table tbody tr.empty td {
  display: block;
}
.table-container.week .table thead th {
  padding-bottom: 8px;
}
.table-container.week .table thead th:first-child {
  background-color: #EBEFEE;
  height: 1px;
}
.table-container.week .table thead th:first-child > div {
  height: 100%;
}
.table-container.week .table thead th:last-child {
  background-color: #EBEFEE;
}
.table-container.week .table tbody td.table__working-hours:not(._off) {
  cursor: pointer;
}
.table-container.week .table tbody td.table__working-hours:not(._off) > span {
  line-height: 1.5;
  position: relative;
  z-index: 1;
}
.table-container.week .table tbody td.table__working-hours._off {
  background-color: #E9EBEF;
  border-right: 2px solid var(--white-color);
}
.table-container.week .table tbody td.table__working-hours._off::before {
  background-color: var(--white-color);
}
.table-container.week .table tbody td.table__working-hours._add {
  padding: 0 !important;
}
.table-container.week .table tbody td.table__working-hours._add > span {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
}
@media (min-width: 1025px) {
  .table-container.week .table thead th:first-child, .table-container.week .table tbody td:first-child {
    position: sticky;
    width: 230px;
    left: 0px;
    z-index: 3;
  }
  .table-container.week .table thead th:not(:first-child):not(:last-child), .table-container.week .table tbody td:not(:first-child):not(:last-child) {
    width: 140px;
  }
  .table-container.week .table thead th:last-child, .table-container.week .table tbody td:last-child {
    position: sticky;
    width: 80px;
    right: 0px;
    z-index: 3;
  }
  .table-container.week .table tbody td:first-child, .table-container.week .table tbody td:last-child {
    transform-style: preserve-3d;
  }
  .table-container.week .table tbody td:first-child::before, .table-container.week .table tbody td:last-child::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 15px;
    height: 100%;
    background-color: #EBEFEE;
    transform: translateZ(-1px);
  }
  .table-container.week .table tbody td:first-child {
    border-right: 1px solid #E9EBEF;
  }
  .table-container.week .table tbody td:first-child::before {
    left: 0;
  }
  .table-container.week .table tbody td:last-child {
    border-left: 1px solid #E9EBEF;
  }
  .table-container.week .table tbody td:last-child::before {
    right: 0;
  }
  .table-container.week .table tbody td:nth-last-child(2).table__working-hours {
    border-right: none !important;
  }
  .table-container.week .table tbody td.table__working-hours {
    font-weight: 500;
    border-right: 1px solid #E9EBEF;
  }
  .table-container.week .table tbody td.table__working-hours > span {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
    display: block;
  }
  .table-container.week .table tbody td.table__working-hours._add {
    height: 1px;
  }
  .table-container.week .table tbody td.table__working-hours._add:hover span {
    opacity: 1;
  }
  .table-container.week .table tbody td.table__working-hours._add span {
    opacity: 0;
  }
}
@media (min-width: 1750px) {
  .table-container.week .table tbody td.table__working-hours {
    padding: 20px;
  }
}
@media (max-width: 1024.9px) {
  .table-container.week .table tbody td {
    display: flex;
    flex-direction: column;
  }
  .table-container.week .table tbody td::before {
    flex-shrink: 0;
    z-index: 1;
    position: relative;
  }
  .table-container.week .table tbody td:first-child {
    overflow: hidden;
  }
  .table-container.week .table tbody td:first-child::before {
    padding-bottom: 26px !important;
  }
  .table-container.week .table tbody td .table__user {
    height: 100%;
  }
  .table-container.week .table tbody tr {
    grid-template-rows: min-content auto !important;
  }
  .table-container.week .table tbody tr > td.table__working-hours {
    left: -5px;
    width: calc(100% + 10px) !important;
  }
  .table-container.week .table tbody tr > td.table__working-hours::before, .table-container.week .table tbody tr > td.table__working-hours::after {
    padding-left: 5px;
  }
  .table-container.week .table tbody tr > td.table__working-hours > span {
    margin-left: 5px;
  }
  .table-container.week .table tbody td.table__working-hours {
    position: relative;
  }
  .table-container.week .table tbody td.table__working-hours::before {
    padding-bottom: 30px !important;
    font-size: 10px !important;
  }
  .table-container.week .table tbody td.table__working-hours::after {
    content: attr(data-day);
    position: absolute;
    top: 20px;
    color: #999;
    font-family: var(--ff-second);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    z-index: 1;
  }
  .table-container.week .table tbody td.table__working-hours > span {
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2px;
    overflow: hidden;
  }
  .table-container.week .table tbody td.table__working-hours._off > span {
    margin: 15px 5px;
  }
  .table-container.week .table tbody td.table__working-hours._add > span {
    justify-content: flex-start;
  }
}

.table-container.month {
  margin-top: 0;
  margin-bottom: 0;
}
.table-container.month .table {
  border-spacing: 0px;
}
.table-container.month .table thead th {
  padding-bottom: 16px;
  color: #999;
  font-weight: 400;
}
.table-container.month .table thead th:last-child, .table-container.month .table tbody td:last-child {
  width: auto !important;
}
@media (min-width: 1025px) {
  .table-container.month .table thead th:first-child {
    padding-left: 8px !important;
  }
  .table-container.month .table thead th:last-child {
    padding-right: 8px !important;
  }
  .table-container.month .table tbody {
    border-radius: 10px;
    box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.14);
  }
  .table-container.month .table tbody tr:last-child td {
    border-bottom: none;
  }
  .table-container.month .table tbody td {
    padding: 8px;
    height: 200px;
    border-radius: 0px;
    border-bottom: 1px solid #E9EBEF;
  }
  .table-container.month .table tbody td:not(:last-child) {
    border-right: 1px solid #E9EBEF;
  }
  .table-container.month .table tbody tr {
    box-shadow: none !important;
  }
  .table-container.month .table tbody tr:first-child td:first-child {
    border-top-left-radius: 10px;
  }
  .table-container.month .table tbody tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }
  .table-container.month .table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  .table-container.month .table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
}
@media (max-width: 1024.9px) {
  .table-container.month .table thead {
    display: none;
  }
  .table-container.month .table tbody {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
  }
  .table-container.month .table tbody tr {
    padding: 0 !important;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    box-shadow: none !important;
    background: transparent;
  }
  .table-container.month .table tbody tr td {
    height: auto;
    padding: 10px 15px !important;
    border-radius: 10px !important;
    box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.14) !important;
    overflow: hidden;
  }
  .table-container.month .table tbody tr td::before {
    display: none !important;
  }
  .table-container.month .table tbody tr td._not-this-month {
    display: none;
  }
}
.table-container.month .day {
  height: 100%;
}
.table-container.month .day__header {
  position: relative;
  padding: 4px 4px 10px;
}
.table-container.month .day__header-date {
  color: #444444;
  font-family: var(--ff-second);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.table-container.month .day__header-menu-btn {
  position: absolute;
  top: -1px;
  right: -4px;
  color: #999999;
  width: 30px;
  height: 30px;
  line-height: 30px;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}
.table-container.month .day__header-menu-btn .material-icons {
  font-size: 22px !important;
}
.table-container.month .day__header-staff {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}
.table-container.month .day__header-staff__img {
  min-width: 34px;
  min-height: 34px;
  max-width: 34px;
  max-height: 34px;
  width: 34px;
  height: 34px;
}
.table-container.month .day:hover .day__header-menu-btn {
  opacity: 1;
}
.table-container.month .day__showAll {
  width: 100%;
  padding: 0 16px;
  font-family: var(--ff-base);
  font-size: 14px;
  font-weight: 400;
  line-height: 28px !important;
}
@media (max-width: 1024.9px) {
  .table-container.month .day__header {
    padding: 0px;
    cursor: pointer;
  }
  .table-container.month .day__header-date {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .table-container.month .day__header-date::after {
    content: attr(data-day);
    display: block;
    padding-top: 6px;
    color: #999999;
    font-family: var(--ff-second);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
  }
  .table-container.month .day__header-open-btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 11px;
    transition: all 0.2s ease-in-out;
  }
}
@media (max-width: 767.9px) {
  .table-container.month .day__header-staff__img {
    min-width: 28px;
    min-height: 28px;
    max-width: 28px;
    max-height: 28px;
    width: 28px;
    height: 28px;
  }
}

.mb18 {
  margin-bottom: 18px;
}

.pb18 {
  padding-bottom: 18px;
}

.profile {
  overflow-y: auto;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 20px;
  align-items: flex-start;
  overflow: visible;
}
@media (max-width: 1024.9px) {
  .profile {
    grid-template-columns: 1fr;
  }
}

.profile-bar {
  background: var(--white-color);
  box-shadow: 0px 4px 4px rgba(51, 77, 110, 0.25);
  border-radius: 35px;
  padding: 25px 35px 40px;
  position: relative;
  margin-bottom: 20px;
}
@media (max-width: 1024.9px) {
  .profile-bar {
    width: 100%;
    margin-bottom: 20px;
  }
}
.profile-bar__created {
  font-size: 12px;
  line-height: 16px;
  color: #7B808B;
}
.profile-bar__created__date {
  font-weight: 500;
  color: #666666;
}
.profile-bar__more {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 25px;
  display: flex;
  flex-direction: column;
}
.profile-bar__more span {
  width: 5px;
  height: 5px;
  display: block;
  background: var(--grey-color);
  border-radius: 50%;
  margin-bottom: 5px;
}
.profile-bar__more span:last-child {
  margin-right: 0;
}
.profile-bar__more__popup {
  position: absolute;
  background: var(--white-color);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 10px;
  width: 95px;
  right: 0;
  z-index: 11;
  top: 20px;
  opacity: 0;
  transition: 0.3s all ease-in-out;
  transform: scale(0);
}
.profile-bar__more__popup .popup__link {
  display: block;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--blue-color);
}
.profile-bar__more__popup .popup__link:last-child {
  margin-bottom: 0;
}
.profile-bar__more__popup .popup__link._danger {
  padding-top: 10px;
  border-top: 0.5px solid var(--grey-color);
  letter-spacing: 0.01em;
  color: #C0392B;
}
.profile-bar__more__popup._active {
  transition: 0.3s all ease-in-out;
  opacity: 1;
  transform: scale(1);
}
.profile-bar__photo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  padding: 10px;
  margin-top: 40px;
}
.profile-bar__photo._letter {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
  font-size: 35px;
  color: var(--sky-color);
}
.profile-bar__photo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.profile-bar__photo__label {
  position: absolute;
  display: block;
  left: 34px;
  bottom: -4px;
  background: var(--sky-color);
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--white-color);
}
.profile-bar__name {
  margin: 20px 0 14px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
}
.profile-bar__newEvent {
  text-align: center;
  margin-bottom: 28px;
}
.profile-bar__info__item {
  padding-bottom: 20px;
  border-bottom: 0.5px solid var(--grey-color);
  margin-bottom: 20px;
}
.profile-bar__info__item:last-child {
  border-bottom: none;
}
.profile-bar__info__header {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
  margin-bottom: 5px;
}
.profile-bar__info__text {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #979797;
}

.profile-container {
  flex-grow: 1;
}
@media (max-width: 1024.9px) {
  .profile-container {
    width: 100%;
    padding-left: 0;
  }
}

.profile-main {
  padding-bottom: 20px;
}

.profile-topsection {
  display: flex;
}
@media (max-width: 1439.9px) {
  .profile-topsection {
    flex-direction: column;
  }
  .profile-topsection .sales {
    width: 100%;
  }
  .profile-topsection .link {
    padding-left: 0;
    margin-top: 20px;
  }
}

.sales {
  width: 440px;
  text-align: center;
  background: var(--white-color);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 30px 20px;
}
.sales__total {
  width: 50%;
  padding-bottom: 30px;
  border-right: 0.5px solid var(--grey-color);
}
.sales__number {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--blue-color);
  margin-bottom: 5px;
}
.sales__title {
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
}
.sales__outstanding {
  width: 50%;
  padding-bottom: 30px;
}

.sales-top {
  display: flex;
  border-bottom: 0.5px solid var(--grey-color);
}

.sales-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1024.9px) {
  .sales-bottom {
    flex-direction: column;
  }
  .sales-bottom > div {
    margin-bottom: 10px;
  }
  .sales-bottom > div:last-child {
    margin-bottom: 0;
  }
}

.link {
  padding-left: 20px;
  flex-grow: 1;
}

.link-item {
  background: var(--white-color);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  cursor: pointer;
}
.link-item:last-child {
  margin-bottom: 0;
}
.link-item__add {
  margin-right: 20px;
  font-size: 12px;
  text-decoration: underline;
  color: var(--sky-color);
}
.link-item__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--blue-color);
}

.profile-event {
  background: var(--white-color);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 20px;
  margin-top: 20px;
}

.event-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 23px;
  margin-top: 20px;
}
.event-title__type {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--blue-color);
}

.event-row {
  position: relative;
  background: var(--white-color);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  padding-right: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}
@media (max-width: 1024.9px) {
  .event-row {
    padding-right: 5px;
  }
}
.event-row:last-child {
  margin-bottom: 0;
}
.event-row__color {
  position: absolute;
  width: 6px;
  height: 100%;
  background: var(--green-color);
  border-radius: 5px 0px 0px 5px;
}
.event-row__body {
  display: flex;
}
.event-row__date {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30px;
  max-width: 30px;
  min-width: 30px;
  margin-left: 16px;
  margin-right: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--blue-color);
}
@media (max-width: 1024.9px) {
  .event-row__date {
    position: absolute;
    right: 5px;
    top: 2px;
    font-size: 10px;
    width: auto;
    max-width: 100%;
    min-width: auto;
    margin-right: 0;
  }
}
@media (max-width: 1024.9px) {
  .event-row__title {
    padding-left: 10px;
  }
}
.event-row__title__top {
  display: flex;
  margin-bottom: 5px;
}
.event-row__title__name {
  word-break: break-word;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
  margin-right: 15px;
}
@media (max-width: 1024.9px) {
  .event-row__title__name {
    font-size: 12px;
  }
}
.event-row__title__status {
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: var(--grey-color);
}
@media (max-width: 1024.9px) {
  .event-row__title__status {
    position: absolute;
    bottom: 2px;
    right: 5px;
  }
}
.event-row__title__bottom {
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
}
@media (max-width: 1024.9px) {
  .event-row__title__bottom {
    font-size: 12px;
  }
}
.event-row__price {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
  min-width: fit-content;
}
@media (max-width: 1024.9px) {
  .event-row__price {
    font-size: 12px;
    padding: 5px 0;
    width: 70px;
    text-align: end;
    min-width: 70px;
  }
}

.icon-chevron {
  display: block;
  transition: 0.3s all ease-in-out;
}
.icon-chevron._active {
  transition: 0.3s all ease-in-out;
  transform: rotate(90deg);
}

.profile-section {
  height: 100%;
  background: var(--white-color);
  box-shadow: 0px 4px 4px rgba(51, 77, 110, 0.25);
  border-radius: 35px;
  padding: 25px;
}

.profile-link {
  text-align: center;
}
.profile-link__nav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5px;
}
.profile-link__nav__back {
  justify-self: start;
  cursor: pointer;
  transform: rotate(180deg);
}
.profile-link__nav__back > * {
  line-height: 42px;
}
.profile-link__nav__title {
  color: #343434;
  font-family: var(--ff-base);
  font-size: 24px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  justify-self: center;
}
.profile-link__nav__btn {
  justify-self: end;
  border-radius: 10px !important;
}
@media (max-width: 767.9px) {
  .profile-link__nav__back > * {
    line-height: 32px;
  }
  .profile-link__nav__title {
    line-height: 32px;
  }
}

.profile-files {
  overflow: hidden;
  user-select: none;
}
.profile-files__path {
  margin-top: 20px;
  margin-left: -10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.profile-files__path-directory, .profile-files__path-separator {
  color: #666666;
  font-family: var(--ff-base);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0px;
}
.profile-files__path-directory {
  min-width: 0px !important;
  padding: 0 10px !important;
  border-radius: 10px !important;
}
.profile-files__path-directory._selected {
  color: #343434;
}
.profile-files__path-separator {
  margin: 0 2px;
}
.profile-files__body {
  margin: 0 -15px -15px;
  padding: 15px;
}
.profile-files__body._dragActive {
  position: relative;
  overflow: hidden;
}
.profile-files__section {
  margin-bottom: 15px;
  color: #343434;
  font-family: var(--ff-base);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.profile-files__section:nth-of-type(2) {
  margin-top: 20px;
}
.profile-files__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 15px;
  justify-items: center;
}
.profile-files__drag {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: color-mix(in srgb, #F1FBF9 70%, transparent);
  border: 2px solid #6AC4B3;
  border-radius: 25px;
}
.profile-files__item {
  width: 100%;
  max-width: 100%;
  min-width: 0;
  position: relative;
  padding: 8px;
  background-color: var(--white-color);
  border-radius: 10px;
  border: 1px solid #EBEFEE;
  overflow: hidden;
  cursor: pointer;
  transition: 0.15s all ease-in-out;
}
.profile-files__item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.profile-files__item__container {
  margin-top: 8px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2px;
  overflow: hidden;
}
.profile-files__item__container.img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-files__item__container.file {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-files__item__container.file__icon {
  position: relative;
  height: 100px;
  width: 75px;
  border-radius: 8px;
  background-color: #D6D6DC;
  clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 30px, 100% 100%, 0 100%);
  color: white;
  font-family: var(--ff-base);
  font-size: 18px;
  font-weight: 700;
  line-height: 130px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
}
.profile-files__item__container.file__icon::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 30px;
  height: 30px;
  background-color: color-mix(in srgb, #D6D6DC 40%, white);
  border-bottom-left-radius: 8px;
}
.profile-files__item__container.file__icon.blue {
  background-color: #45C2E6;
}
.profile-files__item__container.file__icon.blue::after {
  background-color: color-mix(in srgb, #45C2E6 40%, white);
}
.profile-files__item__container.file__icon.red {
  background-color: #E47878;
}
.profile-files__item__container.file__icon.red::after {
  background-color: color-mix(in srgb, #E47878 40%, white);
}
.profile-files__item__container.file__icon.green {
  background-color: #6AC4B3;
}
.profile-files__item__container.file__icon.green::after {
  background-color: color-mix(in srgb, #6AC4B3 40%, white);
}
.profile-files__item__container.file__icon.yellow {
  background-color: #FFC300;
}
.profile-files__item__container.file__icon.yellow::after {
  background-color: color-mix(in srgb, #FFC300 40%, white);
}
.profile-files__item__head {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.profile-files__item__head-title {
  flex-grow: 1;
  margin-top: 2px;
  margin-bottom: 2px;
  color: #343434;
  font-family: var(--ff-base);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
}
.profile-files__item__head-more {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  color: #444444;
}
.profile-files__item__head-more [class^=material-icons-], .profile-files__item__head-more [class*=" material-icons-"], .profile-files__item__head-more .material-icons {
  font-size: 22px !important;
}
.profile-files__item__head-icon {
  color: color-mix(in srgb, #6AC4B3 70%, white);
  margin-right: 10px;
}
.profile-files__empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 200px;
  padding: 15px 25px;
  border-radius: 10px;
  text-align: center;
}
.profile-files__empty-icon {
  margin-bottom: 10px;
  color: color-mix(in srgb, #6AC4B3 70%, white);
  font-size: 40px;
}
.profile-files__empty-text {
  color: #444444;
  font-family: var(--ff-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.file-preview-backdrop {
  background-color: #000000ba;
}
.file-preview-backdrop + .cdk-overlay-connected-position-bounding-box {
  padding: 70px 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1024.9px) {
  .file-preview-backdrop + .cdk-overlay-connected-position-bounding-box {
    padding: 60px 30px 20px;
  }
}
@media (max-width: 767.9px) {
  .file-preview-backdrop + .cdk-overlay-connected-position-bounding-box {
    padding: 50px 20px 10px;
  }
}

.file-preview-panel {
  position: static !important;
  margin: 0 auto !important;
  user-select: none;
}
.file-preview-panel .file-preview__head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  padding: 15px 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  pointer-events: none;
  z-index: 10;
  transition: 0.2s background ease-in-out;
}
.file-preview-panel .file-preview__head.background {
  background-color: #0000007A;
}
.file-preview-panel .file-preview__head__group {
  display: flex;
  align-items: center;
  gap: 10px;
  pointer-events: auto;
}
.file-preview-panel .file-preview__head__group:first-of-type {
  overflow: hidden;
}
.file-preview-panel .file-preview__head__group:first-of-type.zoom {
  max-width: calc(50% - 55px);
}
.file-preview-panel .file-preview__head__group:first-of-type.zoom + * {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.file-preview-panel .file-preview__head__group:nth-child(2), .file-preview-panel .file-preview__head__group:nth-child(3) {
  flex-shrink: 0;
}
.file-preview-panel .file-preview__head-name {
  font-family: var(--ff-base);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}
.file-preview-panel .file-preview__head button[disabled] {
  color: rgba(255, 255, 255, 0.36) !important;
}
.file-preview-panel .file-preview__img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 4px;
  overflow: hidden;
  visibility: hidden;
  transition: 0.1s max-width ease;
}
.file-preview-panel .file-preview__img img {
  max-width: 100%;
  max-height: 100%;
}
.file-preview-panel .file-preview__pdf {
  width: calc(100% + 300px);
  height: calc(100% + 140px);
  margin: -70px -150px 0;
}
.file-preview-panel .file-preview__pdf pdf-viewer {
  width: 100%;
  height: 100%;
}
.file-preview-panel .file-preview__pdf pdf-viewer .ng2-pdf-viewer-container {
  padding-top: 70px;
}
.file-preview-panel .file-preview__pdf pdf-viewer .page, .file-preview-panel .file-preview__pdf pdf-viewer .page .canvasWrapper {
  border-radius: 4px;
}
.file-preview-panel .file-preview__txt {
  width: calc(100% + 300px);
  padding: 70px 150px 0;
  margin: -70px -150px -70px;
  overflow-y: auto;
  min-height: 100%;
}
.file-preview-panel .file-preview__txt__content {
  width: 100%;
  height: auto;
  min-height: calc(100% - 70px);
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: white;
}
.file-preview-panel .file-preview__txt__content pre {
  margin: 0;
  font-family: var(--ff-field);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.file-preview-panel .file-preview__does-not-open {
  color: white;
  font-family: var(--ff-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.3px;
}
@media (max-width: 1024.9px) {
  .file-preview-panel .file-preview__head {
    height: 60px;
    padding: 10px 15px;
  }
  .file-preview-panel .file-preview__head__group {
    gap: 8px;
  }
  .file-preview-panel .file-preview__pdf {
    width: calc(100% + 60px);
    height: calc(100% + 60px + 20px);
    margin: -60px -30px 0;
  }
  .file-preview-panel .file-preview__pdf pdf-viewer .ng2-pdf-viewer-container {
    padding-top: 60px;
  }
  .file-preview-panel .file-preview__txt {
    width: calc(100% + 60px);
    padding: 60px 30px 0;
    margin: -60px -30px -20px;
  }
  .file-preview-panel .file-preview__txt__content {
    min-height: calc(100% - 20px);
  }
}
@media (max-width: 767.9px) {
  .file-preview-panel .file-preview__head {
    height: 52px;
    padding: 10px 10px;
    gap: 5px;
  }
  .file-preview-panel .file-preview__head__group {
    gap: 5px;
  }
  .file-preview-panel .file-preview__head__group:first-of-type.zoom {
    max-width: none;
  }
  .file-preview-panel .file-preview__head__group:first-of-type.zoom + * {
    position: fixed;
    bottom: 10px;
    gap: 10px;
    background-color: #0000007A;
    border-radius: 16px;
  }
  .file-preview-panel .file-preview__head button {
    width: 32px !important;
    height: 32px !important;
    line-height: 32px !important;
  }
  .file-preview-panel .file-preview__head button [class^=material-icons-], .file-preview-panel .file-preview__head button [class*=" material-icons-"], .file-preview-panel .file-preview__head button .material-icons {
    font-size: 22px !important;
  }
  .file-preview-panel .file-preview__does-not-open {
    margin-bottom: 20px;
    margin-right: 15px;
    font-size: 14px;
  }
  .file-preview-panel .file-preview__pdf {
    width: calc(100% + 40px);
    height: calc(100% + 50px + 10px);
    margin: -50px -20px 0;
  }
  .file-preview-panel .file-preview__pdf pdf-viewer .ng2-pdf-viewer-container {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .file-preview-panel .file-preview__txt {
    width: calc(100% + 40px);
    padding: 50px 20px 0;
    margin: -50px -20px -10px;
  }
  .file-preview-panel .file-preview__txt__content {
    min-height: calc(100% - 10px);
    padding: 15px;
    margin-bottom: 10px;
  }
  .file-preview-panel .file-preview__txt__content pre {
    font-size: 12px;
  }
}

.folder-tree {
  overflow: hidden;
}
.folder-tree__location {
  flex-shrink: 0;
  margin-right: 12px;
}
.folder-tree__toggle {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  color: #444444;
}
.folder-tree__toggle [class^=material-icons-], .folder-tree__toggle [class*=" material-icons-"], .folder-tree__toggle .material-icons {
  font-size: 22px !important;
}
.folder-tree__item {
  min-width: 0px !important;
  max-width: 100%;
  padding: 0 10px !important;
  border-radius: 10px !important;
  border: 1px solid transparent !important;
  color: #343434;
  font-family: var(--ff-base);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  overflow: hidden !important;
}
.folder-tree__item._active {
  background-color: color-mix(in srgb, #6AC4B3 15%, transparent);
  border-color: #6AC4B3 !important;
}
.folder-tree__item._current {
  border-color: #EBEFEE !important;
}
.folder-tree__item [class^=material-icons-], .folder-tree__item [class*=" material-icons-"], .folder-tree__item .material-icons {
  color: color-mix(in srgb, #6AC4B3 70%, white);
  margin-right: 10px;
  line-height: 14px;
}
.folder-tree__item-text {
  color: #343434;
}
.folder-tree__item[disabled] {
  pointer-events: none;
}
.folder-tree__item[disabled] [class^=material-icons-], .folder-tree__item [class*=" material-icons-"], .folder-tree__item .material-icons {
  color: color-mix(in srgb, #6AC4B3 30%, white);
}
.folder-tree__item[disabled] .folder-tree__item-text {
  color: color-mix(in srgb, #343434 50%, white);
}

.profile-notes {
  padding: 0;
  margin-bottom: 20px;
}
.profile-notes .profile-link__nav {
  padding: 20px;
}
.profile-notes .profile-link__nav__back {
  left: 20px;
  top: 20px;
}

.question {
  margin-top: 24px;
}

.question-item {
  background: var(--white-color);
  box-shadow: 0px 3px 10px rgba(124, 125, 125, 0.15);
  border-radius: 35px;
  padding: 20px 35px;
  margin-bottom: 20px;
  position: relative;
}
.question-item__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.question-item__title__author {
  padding-right: 50px;
  color: #979797;
}
.question-item__title__left {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: var(--blue-color);
}
.question-item__title__right {
  position: absolute;
  right: 0;
  top: 130%;
  transform: translateY(-50%);
}
.question-item__title__right._active .icon-chevron {
  transform: rotate(90deg);
}
.question-item__body {
  margin-top: 18px;
}
.question-item__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #979797;
  line-height: 16px;
  letter-spacing: 0.01em;
  border-bottom: 1px dashed #E0E0E0;
}
.question-item__row:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.question-item__row__title {
  display: flex;
  width: 60%;
  word-break: break-word;
}
.question-item__row__result {
  border: 1px solid var(--sky-color);
  background-color: var(--white-color);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  word-break: break-word;
  text-align: right;
  position: relative;
  background-color: #68C7B3;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: none;
}

.result-checkbox {
  font-size: 1px;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.result-checkbox._checkbox {
  content: "";
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 6px;
  height: 10px;
  border: solid var(--white-color);
  border-width: 0 2px 2px 0;
}
.result-checkbox._radio {
  background: white;
  border-radius: 50%;
}

.cl-add {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.cl-add__first {
  padding-top: 76px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 575.9px) {
  .cl-add__first {
    padding-right: 0;
    padding-top: 20px;
  }
}
.cl-add__first button {
  margin-top: 20px;
  min-width: 150px;
  display: inline-block;
  font-weight: bold;
  font-family: var(--ff-base);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: none;
  padding: 0.375rem 0.75rem;
  color: var(--white-color);
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 1.25px;
  background-color: var(--sky-color);
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: var(--sky-color);
  transition: 0.2s ease-in-out;
}
.cl-add__first button:hover {
  background-color: var(--skyL-color);
  transition: 0.2s ease-in-out;
}
.cl-add__img {
  display: block;
  width: 152px;
  height: 152px;
  border-radius: 50%;
  cursor: pointer;
}
.cl-add__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.cl-add__title {
  margin: 20px 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--blue-color);
}
.cl-add__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (max-width: 583px) {
  .cl-add__row {
    justify-content: center;
  }
  .cl-add__row .waw-input:first-child {
    margin-right: 0 !important;
  }
  .cl-add__row .waw-select {
    margin-bottom: 20px;
  }
}
.cl-add__row .cl-add__gender {
  max-width: 236.4px;
  width: 100%;
}
.cl-add__row .waw-input:first-child {
  margin-right: 20px;
}
.cl-add__row .waw-input input {
  max-width: 250px;
  width: 100%;
}
@media screen and (max-width: 583px) {
  .cl-add__notes {
    display: flex;
    justify-content: center;
  }
  .cl-add__notes .waw-textarea {
    max-width: 236.4px;
    width: 100%;
  }
}
.cl-add__switch {
  margin: 30px 0;
}
@media screen and (max-width: 583px) {
  .cl-add__switch {
    display: flex;
    justify-content: center;
  }
}
.cl-add__btn {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.cl-add__btn .waw-btn._second {
  margin-right: 40px;
}
@media screen and (max-width: 559px) {
  .cl-add__btn {
    flex-direction: column;
    justify-content: center;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
  }
  .cl-add__btn button {
    width: 100%;
  }
  .cl-add__btn .waw-btn:first-child {
    margin-bottom: 20px;
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Gilroy", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: normal;
}