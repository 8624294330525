.pureplanner {
    .waw-alert__close {
        height: 10px !important;
    }

    .waw-alert-texts {
        margin: 0 !important;
        font-family: $ff-base !important;
    }

    .waw-alert-body {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
    }
}