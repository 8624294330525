.select-container {
    position: relative;
}

.select {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: $input-height;
    min-height: $input-height;
    max-height: $input-height;
    padding: $input-padding_top_bottom calc($input-padding_left_right + $input-btn_width_height) $input-padding_top_bottom $input-padding_left_right;
    margin-bottom: $input-margin-bottom;
    border-radius: $input-border-radius;
    border: $input-border;
    background-color: $input-background;
    font: $input-font;
    letter-spacing: $input-letter-spacing;
    cursor: pointer;
    user-select: none;
    transition: 0.2s border ease-in-out;

    &:hover:not(._disabled), &:active:not(._disabled) {
        border-color: $input-border_active;
    }

    &._disabled, &._disabled .btn {
        cursor: not-allowed;
    }

    &.search::before, &.location::before, &.service::before {
        position: absolute;
        top: 50%;
        font-family: 'icomoon' !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate(0, -50%);
    }

    &.search::before {
        content: "\e914";
        left: 15px;
        color: #999999;
        font-size: 14px;
    }

    &.location::before {
        content: "\e944";
        left: 10px;
        color: $c-text_dark-grey-2;
        font-size: 18px;
    }

    &.service::before {
        content: "\e910";
        left: 10px;
        color: $c-text_dark-grey-2;
        font-size: 18px;
    }

    &.staff::before {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        content: "\e7ff";
        font-family: "Material Icons";
        color: #999999;
        font-size: 24px;
    }

    &.category::before {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        content: "\e574";
        font-family: "Material Icons";
        color: #999999;
        font-size: 24px;
    }

    &.money::before {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        content: "\e263";
        font-family: "Material Icons Round";
        color: $c-text_dark-grey-2;
        font-size: 21.5px;
    }

    &._icon {
        padding: $input-padding_top_bottom calc($input-padding_left_right + $input-btn_width_height) $input-padding_top_bottom 36px;
    }

    &._open {
        border-color: $c-main;
    }

    &._req {
        border-color: $input-req_color !important;
    }

    &._filter {
        margin-bottom: 0;
        padding-right: calc(($input-height - $input-btn_width_height) / 2 + $input-btn_width_height);
        padding-left: calc(18px + 12px + 10px);
        border-radius: 10px;
        border: 1px solid transparent;
        background: $white;
        color: $c-text_dark-grey-2;
        font-family: $ff-base;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: normal;

        .select__placeholder {
            color: $c-text_dark-grey-2;
            font-family: $ff-base;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: normal;
        }
        .select__value {
            font-family: $ff-base;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: normal;
        }

        .btn {
            right: calc(($input-height - $input-btn_width_height) / 2);
        }

        &::before {
            left: 12px;
            color: $c-text_dark-grey-2;
        }
    }

    &._zero_padding {
        padding: 0 40px 0 0;

        .select__placeholder {
            margin: 11px 0px 11px 15px;
        }
    }

    &__placeholder {
        color: $input-placeholder_color;
        font: $input-font;
        letter-spacing: $input-letter-spacing;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &__value {
        color: $input-color;
        font: $input-font;
        letter-spacing: $input-letter-spacing;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &._multiple {
            display: flex;
            align-items: center;
        }

        &-first {
            height: 24px;
            padding: 0px 18px;
            border-radius: 57px;
            background: $c-main;
            color: $white;
            font-family: $ff-second;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &-more {
            flex-shrink: 0;
            height: 24px;
            padding: 0px 18px;
            margin-left: 6px;
            border-radius: 57px;
            border: 1px dashed $c-main;
            background: transparent;
            color: $c-main;
            font-family: $ff-second;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &-all {
            height: 24px;
            padding: 0px 18px;
            border-radius: 57px;
            border: 1px dashed $c-main;
            background: transparent;
            color: $c-main;
            font-family: $ff-second;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .btn {
        position: absolute;
        top: 50%;
        right: $input-btn_right;
        width: $input-btn_width_height;
        height: $input-btn_width_height;
        font-size: $input-btn_font-size;
        line-height: $input-btn_width_height;
        border-radius: $input-btn_border-radius;
        translate: 0 -50%;
        transition: $input-btn_transition;
    }

    @include bp-max(sm) {
        height: $input-height_mobile;
        min-height: $input-height_mobile;
        max-height: $input-height_mobile;
        padding: $input-padding_top_bottom_mobile calc(($input-height_mobile - $input-btn_width_height_mobile) / 2 + $input-btn_width_height_mobile) $input-padding_top_bottom_mobile $input-padding_left_right_mobile;
        border-radius: $input-border-radius_mobile;
        font: $input-font_mobile;

        &.search::before {
            left: 10px;
            font-size: 12px;
        }

        &.location::before {
            font-size: 14px;
        }

        &.staff::before {
            font-size: 20px;
        }

        &.category::before {
            font-size: 20px;
        }

        &.money::before {
            font-size: 17px
        }

        &.service::before {
            font-size: 14px;
        }

        &._icon {
            padding: $input-padding_top_bottom_mobile calc(($input-height_mobile - $input-btn_width_height_mobile) / 2 + $input-btn_width_height_mobile) $input-padding_top_bottom_mobile 32px;
        }

        &._filter {
            padding-left: calc(14px + 8px + 6px);
            border-radius: 8px;
            font-size: 12px;
            font-weight: 400;

            &::before {
                left: 8px;
            }
    
            .select__placeholder {
                font-size: 12px;
                font-weight: 400;
            }
            .select__value {
                font-size: 12px;
                font-weight: 400;
            }
    
            .btn {
                right: $input-btn_right_mobile;
            }
        }

        &__placeholder {
            font-size: 10px;
            line-height: 16px;
        }

        &__value {
            font-size: 10px;
            line-height: 16px;
            
            &-first {
                height: 22px;
                padding: 0 11px;
                font-size: 10px;
                line-height: 22px;
            }

            &-more {
                height: 22px;
                padding: 0 11px;
                font-size: 10px;
                line-height: 22px;
            }
            &-all {
                height: 22px;
                padding: 0 11px;
                font-size: 10px;
                line-height: 22px;
            }
        }

        .btn {
            width: $input-btn_width_height_mobile;
            height: $input-btn_width_height_mobile;
            right: $input-btn_right_mobile;
            flex-shrink: 0;
            font-size: calc($input-btn_font-size - 1px);
            line-height: $input-btn_width_height_mobile;
        }
    }
}

.select-picker {
    position: absolute;
    width: 250px;
    min-width: 200px;
    margin: 10px 0;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    box-shadow: $shadow-popup;
    background-color: white;
    overflow: hidden;
    box-sizing: border-box;
    font-family: $ff-second;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    z-index: 100;

    &._searchable {
        .select-list {
            margin-top: 0px;   
        }
    }

    &._clearable, &._multiple {
        .select-list {
            margin-bottom: 0px;
        }
    }

    .select-list {
        width: 100%;
        max-height: 200px;
        margin-top: 6px;
        margin-bottom: 6px;
        overflow-y: auto;
        overflow-x: hidden;
        user-select: none;

        &__empty {
            padding: 12px;
            color: $c-grey;
            font-weight: 400;
            // font-size: 16px;
        }
        
        &__item {
            padding: 10px 15px;
            // font-weight: 400;
            // font-size: 16px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            transition: 0.15s all ease-in-out;
            color: $c-black;
            cursor: pointer;

            &:hover {
                background-color: #00000006;
            }

            &:active {
                background-color: #00000013;
            }

            &:last-child {
                margin-bottom: 0;
            }
            
            &._active {
                padding: 10px 15px;
                color: $c-main;
            }

            &._disabled {
                cursor: default;
                color: $c-grey;
                &:hover {
                    background-color: transparent;
                }
            }

            &.multiple {
                display: flex;
                align-items: center;
            }
            
            .checkbox {
                position: relative;
                display: inline-block;
                flex-shrink: 0;
                width: 22px;
                height: 22px;
                margin-right: 10px;
                border-radius: 3px;
                border: 1px solid #DBDFEE;
                background-color: $white;
                transition: 0.15s all ease-in-out;
            }

            input:checked ~ .checkbox { 
                background-color: $c-main;
                border-color: $c-main;
                &::after {
                    content: "\e5ca";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    color: $white;
                    font-family: 'Material Icons';
                    font-weight: normal;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 1;
                    letter-spacing: normal;
                    text-transform: none;
                    white-space: nowrap;
                    word-wrap: normal;
                    direction: ltr;
                    -webkit-font-feature-settings: 'liga';
                    -webkit-font-smoothing: antialiased;
                    transform: translate(-50%, -50%);
                    transition: 0.15s all ease-in-out;
                }
            }

            &-name {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    .select-search {
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);

        &::before {
            content: "\e914";
            position: absolute;
            left: 15px;
            top: 50%;
            font-size: 14px;
            color: #999999;
            font-family: 'icomoon' !important;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transform: translate(0, -50%);
        }

        &__input {
            width: 100%;
            height: 42px;
            min-height: 42px;
            max-height: 42px;
            padding: 10px 15px 10px 40px;
            border: none;
            background: transparent;
            color: $c-black;
            font-family: $ff-field;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.3px;
            transition: 0.2s all ease-in-out;

            &::placeholder {
                color: $c-grey;
                font-family: $ff-field;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.3px;
                transition: 0.2s all ease-in-out;
            }
        }
    }

    .select-actions {
        display: flex;
        justify-content: flex-end;
        padding: 6px 6px 6px;
        border-top: 1px solid rgba(0, 0, 0, 0.09);
        button {
            margin-left: 10px;
            border-radius: 4px;
            font-family: $ff-second;
            font-size: 14px;
            line-height: 36px;
            &:first-child {
                margin-left: 0;
            }
            @include bp-max(sm) {
                font-size: 12px;
                line-height: 32px;
            }
        }
    }
    
    @include bp-max(md) {
        position: relative;
        margin: 0;
        width: 65vh;
        max-height: 75vh;

        &._searchable .select-list {
            max-height: calc(75vh - 50px - 42px);
        }

        .select-list {
            max-height: calc(75vh - 50px);
        }
    }

    @include bp-max(sm) {
        width: 80vw;
        max-height: 65vh;
        font-size: 12px;

        &._searchable .select-list {
            max-height: calc(65vh - 50px - 42px);
        }

        .select-list {
            max-height: calc(65vh - 50px);
    
            &__empty {
                // font-size: 12px;
            }
            
            &__item {
                padding: 7px 10px;
                // font-size: 12px;
                
                &._active {
                    padding: 7px 10px;
                }

                .checkbox {
                    width: 20px;
                    height: 20px;
                }
    
                input:checked ~ .checkbox::after {
                    font-size: 15px;
                }
            }
        }

        .select-search {
            &::before {
                left: 10px;
                font-size: 12px;
            }

            &__input {
                padding: 8px 10px 8px 30px;
                font-size: 12px;
                line-height: 16px;

                &::placeholder {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        .select-actions button {
            font-size: 12px;
            line-height: 32px;
        }
    }
}

.select-option-user {
    margin: calc(($input-height - 30px) / 2) 0px calc(($input-height - 30px) / 2) $input-padding_left_right;
    display: flex;
    align-items: center;
    overflow: hidden;

    @include bp-max(sm) {
        margin: calc(($input-height_mobile - 22px) / 2) 0px calc(($input-height_mobile - 22px) / 2) $input-padding_left_right_mobile;
    }

    &__img {
        width: 30px;
        height: 30px;
        flex-shrink: 0;

        @include bp-max(sm) {
            width: 22px;
            height: 22px;
        }

        .letter {
            font-size: 14px !important;

            @include bp-max(sm) {
                font-size: 12px !important;
            }
        }
    }
    
    &__name {
        margin-left: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        @include bp-max(sm) {
            margin-left: 6px;
        }
    }
}
