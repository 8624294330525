// INPUT
.waw-input {
    position: relative;
    .req {
		input {
			border-color: $input-req_color;
            &:hover, &:focus {
			    border-color: $input-req_color !important;
            }
		}
        &-text {
            margin-top: $input-req_top;
			display: block;
            color: $input-req_color;
            font: $input-req_font;
        }
    }
	&._icon {
		input {
			padding-left: 40px;
		}
        .material-icons {
			position: absolute;
            top: 50%;
			color: $c-placeholder;
            transform: translateY(-50%);
            left: 20px;
        }
    }
    &__title {
        margin-bottom: $input-label_margin;
        display: block;
        color: $input-label_color;
        font: $input-label_font;
        text-transform: capitalize;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: keep-all;
        white-space: nowrap;
    }
    input {
        width: 100%;
        height: $input-height;
        min-height: $input-height;
        max-height: $input-height;
        padding: $input-padding_top_bottom $input-padding_left_right;
        margin-bottom: $input-margin-bottom;
        display: block;
        border-radius: $input-border-radius;
        border: $input-border;
        background-color: $input-background;
        color: $input-color;
        font: $input-font;
        letter-spacing: $input-letter-spacing;
        transition: 0.2s all ease-in-out;

        &::placeholder {
            color: $input-placeholder_color;
            font: $input-font;
            letter-spacing: $input-letter-spacing;
            transition: 0.2s all ease-in-out;
        }
        &:hover:not(:disabled), &:focus:not(:disabled) {
            border-color: $input-border_active;
        }
        &:disabled {
            cursor: not-allowed;
        }
    }

    @include bp-max(sm) {
        &:not(._not_change_on_mobile) input {
            height: $input-height_mobile;
            min-height: $input-height_mobile;
            max-height: $input-height_mobile;
            padding: $input-padding_top_bottom_mobile $input-padding_left_right_mobile;
            border-radius: $input-border-radius_mobile;
            font: $input-font_mobile;
    
            &::placeholder {
                font: $input-font_mobile;
            }
        }
    }
}
input[type="time"] {
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
}
