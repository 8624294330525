.mat-form-field {
    display: block;
    width: 100%;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none !important;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0;
    border: none;
}
.mat-calendar {
    .mat-calendar-table-header-divider::after {
        left: 0 !important;
        right: 0 !important;
    }

    .mat-calendar-body-cell .mat-calendar-body-cell-content.mat-focus-indicator {
        background: transparent;
    }
    .mat-calendar-body-cell:hover .mat-calendar-body-cell-content.mat-focus-indicator{
        background: rgb(115, 119, 118, 0.15) !important;
    }
    .mat-calendar-body-disabled.mat-calendar-body-cell:hover > .mat-calendar-body-cell-content {
        background: transparent !important;
    }
    .mat-calendar-body-cell .mat-calendar-body-selected, 
    .mat-calendar-body-cell:hover .mat-calendar-body-cell-content.mat-calendar-body-selected {
        background: $c-main !important;
        box-shadow: inset 0 0 0 1px white;
    }
    .mat-calendar-body-disabled.mat-calendar-body-cell .mat-calendar-body-selected, .mat-calendar-body-disabled.mat-calendar-body-cell:hover .mat-calendar-body-cell-content.mat-calendar-body-selected {
        background: rgba(106, 196, 179, 0.4) !important;
    }
    .mat-calendar-body-in-range::before {
        background: rgb(115, 119, 118, 0.15) !important;
    }
}

.mat-datepicker-content {
    margin: 10px 0 !important;
    border-radius: 10px !important;
    border: 1px solid rgba(0, 0, 0, 0.09);
    box-shadow: $shadow-popup;
    background-color: white;
}

.cdk-overlay-dark-backdrop {
    background: #0000007A !important;
}
