.modal.pureplanner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 70px 150px;
    background-color: #0000007A !important;
    @include bp-max(md) {
        padding: 20px 30px;
    }
    @include bp-max(sm) {
        padding: 10px 20px;
    }
}

.modalOpened .pureplanner>.modal-content {
    width: 100%;
    max-width: 570px !important;
    padding: 0px;
    margin: 0 auto !important;
    border-radius: 0;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    &>div {
        width: 100%;
    }
    .close {
        display: none;
        top: 10px;
        right: 45px;
        content: "\e5cd";
        font-family: 'Material Icons';
        color: #fff;
        @include bp-max(md) {
            top: 25px;
            right: 25px;
        }
    }
}

.modalOpened .pureplanner.big>.modal-content {
    max-width: 725px !important;
}

.modalOpened .pureplanner.small>.modal-content {
    max-width: 378px !important;
}

.modalOpened .pureplanner.importexport>.modal-content {
    max-width: 760px !important;

    @include bp-max(md) {
        max-width: 435px !important;
    }

    @include bp-max(sm) {

    }
}

.pureplanner:not(.foreground) {
    z-index: 999 !important;
}

.newAppoint-modal {
    .nsm-dialog {
        max-width: 100%;
    }
}

.nsm-body {
    max-width: 810px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.nsm-content {
    border-radius: 0;
    padding: 60px 150px;
    margin: 0;
    padding-top: 87px;
    @include bp-max(md) {
        padding: 40px;
    }
}

.nsm-dialog {
    max-width: 100%;
}
.nsm-dialog-btn-close {
    width: 14px;
    height: 14px;
    padding: 0;
    top: 45px;
    right: 45px;
    svg {
        width: 100%;
    }
    @include bp-max(md) {
        top: 25px;
        right: 25px;
    }
}

.modal {
    &-title {
        margin-bottom: 30px;
        margin-right: 40px;
        color: $c-black;
        font-family: $ff-second;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.3px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        word-break: keep-all;

        @include bp-max(sm) {
            margin-bottom: 20px;
            margin-right: 15px;
            color: $c-text_dark-grey-2;
            font-size: 14px;
        }
    }

    &-img {
        flex-shrink: 0;
        display: block;
        width: 138px;
        height: 138px;
        position: relative;
        border-radius: 50%;
 
        @include bp-max (md) {
            width: 130px;
            height: 130px;
        }

        @include bp-max (sm) {
            width: 92px;
            height: 92px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
               
        .img-upload, .img-delete {
            width: 26px !important;
            min-width: 26px !important;
            height: 26px !important;
            border-radius: 8px !important;
            background-color: $white;
            font-size: 15px;
            text-align: center;
            
            @include bp-max(sm) {
                width: 24px !important;
                min-width: 24px !important;
                height: 24px !important;
                font-size: 14px;
            }

            [class^="icon-"]:before, [class*=" icon-"]:before {
                font-size: 15px;
                line-height: 24px;

                @include bp-max(sm) {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        .img-upload {
            position: absolute;
            right: 0;
            bottom: 0;
            border: 1px solid $c-main;
            color: $c-main;
        }

        .img-delete {
            position: absolute;
            right: 0;
            top: 0;
            border: 1px solid $c-red;
            color: $c-red;
        }
    }

    &-box {
        position: relative;
        padding: 30px;
        background: $white;
        border-radius: 20px;
        transition: 0.2s all ease-in-out;
        @include bp-max(sm) {
            border-radius: 14px;
            padding: 16px 10px 10px;
        }
    }

    &-switch {
        margin: 10px 0 30px;

        @include bp-max(sm) {
            margin-top: 0;
        }

        @media screen and (max-width: 583px) {
            display: flex;
            justify-content: flex-start;
        }
    }

    &-btn {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;

        @include bp-max(sm) {
            margin-top: 20px;
        }
        
        .waw-btn {
            width: 100%;
            max-width: 155px;

            &:nth-child(1) {
                justify-self: start;
            }

            &:nth-child(2) {
                justify-self: end;
            }

            @include bp-max(md) {
                font-size: 16px;
                line-height: normal;
            }

            @include bp-max(sm) {
                max-width: 100%;
                min-width: 0;
                height: 36px;
                margin-bottom: 10px;
                padding: 10px 20px;
                border-radius: 10px;
                font-size: 14px;
                line-height: normal;
            }
        }
    }

    &-close {
        position: absolute !important;
        top: 25px;
        right: 25px;
        width: 42px !important;
        height: 42px !important;
        line-height: 22px !important;
        &__icon {
            position: relative;
            display: inline-block;
            width: 22px;
            height: 22px;
            &::before, &::after {
                position: absolute;
                top: -4px;
                left: 10px;
                content: ' ';
                height: 30px;
                width: 1px;
                background-color: #D4D4D4;
            }
            &::before {
                transform: rotate(45deg);
            }
            &::after {
                transform: rotate(-45deg);
            }
            @include bp-max(sm) {
                width: 10px;
                height: 10px;
                &::before, &::after {
                    height: 13px;
                    top: -1px;
                    left: 5px;
                    background-color: #ADB8C6;
                }
            }
        }
        @include bp-max(sm) {
            top: 11px;
            right: 5px;
            width: 20px !important;
            height: 20px !important;
            line-height: 10px !important;
        }
    }

    &-section {
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 8px;
        background: #F7F8FA;

        @include bp-max(md) {
            padding: 15px;
        }

        @include bp-max(sm) {
            padding: 10px;
            margin-top: -10px;
            border-radius: 6px;
        }
    }

    &-h2 {
        margin-top: 0;
        margin-bottom: 10px;
        color: $c-black;
        font-family: $ff-second;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.3px;
    
        @include bp-max(sm) {
            font-size: 14px;
        }
    }

    &-section &-h2{
        color: $c-text_dark-grey-2;
    }

    &-p {
        margin: 0;
        margin-bottom: 20px;
        color: $c-text_dark-grey;
        font-family: $ff-field;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.3px;
    
        @include bp-max(sm) {
            font-size: 10px;
            line-height: normal;
        }
    }

    &-rowImg {
        display: flex;
        flex-wrap: nowrap;
        width: calc(100% + 30px);
        margin-left: -15px;

        @include bp-max(sm) {
            width: calc(100% + 10px);
            margin-left: -5px;
        }

        &__left {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            margin: 0 15px 10px;

            .placeholder-icon {
                width: 100%;
                height: 100%;
                display: block;
                color: #D6D6DC;
                text-align: center;
                line-height: 138px;
                font-size: calc(138px / 2.5);
            }
            
            .waw-input, .waw-select {
                max-width: calc(138px + 20px);
                margin-right: -20px;
            }

            @include bp-max(md) {  
                .placeholder-icon {
                    line-height: 130px;
                    font-size: calc(130px / 2.5);
                }

                .waw-input, .waw-select {
                    max-width: calc(130px + 20px);
                }
            }

            @include bp-max(sm) {
                margin: 0 10px 10px 5px;

                .placeholder-icon {
                    line-height: 92px;
                    font-size: calc(92px / 2.5);
                }

                .waw-input, .waw-select {
                    max-width: 92px;
                    margin-right: 0;
                }
            }
        }

        &__right {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            width: auto;
            width: calc(100% + 30px);

            @include bp-max(sm) {
                width: calc(100% + 10px);
            }
        }
    }

    &-selectMultiple {
        --item-height: 24px;

        position: relative;
        width: 100%;
        min-height: $input-height;
        padding: calc(($input-height - 2px - var(--item-height)) / 2) $input-padding_left_right;
        margin-bottom: $input-margin-bottom;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        overflow: hidden;
        border-radius: $input-border-radius;
        border: $input-border;
        background-color: $input-background;
        color: $input-color;
        font: $input-font;
        letter-spacing: $input-letter-spacing;
        user-select: none;
        cursor: pointer;
        transition: 0.2s all ease-in-out;

        &:hover, &._open {
            border-color: $input-border_active;
        }

        &._req {
            border-color: $input-req_color;
        }

        &__placeholder {
            color: $input-placeholder_color;
            letter-spacing: $input-letter-spacing;
            line-height: var(--item-height);
            transition: 0.2s all ease-in-out;
        }

        &__btn {
            position: absolute !important;
            bottom: calc(($input-height - 1px) / 2);
            right: $input-btn_right;
            width: $input-btn_width_height !important;
            height: $input-btn_width_height !important;
            font-size: $input-btn_font-size;
            line-height: $input-btn_width_height !important;
            border-radius: $input-btn_border-radius !important;
            translate: 0 50%;
            transition: $input-btn_transition;
        }

        &__item {
            position: relative;
            display: block;
            height: var(--item-height);
            padding: 5px 18px;
            border-radius: 57px;
            background: $c-main;
            color: white;
            font-family: $ff-base;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            &:last-of-type {
                margin-right: $input-btn_width_height;
            }

            &._delete {
                padding-right: calc(var(--item-height) + 1px);
            }

            &__delete {
                position: absolute !important;
                top: 0;
                right: 0;
                width: var(--item-height) !important;
                height: var(--item-height) !important;
                line-height: var(--item-height) !important;
                
                .material-icons {
                    font-size: 12px; 
                    line-height: var(--item-height);
                    color: white;
                }
            }
        }

        @include bp-max(sm) {
            --item-height: 22px;

            min-height: $input-height_mobile;
            padding: calc(($input-height_mobile - 2px - var(--item-height)) / 2) $input-padding_left_right_mobile;
            border-radius: $input-border-radius_mobile;
            font: $input-font_mobile;
        
            &__btn {
                bottom: calc(($input-height_mobile - 1px) / 2);
                right: $input-btn_right_mobile;
                width: $input-btn_width_height_mobile !important;
                height: $input-btn_width_height_mobile !important;
                flex-shrink: 0;
                font-size: calc($input-btn_font-size - 1px);
                line-height: $input-btn_width_height_mobile !important;
            }
        
            &__item {
                padding: 5px 11px;
                font-size: 10px;
                font-weight: 400;
    
                &:last-of-type {
                    margin-right: calc(($input-height_mobile - $input-btn_width_height_mobile) / 2 + $input-btn_width_height_mobile - $input-padding_left_right_mobile);
                }
            }
        }
    }
}

hr {
    border: 0;
    border-top: 0.25px solid #979797;
    margin: 30px 0;
}

.modal-popup {
    width: 100%;
    max-width: 300px;
    padding: 30px 26px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    .col-1 {
        padding: 0 0 10px;
    }
    .modal-btn {
        justify-content: center;
        @include bp-max(sm) {
            .waw-btn {
                margin-bottom: 0;
            }
        }
    }
}
