.waw-select__title {
	display: block;
	margin-bottom: $input-label_margin;
	color: $input-label_color;
	font: $input-label_font;
	text-transform: capitalize;
	text-overflow: ellipsis;
	overflow: hidden;
	word-break: keep-all;
	white-space: nowrap;
}
.waw-select {
	.req {
        &-text {
			margin-top: $input-req_top;
			display: block;
			color: $input-req_color;
            font: $input-req_font;
        }
    }
}
