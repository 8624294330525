.navigation {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    // grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 16px;
    margin-bottom: 30px;
    transition: 0.2s all ease-in-out, 0s padding ease-in-out;

    &__topbar {
        button {
            float: right;
        }
    }

    &__search {
        width: 100%;
        max-width: 300px;
    }

    &__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px 10px;
        width: 100%;
        // overflow: hidden;
    }

    &__filter {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        flex-grow: 1;
        // width: 100%;

        &-date {
            width: 100%;
            max-width: 175px;
            overflow: hidden;
        }

        &-type {
            width: 100%;
            max-width: 145px;
            overflow: hidden;
        }
    }

    &__btn button {
        transition: all 0.2s ease-in-out, border-radius 0s, min-width 0s, height 0s !important;
    }
    
    @include bp-min(lg) {
        &.without_searching {
            &::before {
                content: "";
                display: block;
                width: 100%;
                max-width: 300px;
            }
        }
    }

    @media (min-width: $lg) and (max-width: $xl) {
        &.navShown {
            display: flex;
            justify-content: space-between;
            gap: 16px;
        }

        &.navShown.without_filter.without_searching::before {
            display: none;
        }

        &.navShown:not(.without_filter).without_searching, &.navShown:not(.without_searching).without_filter, &.navShown:not(.without_searching):not(.without_filter) {
            padding-top: calc(16px + 42px);
            position: relative;
        }
        
        &.navShown:not(.without_filter).without_searching &__tab, &.navShown:not(.without_searching).without_filter &__tab, &.navShown:not(.without_searching):not(.without_filter) &__tab {
            width: 100%;
            position: absolute;
            top: 0;
        }
    }

    @media (min-width: $md) and (max-width: 1200px) {
        &:not(.without_filter).without_searching, &:not(.without_searching).without_filter, &:not(.without_searching):not(.without_filter) {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            padding-top: calc(16px + 42px);
            position: relative;
        }

        &:not(.without_filter).without_searching &__tab, &:not(.without_searching).without_filter &__tab, &:not(.without_searching):not(.without_filter) &__tab {
            width: 100%;
            position: absolute;
            top: 0;
        }
    }

    @include bp-max (md) {
        display: flex;
        justify-content: center !important;
        gap: 16px;
        
        &__search {
            flex-shrink: 1;
        }
    
        &__right {
            overflow: visible;
            width: auto;
    
            &:not(._filter) {
                position: absolute;
            }
        }
    
        &__filter {
    
            &-date {
                width: 175px;
            }
    
            &-type {
                width: 145px;
            }
        }
    }

    @include bp-max (sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;
        padding-top: 0 !important;

        &__tab {
            width: 100%;
        }

        &__search {
            max-width: none;
        }

        &__filter {
            gap: 16px;
    
            &-type, &-date {
                width: 50% !important;
                max-width: none;
            }

            &-daterange {
                flex-grow: 1;
            }
        }

        &__right {
            width: 100%;
            gap: 10px;

            &:not(._filter) {
                position: absolute;
            }

            &._filter {
                flex-direction: row;
                justify-content: center;
            }
        }
    }

    @include bp-max (xs) {
        &__right._filter {
            align-items: flex-end;
            flex-direction: column;
        }

        &__filter {
            justify-content: center;
            width: 100%;
        }
    }
}

@include bp-max (md) {
    .navigation__btn {
        position: fixed;
        right: 24px;
        bottom: 24px;
        z-index: 100;
        transition: all 0.3s ease-in-out;
        
        button {
            transition: border-radius 0s;
            width: 56px !important;
            min-width: 0 !important;
            height: 56px !important;
            padding: 0 !important;
            border-radius: 50% !important;
            font-size: 25px !important;

            .icon-plus {
                margin-right: 0 !important;

                & + * {
                    display: none;
                }
            }
        }

        &._hide {
            bottom: -56px !important;
            opacity: 0 !important;
        }
    }

    .hideOnScroll {
        transition: all 0.3s ease-in-out;
        
        &._hide {
            bottom: -56px !important;
            opacity: 0 !important;
        }
    }
}
