// style scroll for all blocks
body:not(._mobile-scrollbar) {
    *::-webkit-scrollbar-track {
        border-radius: 0px;
        background-color: $c-background;
        display: none;
    }
    
    *::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: transparent;
    }
    
    *::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $c-main; 

        &:hover {
            background-color: #22AAA1;
        }
    }

    *::-webkit-scrollbar-corner { 
        background: transparent; 
    }

    *.dark-scrollbar::-webkit-scrollbar-thumb {
        background-color: #444444; 

        &:hover {
            background-color: #666666;
        }
    }
}

// style scroll for Firefox
@-moz-document url-prefix() {
    * {
        scrollbar-color: #22AAA1 transparent;
    } 
    *.dark-scrollbar {
        scrollbar-color: #666666 transparent;
    }
}