// INPUT datepicker
.waw-datepicker {
    position: relative;

    &__title {
        margin-bottom: $input-label_margin;
        display: block;
        color: $input-label_color;
        font: $input-label_font;
        text-transform: capitalize;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: keep-all;
        white-space: nowrap;
    }

    // input body settings
    &.req {
        .req-text {
            margin-top: $input-req_top;
			display: block;
            color: $input-req_color;
            font: $input-req_font;
            border-color: $input-req_color;
        }
    }
}
