// SWITCH
.waw-switch {
    display: flex;
    align-items: center;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:not([disabled])+.track, input:not([disabled]) ~ .waw-switch-text {
        cursor: pointer;
    }

    input:checked+.track {
        border: 1px solid $c-main;
    }

    input:checked+.track .handle {
        transform: translate(18px, -50%);
        background-color: $c-main;

        @include bp-max(sm) {
            transform: translate(16px, -50%);
        }
    }

    &:hover input:not([disabled])~.track .handle:before {
        opacity: 0.3;
    }
    
    &:hover input:checked:not([disabled])~.track .handle:before {
        background-color: $c-main;
        opacity: 0.15;
    }

    &:active input:not([disabled])~.track .handle:before {
        opacity: 0.6;
    }
    
    &:active input:checked:not([disabled])~.track .handle:before {
        background-color: $c-main;
        opacity: 0.3;
    }

    input:disabled+.track {
        border-color: $c-stroke !important;
        transition: 0s;
    }

    input:not([checked]):disabled+.track .handle {
        background-color: $c-stroke !important;
        transition: 0s;
    }

    input:checked:disabled+.track .handle {
        background-color: #D6D6DC !important;
        transition: 0s;
    }

    input:disabled ~ .waw-switch-text {
        color: $c-placeholder !important;
    }

    .track {
        position: relative;
        width: 42px;
        height: 24px;
        border: 1px solid $c-stroke;
        border-radius: 100px;
        background: #FCFDFE;
        outline: none;
        transition: 0.4s;

        .handle {
            position: absolute;
            display: block;
            width: 18px;
            height: 18px;
            left: 2px;
            top: 50%;
            border: 1px solid $c-stroke;
            border-radius: 50%;
            background-color: #ADB8C6;
            transform: translateY(-50%);
            box-sizing: border-box;
            transition: 0.4s;
            transform-style: preserve-3d;

            &::before {
                content: "";
                position: absolute;
                width: 40px;
                height: 40px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) translateZ(-1px);
                border-radius: 50%;
                background-color: #E1E7F3;
                opacity: 0;
                transition: 0.2s ease-in-out;
            }
        }

        @include bp-max(sm) {
            width: 34px;
            height: 18px;     
            
            .handle {
                width: 14px;
                height: 14px;
                left: 1px;
            }

            &::before {
                width: 24px;
                height: 24px;
            }
        }
    }

    &-text {
        margin-left: 15px;
        color: $c-text_dark-grey;
        font-family: $ff-second;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
}
