// @include bp-max(md) {
//     display: flex;
// }

.mb18 {
    margin-bottom: 18px;
}

.pb18 {
    padding-bottom: 18px;
}

.profile {
    // display: flex;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-gap: 20px;
    align-items: flex-start;
    overflow: visible;

    @include bp-max(md) {
        grid-template-columns: 1fr;
    }
}

.profile-bar {
    background: $white;
    box-shadow: 0px 4px 4px rgba(51, 77, 110, 0.25);
    border-radius: 35px;
    padding: 25px 35px 40px;
    position: relative;
    margin-bottom: 20px;

    @include bp-max(md) {
        width: 100%;
        margin-bottom: 20px;
    }

    &__created {
        font-size: 12px;
        line-height: 16px;
        color: $c-text_grey;

        &__date {
            font-weight: 500;
            color: $c-text_dark-grey;
        }
    }

    &__more {
        cursor: pointer;
        position: absolute;
        right: 30px;
        top: 25px;
        display: flex;
        flex-direction: column;

        span {
            width: 5px;
            height: 5px;
            display: block;
            background: $grey;
            border-radius: 50%;
            margin-bottom: 5px;

            &:last-child {
                margin-right: 0;
            }
        }

        &__popup {
            position: absolute;
            background: $white;
            box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
            border-radius: 3px;
            padding: 10px;
            width: 95px;
            right: 0;
            z-index: 11;
            top: 20px;
            opacity: 0;
            transition: .3s all ease-in-out;
            transform: scale(0);


            .popup__link {
                display: block;
                text-align: center;
                cursor: pointer;
                margin-bottom: 10px;
                font-size: 14px;
                text-align: center;
                letter-spacing: 0.01em;
                color: $blue;

                &:last-child {
                    margin-bottom: 0;
                }

                &._danger {
                    padding-top: 10px;
                    border-top: 0.5px solid $grey;
                    letter-spacing: 0.01em;

                    color: #C0392B;
                }
            }

            &._active {
                transition: .3s all ease-in-out;
                opacity: 1;
                transform: scale(1);
            }
        }
    }

    &__photo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        padding: 10px;
        // border: 1px solid $sky;
        margin-top: 40px;

        &._letter {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f3f3f3;
            font-size: 35px;
            color: $sky;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }

        &__label {
            position: absolute;
            display: block;
            left: 34px;
            bottom: -4px;
            background: $sky;
            border-radius: 20px;
            padding: 4px 10px;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: $white;
        }
    }

    &__name {
        margin: 20px 0 14px;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: $blue;
    }

    &__newEvent {
        text-align: center;
        margin-bottom: 28px;
    }

    &__info {
        &__item {
            padding-bottom: 20px;
            border-bottom: 0.5px solid $grey;
            margin-bottom: 20px;

            &:last-child {
                border-bottom: none;
            }
        }

        &__header {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: $blue;
            margin-bottom: 5px;
        }

        &__text {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: #979797;
        }


    }
}



.profile-container {
    flex-grow: 1;

    @include bp-max(md) {
        width: 100%;
        padding-left: 0;
    }
}

.profile-main {
    padding-bottom: 20px;
}

.profile-topsection {
    display: flex;

    @include bp-max(lg) {
        flex-direction: column;

        .sales {
            width: 100%;
        }

        .link {
            padding-left: 0;
            margin-top: 20px;
        }
    }
}

.sales {
    width: 440px;
    text-align: center;
    background: $white;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    padding: 30px 20px;

    &__total {
        width: 50%;
        padding-bottom: 30px;

        border-right: 0.5px solid $grey;

    }

    &__number {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: $blue;
        margin-bottom: 5px;
    }

    &__title {
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.01em;
        color: $blue;
    }

    &__outstanding {
        width: 50%;
        padding-bottom: 30px;

    }
}

.sales-top {
    display: flex;
    border-bottom: 0.5px solid $grey;

}

.sales-bottom {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    @include bp-max(md) {
        flex-direction: column;

        &>div {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

        }


    }
}

.link {
    padding-left: 20px;
    flex-grow: 1;
}

.link-item {
    background: $white;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }

    &__add {
        margin-right: 20px;
        font-size: 12px;
        text-decoration: underline;
        color: $sky;
    }

    &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: $blue;
    }


}

.profile-event {
    background: $white;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    padding: 20px;
    margin-top: 20px;
}

.event-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 23px;
    margin-top: 20px;

    &__type {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: $blue;
    }
}

.event-row {
    position: relative;
    background: $white;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    padding-right: 20px;
    margin-bottom: 10px;
    cursor: pointer;

    @include bp-max(md) {
        padding-right: 5px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__color {
        position: absolute;
        width: 6px;
        height: 100%;
        background: $green;
        border-radius: 5px 0px 0px 5px;

    }

    &__body {
        display: flex;

    }

    &__date {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 30px;
        max-width: 30px;
        min-width: 30px;
        margin-left: 16px;
        margin-right: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: $blue;

        @include bp-max(md) {
            position: absolute;
            right: 5px;
            top: 2px;
            font-size: 10px;
            width: auto;
            max-width: 100%;
            min-width: auto;
            margin-right: 0;
        }

    }


    &__title {
        @include bp-max(md) {
            padding-left: 10px;
        }

        &__top {
            display: flex;
            margin-bottom: 5px;
        }

        &__name {

            word-break: break-word;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.01em;
            color: $blue;
            margin-right: 15px;

            @include bp-max(md) {
                font-size: 12px;
            }
        }

        &__status {
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.01em;
            color: $grey;

            @include bp-max(md) {
                position: absolute;
                bottom: 2px;
                right: 5px;
            }
        }

        &__bottom {
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.01em;
            color: $blue;

            @include bp-max(md) {
                font-size: 12px;
            }
        }
    }

    &__price {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.01em;
        color: $blue;
        min-width: fit-content;

        @include bp-max(md) {
            font-size: 12px;
            padding: 5px 0;
            width: 70px;
            text-align: end;
            min-width: 70px;
        }
    }
}

.icon-chevron {
    display: block;
    transition: .3s all ease-in-out;

    &._active {
        transition: .3s all ease-in-out;
        transform: rotate(90deg);
    }

}

.profile-section {
    height: 100%;
    background: $white;
    box-shadow: 0px 4px 4px rgba(51, 77, 110, 0.25);
    border-radius: 35px;
    padding: 25px;
}

.profile-link {
    text-align: center;

    &__nav {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 5px;

        &__back {
            justify-self: start;
            cursor: pointer;
            transform: rotate(180deg);

            & > * {
                line-height: 42px;
            }
        }

        &__title {
            color: $c-black;
            font-family: $ff-base;
            font-size: 24px;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0em;
            text-align: center;
            justify-self: center;

        }

        &__btn {
            justify-self: end;
            border-radius: 10px !important;
            // border-radius: 8px !important;
        }
    }

    @include bp-max(sm) {
        &__nav {    
            &__back {
                & > * {
                    line-height: 32px;
                }
            }
    
            &__title {
                line-height: 32px;
            }
        }
    }
}

.profile-files {
    overflow: hidden;
    user-select: none;

    &__path {
        margin-top: 20px;
        margin-left: -10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        &-directory, &-separator {
            color: $c-text_dark-grey;
            font-family: $ff-base;
            font-size: 18px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0px;
        }
        
        &-directory {
            min-width: 0px !important;
            padding: 0 10px !important;
            border-radius: 10px !important;

            &._selected {
                color: $c-black;
            }
        }

        &-separator {
            margin: 0 2px;
        }
    }

    &__body {
        margin: 0 -15px -15px;
        padding: 15px;

        &._dragActive {
            position: relative;
            overflow: hidden;
        }
    }

    &__section {
        margin-bottom: 15px;
        color: $c-black;
        font-family: $ff-base;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;

        &:nth-of-type(2) {
            margin-top: 20px;
        }
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 15px;
        justify-items: center;
    }

    &__drag {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: color-mix(in srgb, $c-light-mint-green 70%, transparent);
        border: 2px solid $c-main;
        border-radius: 25px;
    }

    &__item {
        width: 100%;
        max-width: 100%;
        min-width: 0;
        position: relative;
        padding: 8px;
        background-color: $white;
        border-radius: 10px;
        border: 1px solid $c-dark_white;
        overflow: hidden;
        cursor: pointer;
        transition: 0.15s all ease-in-out;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }

        &__container {
            margin-top: 8px;
            width: 100%;
            aspect-ratio: 1/1;
            border-radius: 2px;
            overflow: hidden;

            &.img {
        
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &.file {
                background-color: white;    
                display: flex;
                align-items: center;
                justify-content: center;
    
                &__icon {
                    position: relative;
                    height: 100px;
                    width: 75px;            
                    border-radius: 8px;
                    background-color: #D6D6DC;
                    clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 30px, 100% 100%, 0 100%);
                    color: white;
                    font-family: $ff-base;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: calc(100px + 30px);
                    letter-spacing: 0px;
                    text-align: center;
                    text-transform: uppercase;
    
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        display: block;
                        width: 30px;
                        height: 30px;
                        background-color: color-mix(in srgb, #D6D6DC 40%, white);
                        border-bottom-left-radius: 8px;
                    }
                    
                    &.blue {
                        background-color: #45C2E6;

                        &::after {
                            background-color: color-mix(in srgb, #45C2E6 40%, white);
                        }
                    }
                    
                    &.red {
                        background-color: #E47878;

                        &::after {
                            background-color: color-mix(in srgb, #E47878 40%, white);
                        }
                    }
                    
                    &.green {
                        background-color: #6AC4B3;

                        &::after {
                            background-color: color-mix(in srgb, #6AC4B3 40%, white);
                        }
                    }

                    &.yellow {
                        background-color: #FFC300;

                        &::after {
                            background-color: color-mix(in srgb, #FFC300 40%, white);
                        }
                    }
                }
            }
        }

        &__head {
            overflow: hidden;
            display: flex;
            align-items: center;

            &-title {
                flex-grow: 1;
                margin-top: 2px;
                margin-bottom: 2px;
                color: $c-black;
                font-family: $ff-base;
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0px;
                text-align: left;
            }

            &-more {
                width: 30px !important;
                height: 30px !important;
                line-height: 30px !important;
                color: $c-text_dark-grey-2;

                [class^="material-icons-"], [class*=" material-icons-"], .material-icons {
                    font-size: 22px !important;
                }
            }

            &-icon {
                color: color-mix(in srgb, $c-main 70%, white);
                margin-right: 10px;
            }
        }
    }

    &__empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 200px;
        padding: 15px 25px;
        border-radius: 10px;
        text-align: center;

        &-icon {
            margin-bottom: 10px;
            color: color-mix(in srgb, $c-main 70%, white);
            font-size: 40px;
        }

        &-text {
            color: $c-text_dark-grey-2;
            font-family: $ff-base;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
}

.file-preview-backdrop {
    background-color: #000000ba;

    & + .cdk-overlay-connected-position-bounding-box {
        padding: 70px 150px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include bp-max(md) {
            padding: 60px 30px 20px;
        }

        @include bp-max(sm) {
            padding: 50px 20px 10px;
        }
    }
}

.file-preview-panel {
    position: static !important;
    margin: 0 auto !important;
    user-select: none;

    .file-preview__head {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 70px;
        padding: 15px 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        pointer-events: none;
        z-index: 10;
        transition: 0.2s background ease-in-out;

        &.background {
            background-color: #0000007A;
        }

        &__group {
            display: flex;
            align-items: center;
            gap: 10px;
            pointer-events: auto;

            &:first-of-type {
                overflow: hidden;
            }

            &:first-of-type.zoom {
                max-width: calc(50% - 55px);

                & + * {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0);
                }
            }

            &:nth-child(2), &:nth-child(3) {
                flex-shrink: 0;
            }
        }
        
        &-name {
            font-family: $ff-base;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            cursor: pointer;
        }

        button[disabled] {
            color: rgb(255 255 255 / 36%) !important;
        }
    }

    .file-preview__img {
        width: 100% !important;
        height: 100% !important;
        border-radius: 4px;
        overflow: hidden;
        visibility: hidden;
        transition: 0.1s max-width ease;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .file-preview__pdf {
        width: calc(100% + 2 * 150px);
        height: calc(100% + 2 * 70px);
        margin: -70px -150px 0;

        pdf-viewer {
            width: 100%;
            height: 100%;

            .ng2-pdf-viewer-container {
                padding-top: 70px;
            }

            .page, .page .canvasWrapper {
                border-radius: 4px;
            }
        }
    }

    .file-preview__txt {
        width: calc(100% + 2 * 150px);
        padding: 70px 150px 0;
        margin: -70px -150px -70px;
        overflow-y: auto;
        min-height: 100%;
        
        &__content {
            width: 100%;
            height: auto;
            min-height: calc(100% - 70px);
            padding: 30px;
            margin-bottom: 20px;
            border-radius: 4px;
            background-color: white;
            
            pre {
                margin: 0;
                font-family: $ff-field;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.2px;
                white-space: pre-wrap;
                word-wrap: break-word;
            }
        }
    }

    .file-preview__does-not-open {
        color: white;
        font-family: $ff-second;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.3px;
    }

    @include bp-max(md) {
        .file-preview__head {
            height: 60px;
            padding: 10px 15px;
            
            &__group {
                gap: 8px;
            }
        }

        .file-preview__pdf {
            width: calc(100% + 2 * 30px);
            height: calc(100% + 60px + 20px);
            margin: -60px -30px 0;

            pdf-viewer .ng2-pdf-viewer-container {
                padding-top: 60px;
            }
        }

        .file-preview__txt {
            width: calc(100% + 2 * 30px);
            padding: 60px 30px 0;
            margin: -60px -30px -20px;
            
            &__content {
                min-height: calc(100% - 20px);
            }
        }
    }

    @include bp-max(sm) {
        .file-preview__head {
            height: 52px;
            padding: 10px 10px;
            gap: 5px;

            &__group {
                gap: 5px;

                &:first-of-type.zoom {
                    max-width: none;
    
                    & + * {
                        position: fixed;
                        bottom: 10px;
                        gap: 10px;
                        background-color: #0000007A;
                        border-radius: calc(32px / 2);
                    }
                }
            }

            button {
                width: 32px !important;
                height: 32px !important;
                line-height: 32px !important;

                [class^="material-icons-"], [class*=" material-icons-"], .material-icons {
                    font-size: 22px !important;
                }
            }
        }

        .file-preview__does-not-open {
            margin-bottom: 20px;
            margin-right: 15px;
            font-size: 14px;
        }

        .file-preview__pdf {
            width: calc(100% + 2 * 20px);
            height: calc(100% + 50px + 10px);
            margin: -50px -20px 0;

            pdf-viewer .ng2-pdf-viewer-container {
                padding-top: 50px;
                padding-bottom: 40px;
            }
        }

        .file-preview__txt {
            width: calc(100% + 2 * 20px);
            padding: 50px 20px 0;
            margin: -50px -20px -10px;
            
            &__content {
                min-height: calc(100% - 10px);
                padding: 15px;
                margin-bottom: 10px;

                pre {
                    font-size: 12px;
                }
            }
        }
    }
}

.folder-tree {
    overflow: hidden;

    &__location {
        flex-shrink: 0;
        margin-right: 12px;
    }

    &__toggle {
        width: 30px !important;
        height: 30px !important;
        line-height: 30px !important;
        color: $c-text_dark-grey-2;

        [class^="material-icons-"], [class*=" material-icons-"], .material-icons {
            font-size: 22px !important;
        }
    }

    &__item {
        min-width: 0px !important;
        max-width: 100%;
        padding: 0 10px !important;
        border-radius: 10px !important;
        border: 1px solid transparent !important;
        color: $c-black;
        font-family: $ff-base;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        overflow: hidden !important;

        &._active {
            background-color: color-mix(in srgb, $c-main 15%, transparent);
            border-color: $c-main !important;
        }

        &._current {
            border-color: $c-dark_white !important;
        }
        
        [class^="material-icons-"], [class*=" material-icons-"], .material-icons {
            color: color-mix(in srgb, $c-main 70%, white);
            margin-right: 10px;
            line-height: 14px;
        }

        &-text {
            color: $c-black;
        }
        
        &[disabled] {
            pointer-events: none;
        }

        &[disabled] [class^="material-icons-"], [class*=" material-icons-"], .material-icons {
            color: color-mix(in srgb, $c-main 30%, white);
        }

        &[disabled] &-text {
            color: color-mix(in srgb, $c-black 50%, white);
        }
    }
}

.profile-notes {
    padding: 0;
    margin-bottom: 20px;

    .profile-link__nav {
        padding: 20px;
    }

    .profile-link__nav__back {
        left: 20px;
        top: 20px;
    }


}

.question {
    margin-top: 24px;
}

.question-item {
    background: $white;
    box-shadow: 0px 3px 10px rgba(124, 125, 125, 0.15);
    border-radius: 35px;
    padding: 20px 35px;
    margin-bottom: 20px;
    position: relative;

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        position: relative;

        &__author {
            padding-right: 50px;
            color: #979797;
        }

        &__left {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.01em;
            color: $blue;
        }

        &__right {
            position: absolute;
            right: 0;
            top: 130%;
            transform: translateY(-50%);
            &._active {
                .icon-chevron {
                    transform: rotate(90deg);
                }
            }
        }
    }

    &__body {
        margin-top: 18px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        color: #979797;
        line-height: 16px;
        letter-spacing: 0.01em;
        border-bottom: 1px dashed #E0E0E0;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        &__title {
            display: flex;
            width: 60%;
            word-break: break-word;
        }

        &__result {
            border: 1px solid $sky;
            background-color: $white;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            word-break: break-word;
            text-align: right;
            position: relative;
            background-color: #68C7B3;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            border: none;
        }
    }
}

.result-checkbox {
    font-size: 1px;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);

    &._checkbox {
        content: "";
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 6px;
        height: 10px;
        border: solid var(--white-color);
        border-width: 0 2px 2px 0;
    }

    &._radio {
        background: white;
        border-radius: 50%;
    }
}

.cl-add {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &__first {
        padding-top: 76px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include bp-max (xs) {
            padding-right: 0;
            padding-top: 20px;
        }



        button {
            margin-top: 20px;
            min-width: 150px;
            display: inline-block;
            font-weight: bold;
            font-family: $ff-base;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            user-select: none;
            border: none;
            padding: 0.375rem 0.75rem;
            color: $white;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 1.25px;
            background-color: $sky;
            cursor: pointer;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            border-radius: 15px;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            background-color: $sky;
            transition: 0.2s ease-in-out;

            &:hover {
                background-color: $skyL;
                transition: 0.2s ease-in-out;
            }
        }
    }

    &__img {
        display: block;
        width: 152px;
        height: 152px;
        border-radius: 50%;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &__title {
        margin: 20px 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: $blue;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (max-width: 583px) {
            justify-content: center;

            .waw-input:first-child {
                margin-right: 0 !important;
            }

            .waw-select {
                margin-bottom: 20px;
            }

        }

        .cl-add__gender {
            max-width: 236.4px;
            width: 100%;
        }

        .waw-input:first-child {
            margin-right: 20px;
        }

        .waw-input {
            input {
                max-width: 250px;
                width: 100%;
            }
        }
    }

    &__notes {
        @media screen and (max-width: 583px) {
            display: flex;
            justify-content: center;

            .waw-textarea {
                max-width: 236.4px;
                width: 100%;
            }
        }
    }

    &__switch {
        margin: 30px 0;

        @media screen and (max-width: 583px) {
            display: flex;
            justify-content: center;
        }
    }

    &__btn {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        .waw-btn._second {
            margin-right: 40px;
        }


        @media screen and (max-width: 559px) {
            flex-direction: column;
            justify-content: center;
            width: 150px;
            margin-left: auto;
            margin-right: auto;

            button {
                width: 100%;
            }

            .waw-btn:first-child {
                margin-bottom: 20px;
            }
        }
    }
}

