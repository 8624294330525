picture {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    max-height: 100%;
    max-width: 100%;
    &._fz-46 .letter {
        font-size: 46px;
    }
}


// common styles
.h-inp-def {
    height: 40.4px;
}

.containerBox {
    padding: 20px 40px;
    overflow: auto;
    height: calc(100% - $topbarHeight);
    scrollbar-gutter: stable;
    
    @include bp-max (md) {
        height: calc(100% - $topbarHeightMobil);
        padding: 30px 20px;
    }

    @include bp-max (sm) {
        padding: 20px;
    }
}

.page-nav {
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;


    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #68C7B3;
        color: #fff;
        font-family: $ff-base;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        width: 35px;
        min-width: 35px;
        height: 35px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        cursor: pointer;
        transition: .1s all ease-in-out;
        padding: 0;
        text-align: center;
        // .icon-plus,
        // .material-icons {
        //     margin-right: 10px;
        // }

        &:hover {
            background: darken(#68C7B3, 10%);
            transition: .1s all ease-in-out;
        }
    }

    &__search {
        padding: 10px;
        background: $white;
        border-radius: 15px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        min-width: 36px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s all ease-in-out;
        position: relative;

        .icon-search {
            position: absolute;
            left: 12px;
        }

        input {
            width: 80%;
            right: 12px;
            position: absolute;
            font-size: 14px;
            padding: 5px 10px;
            display: block;
            border: 1px solid $grey;
            border-radius: 15px;
            color: $blue;
            letter-spacing: 0.2px;
            font-size: 16px;
            background-color: $white;
            transition: .3s all ease-in-out;
            transform: scale(0);
        }

        &._active {
            width: 235px;

            input {
                transition: .3s all ease-in-out;
                transform: scale(1);

            }
        }

    }
}

.icon {
    cursor: pointer;
    color: $grey;

    &::before {
        color: $grey;

    }
}

.tab {
    width: 100%;
    border-bottom: 0.7px solid $grey;
    display: flex;

    &__item--icon {
        display: none;
        padding: 12px 20px;
        font-weight: normal;
        letter-spacing: 0.01em;
        text-transform: capitalize;
        color: $c-grey;
        position: relative;
        cursor: pointer;
        font-size: 18px;
        line-height: 21px;


        &::before {
            content: "";
            position: absolute;
            bottom: -3px;
            width: 100%;
            height: 3px;
            left: 0;
            transform: scale(0);
            background-color: $c-green;
            transition: .3s all ease-in-out;
        }

        &._active {
            color: $c-green;
            &::before {
                transition: .3s all ease-in-out;
                transform: scale(1);

            }

        }
    }

    @include bp-max(md) {
        &__item {
            display: none;
        }

        &__item--icon {
            display: block;
        }
    }

    &__item {
        padding: 5px 20px;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: $c-grey;
        position: relative;
        cursor: pointer;
        text-align: center;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 2px;
            left: 0;
            transform: scale(0);
            background-color: $c-green;
            transition: .3s all ease-in-out;
        }

        &._active {
            color: $c-green;
            &::before {
                transition: .3s all ease-in-out;
                transform: scale(1);

            }

        }
    }

}

.tooltip {
    position: relative;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 100%;
    background-color: $blue;
    color: $white;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $blue transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.t-hide {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    word-break: keep-all;
}

.text-elipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
}

.ovf-hidden {
    overflow: hidden;
}

.text-muted {
    color: $grey;
}

.nav-filter {
    .page-nav__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;

        .material-icons {
            font-size: 20px;
        }
    }

    display: none;

    .icon-filter {

        font-size: 24px;
    }
}

.flex-wrap {
    flex-wrap: wrap;
}

.hidden-mob {
    @media (max-width: 1250px) {
        display: none;
    }
}

.dis-mob {
    @media (max-width: 1250px) {
        display: flex;
    }
}


.filter-mobile {
    position: relative;
    &-overlay {
        width: 250px;
        position: absolute;
        top: 40px;
        left: 0;
        z-index: 70;
        padding: 30px 26px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        &.right {
            left: auto;
            right: 0;
        }
        .col-1 {
            padding: 0 0 10px;
            &:last-child {
                padding-bottom: 0;
            }
        }
        @media (max-width: 400px) {
            width: 200px;
        }
    }
}


.image-cropper {
    border-radius: 15px;
    margin-bottom: 10px;
}



.events-none {
    pointer-events: none;
}


.search-input {
    position: relative;
    max-width: 300px;
    width: 100%;
    height: 42px;

    &::after {
        content: "\e914";
        position: absolute;
        right: 23px;
        top: 50%;
        color: #999999;
        transform: translateY(-50%);
        font-family: 'icomoon' !important;
        font-size: 15px;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    input {
        display: block;
        width: 100%;
        height: 42px;
        min-height: 42px;
        max-height: 42px;
        padding: 13px 50px 13px 30px;
        margin: 0;
        border-radius: 10px;
        background-color: $white;
        border: 1px solid transparent;
        color: $c-text_dark-grey-2;
        font-family: $ff-base;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: normal;
        transition: 0.2s all ease-in-out;

        &:hover, &:focus {
            border-color: $c-main;
        }

        &::placeholder {
            color: #999;
            font-family: $ff-base;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: normal;
        }
    }

    @include bp-max(md) {
        &::after {
            right: 20px;
        }

        input {
            padding: 13px 45px 13px 20px;
        }
    }

    @include bp-max(sm) {
        &::after {
            right: 16px;
        }
        
        input {
            padding: 13px 41px 13px 16px;
            border-radius: 8px;
        }
    }
}



.setup-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 30px;
    height: auto;
    overflow: auto;
    transition: 0.3s all ease-in-out;

    @include bp-max (lg) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include bp-max (md) {
        grid-column-gap: 30px;
        grid-row-gap: 20px;
    }

    @include bp-max (sm) {
        grid-template-columns: 1fr;
    }
}

.setup-box {
    background: $white;
    padding: 40px 50px;
    box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.14);
    border-radius: 10px;
    height: 100%;
    font-family: $ff-third;
    overflow: hidden;
    transition: 0.3s all ease-in-out;

    @include bp-max (md) {
        padding: 30px 20px;
    }

    @include bp-max (sm) {
        padding: 16px 10px;
    }
}

.setup-item {
    position: relative;
    font-family: $ff-third;

    &__title {
        margin-right: 70px;
        color: $c-text_dark-grey-2;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: keep-all;
        
        @include bp-max (md) {
            font-size: 16px;
        }
        
        @include bp-max (sm) {
            font-size: 14px;
        }
    }

    &__actions {
        position: absolute;
        top: 30px;
        right: 40px;

        @include bp-max (md) {
            top: 20px;
            right: 10px;
        }
        
        @include bp-max (sm) {
            top: 6px;
            right: 5px;
        }
    }

    &__description {
        margin-top: 10px;
        margin-bottom: 20px;
        color: $c-text_grey;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @include bp-max (md) {
            font-size: 12px;
        }

        ::ng-deep p {
            margin: 0 !important;
        }
    }

    &__property {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        
        &__name {
            margin-right: 10px;
            color: $c-text_dark-grey-2;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        &__value {
            color: $c-text_grey;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            text-align: end;
        }

        ::ng-deep p {
            margin: 0 !important;
        }

        @include bp-max (md) {
            &__name, &__value {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    hr {
        border: 0;
        border-top: 1px solid #E4E8F1;
        margin: 20px 0;
    }
}

.setup-add {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    border: 1px dashed #BDC7DC;
    border-spacing: 20px;
    box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.14);

    @include bp-max (sm) {
        min-height: 130px !important;
    }
}

.setup-empty {
    width: 100%;
    height: auto;
    min-height: 100%;
    text-align: center;

    &__img {
        width: 100%;
        max-width: 525px;
        max-height: 350px;
        margin-left: auto;
        margin-right: auto;

        img {
            max-height: inherit;
        }
    }

    &__title {
        max-width: 950px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;
        margin-bottom: 40px;
        color: $c-text_dark-grey-2;
        font-family: $ff-third;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        a {
            color: $c-main;
            cursor: pointer;
            transition: 0.07s all ease-in-out;

            &:visited {
                color: $c-main;
            }

            &:active, &:hover, &:focus {
                filter: brightness(0.9);
            }
        }
    }

    &__btn {
        display: flex;
        justify-content: center;
    }

    @include bp-max (lg) {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__img {
            @media (orientation: landscape) {
                max-width: 425px;
                max-height: 280px;
            }
        }

        &__title {
            max-width: 650px;

            @media (orientation: portrait) {
                margin-top: 80px;
            }
        }
    }

    @include bp-max (sm) {
        @media (orientation: landscape) {
            &__img {
                max-width: 325px;
                min-height: 216px;
            }

            &__title {
                margin-top: 40px;
                margin-bottom: 20px;
            }
        }

        &__title {
            font-size: 16px;

            @media (orientation: portrait) {
                margin-bottom: 30px;
            }
        }
    }
}

.cl-service-nav {
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hours {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &__day {
        display: flex;
        flex-direction: row;
        
        &:not(._empty) {
            margin-top: calc(18px + 6px);
        }

        &__name {
            width: 150px;
            font-family: $ff-second;
            font-size: 16px;
            font-weight: 400;
            line-height: 42px;
            letter-spacing: 0px;
            color: $c-text_dark-grey;
        }

        &__body {
            display: flex;
            flex-direction: column;
            gap: calc(20px + 18px + 6px);
            flex: 1;

            &__item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 15px;
                
                &-time {
                    display: flex;
                    flex-direction: row;
                    flex: 1;
                    margin-top: calc(($input-label_height * -1) - 2px);
                    margin-bottom: -$input-margin-bottom;

                    timepicker {
                        flex: 1;

                        
                        .timepicker__label {
                            margin-bottom: 2px;
                        }
                        
                        .timepicker__container {
                            min-width: 115px;
                            padding-right: calc(42px - 15px);

                            &:hover, &.focus {
                                z-index: 2;
                            }

                            input {
                                width: calc(100% - 42px + 15px - 15px);
                            }
                        }

                        .timepicker-wrapp.req .timepicker__container {
                            z-index: 4;
                        }
                        
                        &:first-of-type .timepicker__container {
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;

                            .btn {
                                border-top-right-radius: 0px;
                                border-bottom-right-radius: 0px; 
                            }
                        }

                        &:last-of-type {
                            margin-left: -1px;

                            .timepicker__container {
                                border-top-left-radius: 0px;
                                border-bottom-left-radius: 0px;
                            }
                        }
                    }
                }

                &-actions {
                    display: flex;
                    flex-direction: row;
                    gap: 15px;   
                    min-width: calc(42px * 2 + 15px);
                }
            }
        }
    }

    @include bp-max(sm) {
        gap: 20px;

        &__day {
            display: flex;
            flex-direction: column;
            gap: 13px;
            
            &:not(._empty) {
                gap: 8px;
                margin-top: 0px;
            }

            &__name {
                width: auto;
                font-size: 13px;
                font-weight: 500;
                line-height: normal;
            }

            &__body {
                gap: 15px;

                &__item {
                    gap: 10px;

                    &-time {
                        margin-top: 0px;

                        timepicker .timepicker__container {
                            min-width: 0;
                            padding-right: 20px;

                            input {
                                width: calc(100% - 20px - 10px);
                            }
                        }
                    }

                    &-actions {
                        align-items: flex-start;
                        gap: 10px;   
                        min-width: calc(32px * 2 + 12px);
                        margin-top: calc($input-label_height + 2px);
                    }
                }
            }
        }
    }
}